.TooltipGiftModel {
  .tooltips-gift {
    text-align: center;
    h2 {
      height: 50px;
      padding-top: 22px;
      font-size: 16px;
    }

    .RenderGiftIconAndName {
      position: relative;
    }

    width: 320px;
    background: #a4c2d0;
    border-radius: 10px;
    border: 4px solid #597cc0;
    &_block {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around; // равномерное распределение элементов по горизонтали
      align-items: center; // центрирование элементов по вертикали
      &__box {
        text-align: center;
        margin-left: 15px;
        margin-right: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin-top: 10px;
        margin-bottom: 10px;
      }

      button {
        background: #597cc0;
        color: #ffffff;
        font-size: 14px;
        border-radius: 4px;
        padding: 10px 15px;
        border: none;
        cursor: pointer;
        outline: none;
        transition: background 0.3s;
        margin-bottom: 10px;
        margin-top: 10px;
      }

      button:hover {
        background: #0057f5;
      }
      svg {
        margin-top: 5px;
        width: 50px;
        height: 50px;
      }
    }
  }
}
