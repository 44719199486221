.error-socket-modal {
  width: min(537px, 100vw - 1rem);
  margin: 0.5rem auto;
  padding: 2rem;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;

  @media (max-width: 400px) {
    padding: 1rem;
  }

  h2 {
    padding-block: 1rem;
    color: var(--main-color);
    font-family: Inter;
    font-size: 32px;
    font-weight: 400;
    line-height: 48px;
    text-align: center;
    text-wrap: pretty;

    @media (max-width: 400px) {
      font-size: 24px;
    }
  }

  .error-socket-buttons {
    width: 100%;
    padding-block: 1rem;
    display: flex;
    justify-content: space-between;
    gap: 1rem;

    @media (max-width: 550px) {
      flex-direction: column-reverse;
      gap: 0.5rem;
    }

    button {
      height: 48px;
      padding: 11px 22px;
      border-radius: 40px;
      font-family: var(--font-family);
      font-size: 15px;
      font-weight: 400;
      line-height: 18.6px;
      transition: background-color 150ms ease;

      @media (max-width: 550px) {
        flex-basis: 100%;
      }

      &.close-button {
        background-color: #fff;
        border: 1px solid var(--primary-blue-color);
        color: var(--primary-blue-color);

        &:hover {
          border-color: var(--secondary-blue-color);
          color: var(--secondary-blue-color);
        }
      }

      &.refresh-button {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 1rem;
        color: #fff;
        background-color: var(--primary-blue-color);

        &:hover {
          background-color: var(--secondary-blue-color);
        }
      }
    }
  }
}
