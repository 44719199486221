.RenderGiftIconAndName {
  position: absolute;
  top: 0;
  left: 0;
  text-align: center;
  width: 100%;

  svg {
    width: 20px;
    height: 20px;
  }
  img {
    margin: 0 auto;
    display: block;
    width: auto;
    height: auto;
    max-height: 100%;
  }
}

.z-0 {
  z-index: 0;
}

.z-3 {
  z-index: 3;
}

.typing {
  .typing_messages {
    display: flex;
    align-items: center;
    p {
      margin: 0;
      padding-right: 1px;
      color: #597cc0;
    }

    .dot {
      position: relative;
      top: 4px;

      display: inline-block;
      width: 3px;
      height: 3px;
      margin: 0 2px;
      background-color: #597cc0;
      border-radius: 50%;
      animation: blink 1s infinite;

      &:nth-child(2) {
        animation-delay: 0.2s;
      }

      &:nth-child(3) {
        animation-delay: 0.4s;
      }
    }
  }

  @keyframes blink {
    0%,
    20%,
    50%,
    80%,
    100% {
      opacity: 1;
    }
    40% {
      opacity: 0.3;
    }
    60% {
      opacity: 0.3;
    }
  }
  .RenderGiftIconAndName {
    position: relative;
    text-align: center;
    max-width: 50px;
    overflow: hidden;

    svg {
      width: 20px;
      height: 20px;
    }
    img {
      display: block;
      width: auto;
      height: auto;
      max-width: 100%;
    }
  }
}
