.slider {
  &__load {
    width: 150px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 3px white solid;
    background: #dd1982;
    margin: 10px auto 0 auto;
  }
}

.account__slider {
  background-size: cover;
  flex: 1;
  align-self: flex-start;
  width: 100%;
  max-height: 100%;
  padding: 10px;
  background: linear-gradient(#fbeded, var(--pink-color));
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  video {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
}

.keen-slider__btn {
  border-radius: 5px;
  background: rgba(255, 255, 255, 0);
  position: absolute;
  padding: 0;
  top: 50%;
  z-index: 1;
  cursor: pointer;
}

button.keen-slider__btn:hover {
  background: rgba(77, 67, 67, 0);
}

.keen-slider__btn.left {
  left: 5px;
}

.keen-slider__btn.right {
  right: 5px;
}

[class^='number-slide'],
[class*=' number-slide'] {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 50px;
  color: #fff;
  font-weight: 500;
  height: 200px;
  max-height: 100vh;
}
.thumbnail .keen-slider__slide {
  font-size: 30px;
  margin-top: 10px;
  height: 100px;
}
.thumbnail .keen-slider__slide {
  cursor: pointer;
}
.thumbnail .keen-slider__slide.active {
  border: 2px dashed black;
}
.keen-slider__slide {
  height: 530px;
}

@media (min-width: 500px) {
  .account__slider {
    min-height: 457px;
  }
}
@media (max-width: 500px) {
  .account__slider {
    height: 457px;
  }
}
