.message-user__content {
  height: 100%;
  flex-basis: 100%;
  position: relative;

  .chat-header {
    height: 95px;
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 1rem;
    border-bottom: 1px solid hsl(0, 0%, 91%);
    background-color: hsl(0, 0%, 100%);

    @media (max-width: 768px) {
      height: auto;
      padding: 10px;
      gap: 10px;
    }

    .chat-header__user {
      display: flex;
      align-items: center;
      gap: 12px;

      .chat-header__back {
        button {
          width: 42px;
          height: 42px;
          padding: 0;
          background-color: transparent;
          transition: transform 300ms ease;

          svg {
            width: 100%;
            height: 100%;
          }

          &:hover {
            transform: translateX(-3px);
          }
        }
      }

      .chat-header__user-avatar {
        position: relative;
        width: 48px;
        height: 48px;

        img {
          border-radius: 50%;
        }
      }

      .chat-header__model-avatar,
      .chat-header__model-info {
        cursor: pointer;
      }

      .chat-header__user-info {
        display: flex;
        justify-content: center;
        flex-direction: column;
        gap: 4px;
        color: #fff;

        .chat-header__user-name {
          font-family: var(--font-family);
          font-size: 20px;
          font-weight: 600;
          line-height: 25px;
          text-align: left;
          color: hsl(235, 13%, 28%);

          width: 200px;
          @media (max-width: 955px) {
            width: 150px;
          }
          @media (max-width: 905px) {
            width: 100px;
          }
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .chat-header__user-amount {
          display: flex;
          gap: 1rem;

          .chat-header__user-amount-item {
            display: flex;
            align-items: center;
            gap: 4px;

            span {
              font-family: Inter;
              font-size: 12px;
              font-weight: 600;
              line-height: 18px;
              text-align: left;
              color: hsla(237, 13%, 28%, 1);
            }
          }
        }
      }
    }

    .chat-header__icons {
      justify-self: flex-end;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 6px;

      div {
        display: grid;
        place-items: center;
        cursor: pointer;
      }
    }
  }
}

.gift {
  border-radius: 10px;
  background-color: white;

  .tippy-arrow {
    color: white;
  }
}
