.enter-room-modal {
  width: min(537px, 100vw - 1rem);
  margin: 0.5rem auto;
  padding: 2rem 1rem;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
  background-color: #fff;

  .model-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;

    .model-photo {
      width: 100px;
      height: 100px;
      border-radius: 50%;

      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        object-fit: cover;
      }
    }

    .model-name {
      text-align: center;
      font-family: var(--font-family);
      font-size: 20px;
      font-weight: 600;
      line-height: 25px;
      color: hsl(235, 13%, 28%);
      width: 200px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .enter-room-info {
    font-family: var(--font-family);
    font-size: 15px;
    line-height: 18.6px;
    text-align: center;
    text-wrap: balance;
  }

  .enter-room-buttons {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;

    @media (max-width: 500px) {
      flex-direction: column-reverse;
      gap: 0.5rem;
    }

    button {
      height: 43px;
      width: 50%;
      padding: 11px 22px;
      border-radius: 40px;
      font-family: var(--font-family);
      font-size: 15px;
      font-weight: 400;
      line-height: 18.6px;
      transition: background-color 150ms ease;

      @media (max-width: 500px) {
        width: 80%;
      }

      &.decline-call {
        background-color: #fff;
        border: 1px solid var(--primary-blue-color);
        color: var(--primary-blue-color);

        &:hover {
          border-color: var(--secondary-blue-color);
          color: var(--secondary-blue-color);
        }
      }

      &.accept-call {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 1rem;
        color: #fff;
        background-color: var(--primary-blue-color);

        &:hover {
          background-color: var(--secondary-blue-color);
        }
      }
    }
  }
}
