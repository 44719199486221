.audioMp3 {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-radius: 12px;
  background-color: #f9f9f9;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 800px;
  margin: 25px auto;
  aside {
    width: 100%;
  }
}

.audioMp3-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

button {
  max-width: 100%;
  padding: 12px 20px;
  border: none;
  border-radius: 8px;
  font-family: var(--font-family);
  font-size: 14px;
  line-height: 18.6px;
  color: #fff;
  background-color: hsla(218, 100%, 60%, 0.75);
  transition: background-color 150ms ease;
  cursor: pointer;
}

button:hover {
  background-color: hsla(218, 100%, 60%, 0.95);
}

button:focus {
  outline: none;
}

.audioMp3 {
  margin-top: 50px;
  width: 100%;
}

.Audio {
  h4,
  audio {
    margin: 10px 0;
  }
}
