.InfoHeader {
  &-block {
    height: 90px;
    width: 100%;
    border-bottom: 1px solid #000;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
}
