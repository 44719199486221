.ReferralControllerTransactions {
  font-family: 'Arial', sans-serif;
  padding: 20px;
  span {
    color: red;
  }
  h1 {
    font-size: 24px;
    margin-bottom: 20px;
    color: #333;
    text-align: center;
  }

  table {
    width: 100%;
    border-collapse: collapse;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin-top: 20px;
    background-color: #fff;
    border-radius: 8px;
    overflow: hidden;

    th,
    td {
      padding: 12px 15px;
      text-align: left;
      border-bottom: 1px solid #ddd;
    }

    th {
      background-color: #4caf50;
      color: white;
      font-weight: bold;
      text-transform: uppercase;
      font-size: 14px;
    }

    td {
      font-size: 14px;
      color: #555;
    }

    tr:nth-child(even) {
      background-color: #f9f9f9;
    }

    tr:hover {
      background-color: #f1f1f1;
      transition: background-color 0.3s ease;
    }
  }

  p {
    margin-top: 10px;
    font-size: 14px;
    color: #555;
    text-align: center;
  }
  .filters {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;

    label {
      display: flex;
      flex-direction: column;
      font-size: 14px;
      color: #333;

      select,
      input {
        margin-top: 5px;
        padding: 8px;
        font-size: 14px;
        border: 1px solid #ddd;
        border-radius: 4px;
      }
    }
  }
}
