.friends__content-item {
  position: relative;
  width: minmax(255px, auto);
  height: 246px;
  padding: 24px;
  gap: 10px;
  border-radius: 16px;
  border-bottom: 1px solid hsla(0, 0%, 0%, 0.08);
  background-color: #fff;

  &.model-link {
    cursor: pointer;
  }

  .flex-column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
  }

  .item__img {
    position: relative;
    width: 80px;
    height: 80px;

    img {
      border-radius: 50%;
    }

    svg {
      width: 100%;
      height: 100%;
    }

    .status {
      position: absolute;
      right: 45px;
      bottom: 25px;
    }

    img + .status {
      right: 40px;
      bottom: 22px;
    }
  }

  .item__content {
    text-align: center;

    &.flex-column {
      gap: 0.25rem;
    }

    .item__content-title {
      font-family: var(--font-family);
      font-size: 14px;
      font-weight: 600;
      line-height: 21px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .item__content-text {
      font-family: Inter;
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
      color: hsl(218, 15%, 52%);
    }
  }

  .item__btn-block-user {
    position: absolute;
    top: 24px;
    right: 24px;
    padding: 0;
    border-radius: 40px;
    background: transparent;
    border: none;

    &:hover {
      background-color: transparent;

      svg {
        path {
          stroke: hsl(237, 27%, 57%);
        }
      }
    }
  }

  .item__buttons {
    display: flex;
    gap: 4px;

    button {
      height: 40px;
      padding: 10px 16px;
      border-radius: 24px;
      font-family: Urbanist;
      font-size: 16px;
      font-weight: 700;
      line-height: 19.2px;
    }
  }

  .item__button_blue {
    color: #fff;
    background: var(--primary-blue-color);

    &:hover {
      background: var(--secondary-blue-color);
    }
  }

  .item__button_grey {
    border: 1px solid hsl(237, 27%, 67%);
    color: hsl(237, 27%, 67%);
    background-color: transparent;

    &:hover {
      border-color: hsl(237, 27%, 57%);
      color: hsl(237, 27%, 57%);
      background-color: transparent;
    }
  }
}
