div {
  .react-dropdown-select,
  .react-dropdown-select:hover,
  .react-dropdown-select-dropdown {
    border-radius: 8px;
    border: 2px solid hsla(218, 100%, 60%, 0.5);
    background-color: #fff;
  }

  .react-dropdown-select {
    margin-top: 3px;
    padding: 10px;
  }

  .react-dropdown-select:focus {
    box-shadow: none;
  }

  .react-dropdown-select:focus-within {
    border: 2px solid var(--secondary-blue-color);
    box-shadow: none;
  }

  span.react-dropdown-select-item:hover {
    background-color: hsla(218, 100%, 60%, 0.25);
  }

  span.react-dropdown-select-item-selected,
  span.react-dropdown-select-item-selected:hover {
    color: #fff;
    background-color: var(--secondary-blue-color);
  }

  .dropdown-select-button,
  .dropdown-select-button:hover {
    padding-inline: 0;
    background-color: transparent;
  }

  .dropdown-select-icon-down {
    transform: rotate(0deg);
    transition: transform 300ms ease;
  }

  .dropdown-select-icon-up {
    transform: rotate(180deg);
    transition: transform 300ms ease;
  }
}
