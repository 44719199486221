.ModelPrice {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
  max-width: 600px;
  margin: 2rem auto;
  background: #fff;
  border-radius: 8px;
  padding: 2rem;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
}

.ModelPrice > div {
  margin-bottom: 1rem;
  line-height: 1.4;
  color: #333;
}

.ModelPrice > div:not(.field-group):not(:last-child) {
  border-bottom: 1px solid #eee;
  padding-bottom: 1rem;
}

.ModelPrice .field-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}

.ModelPrice .field-group span {
  font-weight: 600;
  margin-bottom: 0.5rem;
  color: #444;
}

.ModelPrice input[type='text'] {
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
  transition:
    border-color 0.2s,
    box-shadow 0.2s;
}

.ModelPrice input[type='text']:focus {
  outline: none;
  border-color: #6c63ff;
  box-shadow: 0 0 0 3px rgba(108, 99, 255, 0.2);
}

.ModelPrice button {
  display: inline-block;
  padding: 0.6rem 1.2rem;
  margin-top: 1rem;
  margin-right: 1rem;
  font-size: 1rem;
  font-weight: 500;
  color: #fff;
  background-color: #6c63ff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.ModelPrice button:hover {
  background-color: #5b53dc;
}

.ModelPrice button:last-of-type {
  background-color: #999;
}

.ModelPrice button:last-of-type:hover {
  background-color: #888;
}
