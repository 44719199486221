.custom-selected {
  display: flex;
  justify-content: flex-end;
  position: relative;
  padding: 20px 20px 20px 20px;
  border-radius: 40px;
  background: #fff;
  border: none;
  .error-message {
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: center;
    left: 50%;
    top: calc(100% + 5px); /* 5px - это ваш отступ от инпута */
    transform: translateX(-50%);
    color: var(--red-color);
    font-size: 12px;
    p {
      margin-left: 30px;
    }
  }

  input::placeholder {
    color: #757575; /* Example gray color */
  }
  input {
    max-width: 435px;
    min-width: 300px;
  }

  .react-dropdown-select-dropdown-handle {
    position: relative;
    top: 1px;
    svg {
      width: 30px;
      height: 30px;
    }
  }

  .react-dropdown-select {
    outline: none; /* Убирает стандартную рамку браузера при фокусе */
    border: none; /* Убирает рамку */
    box-shadow: none; /* Убирает тень, если она есть */
    position: relative;

    span:not(.react-dropdown-select-item, .react-dropdown-select-item-selected, .css-z2hyoe-ItemComponent, .evc32pp0) {
      position: absolute;
      top: 10px;
      left: 15px;
      font-size: 20px;
    }
  }

  .react-dropdown-select,
  .react-dropdown-select * {
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
  }

  .react-dropdown-select:hover,
  .react-dropdown-select:hover * {
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
  }

  .react-dropdown-select:focus,
  .react-dropdown-select:focus * {
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
  }

  .react-dropdown-select:active,
  .react-dropdown-select:active * {
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
  }

  .react-dropdown-select-dropdown::-webkit-scrollbar {
    width: 7px;
    background-color: #f5f5f5;
  }
  .react-dropdown-select-dropdown::-webkit-scrollbar-thumb {
    background-color: #597cc0;
    border-radius: 5px;
  }
  .react-dropdown-select-dropdown::-webkit-scrollbar-thumb:hover {
    background-color: #555;
  }
  .react-dropdown-select-item:first-child {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }
  .react-dropdown-select-item:last-child {
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }
  .react-dropdown-select-dropdown {
    border-radius: 20px; /* Задайте нужное значение */
  }
}

@media (max-width: 500px) {
  .custom-selected {
    input {
      max-width: 435px;
      min-width: 200px;
    }
  }
}
