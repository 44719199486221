@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&family=Open+Sans:wght@400;700&display=swap');

.custom-editor {
  display: block;
  min-height: 200px;
  width: 100%;
  padding: 10px 0;
  line-height: 18px;
  color: black;
  font-family: 'Arial', 'Roboto', sans-serif; /* Default font */
  border: none;
  .error {
    padding: 4px;
    color: var(--red-color);
  }

  // Применим высоту к внутреннему контейнеру редактора
  .DraftEditor-root {
    min-height: 200px; // Убедитесь, что это применяется к основному редактору
  }

  .public-DraftEditor-content {
    min-height: 200px; // Принудительно задаем высоту контенту редактора
    height: auto; // Позволяем изменяться
  }
}

.custom-editor .edit {
  padding: 1rem;
  border: 1px solid #999999;
  border-radius: 8px;
  font-family: var(--font-family);
}

#font-family-select {
  option:hover {
    background-color: var(--primary-blue-hover);
  }
}

.emoji-picker {
  max-width: fit-content;
}
