.TransactionalController {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

  h1 {
    font-size: 2em;
    margin-bottom: 20px;
    text-align: center;
  }

  p {
    margin: 10px 0;
    font-size: 1em;
    color: #333;
  }

  ul {
    list-style-type: none;
    padding: 0;

    li {
      padding: 15px;
      margin-bottom: 10px;
      border: 1px solid #ddd;
      border-radius: 5px;
      background-color: #fff;

      p {
        margin: 5px 0;

        &:first-child {
          font-weight: bold;
        }
      }
    }
  }

  .pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;

    button {
      padding: 10px 20px;
      margin: 0 10px;
      border: none;
      background-color: #007bff;
      color: #fff;
      border-radius: 5px;
      cursor: pointer;
      transition: background-color 0.3s;

      &:disabled {
        background-color: #ddd;
        cursor: not-allowed;
      }

      &:hover:not(:disabled) {
        background-color: #0056b3;
      }
    }

    span {
      font-size: 1em;
      color: #333;
    }
  }
}
