.loader {
  width: 48px;
  height: 48px;
  border: 5px solid #fff;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loader-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 3;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader-item {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  position: relative;
  border: 2px solid white;
  &__img {
    border-radius: 10px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    filter: blur(5px);
    background-image: url('../../../images/img/backgraund/img.png');
    width: 100%;
    height: 100%;
  }
}
