@import url('https://fonts.googleapis.com/css2?family=Unbounded:wght@200..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Urbanist:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Unna&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Vampiro+One&display=swap');

body {
  font-family: 'ProximaNova';
  font-weight: 400;
  font-size: 15px;
  background-color: var(--bg-color);
  color: var(--main-color);
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.wrapper {
  width: 100%;
  max-width: 1550px;
  margin-inline: auto;
}

.container {
  max-width: var(--container);
  margin: 0 auto;
}

.main-title {
  text-align: center;
  font-size: 55rem;
  font-weight: 700;
  line-height: 100%;
  letter-spacing: 2.8rem;
  text-transform: uppercase;
  margin-bottom: 96rem;
}

.title {
  font-size: 48rem;
  font-weight: 700;
  line-height: 1.25;
  letter-spacing: 0.96rem;
}

.title span {
  color: var(--secondary-blue-color);
}

@media (max-width: 768px) {
  .title {
    font-size: 24rem;
    letter-spacing: 0.48rem;
  }
}

.logo {
  color: #fff;
  font-size: 20rem;
  font-weight: 700;
  text-transform: uppercase;
}

@media (max-width: 768px) {
  .logo {
    height: 30rem;
  }
}
