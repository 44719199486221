/* Стили основного обёртывающего блока .SocketTest */
.SocketTest {
  width: 100%;
  height: 100%;
  /* Для небольшого отступа от краёв внутри popup */
  padding: 1.5rem;

  &-header {
    font-size: 1.4rem;
    font-weight: 600;
    margin-bottom: 1rem;
  }

  &-block {
    /* Для обрамления основной части контента */
    background-color: #f9f9f9;
    padding: 1rem;
    border-radius: 4px;
  }

  &-messages {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    gap: 0.75rem;

    /* Если нет сообщений, выводим текст "No socket data available." */
    p {
      margin: 0;
      font-size: 0.9rem;
      color: #666;
      text-align: center;
    }
  }

  &-message {
    width: 100%;
    padding: 1rem;
    border-radius: 4px;
    border: 1px solid #ececec;
    font-family: 'Source Code Pro', monospace;
    display: flex;
    /* Для разделения входящих/исходящих сообщений */
    &.outgoing {
      background-color: #c5ffb1;
      align-self: flex-end;
    }
    &.incoming {
      background-color: #ff7f7f;
      align-self: flex-start;
    }
  }
}

/* Дополнительные стили для кнопки закрытия, 
   чтобы смотрелась аккуратно */
.SocketTest-closeBtn,
.SocketTest button {
  margin-top: 1rem;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  background: #ff5656;
  color: #fff;
  font-size: 0.9rem;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;

  &:hover {
    background-color: #ff3c3c;
  }
}

/* При необходимости можно дополнительно стилизовать сам ObjectInspector 
   через селекторы, если нужно что-то поправить глобально */
.react-inspector {
  font-family: 'Source Code Pro', monospace;
}
