.buttons-videoCall {
  background: black;
  .timeStartStop {
    p {
      margin-top: 5px;
      top: 0;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      z-index: 9999999999;
      text-align: center;
    }
    svg {
      width: 15px;
      height: 15px;
    }
    button {
      margin: 3px;
    }
  }
  .coin {
    svg {
      height: 20px;
      width: 20px;
    }
  }
}

.lk-control-bar {
  overflow: auto;
  /* Говорим браузеру, чтобы пространство под скролл было стабильно */
}
/* Базовые стили для центровки на больших экранах и прокрутки на маленьких */
.lk-control-bar,
.lk-agent-control-bar {
  display: flex;
  flex-wrap: nowrap; /* Запрещаем перенос элементов на новую строку */ /* Обрезаем вертикальную прокрутку */
  padding: 10px; /* Внутренние отступы (по необходимости) */
  scrollbar-width: none; /* Скрываем полосу прокрутки в Firefox */
  scroll-behavior: smooth; /* Плавная прокрутка */
  justify-content: center; /* Центрируем элементы по умолчанию */
}

/* Скрываем полосу прокрутки в WebKit-браузерах (Chrome, Safari, Opera) */
.lk-control-bar::-webkit-scrollbar,
.lk-agent-control-bar::-webkit-scrollbar {
  display: none;
}

/* Стили для внутренних элементов */
.lk-control-bar > .lk-button-group,
.lk-agent-control-bar > .lk-button-group {
  flex: 0 0 auto; /* Предотвращаем сжатие или растяжение элементов */
  margin: 0 5px; /* Отступы между элементами */
}

/* Медиа-запрос для маленьких экранов */
@media (max-width: 997px) {
  .lk-control-bar,
  .lk-agent-control-bar {
    justify-content: flex-start !important;
  }
}
.lk-control-bar_logo {
  span {
    display: flex;
    justify-content: center;
  }
  img {
    min-height: 60px;
    max-height: 60px;
    min-width: 160px;
    max-width: 160px;
  }
}
@media (max-width: 500px) {
  .lk-control-bar_logo img {
    min-height: 46px;
    max-height: 40px;
    min-width: 62px;
    max-width: 149px;
  }
}
.lk-button-action {
  img {
    width: 25px;
    height: 25px;
  }
}
