.lk-button-group-custom {
  display: flex;
  .lk-button,
  .lk-button-menu {
    width: 44px;
    height: 40px;
  }
}

.lk-button-custom-arrow {
  svg {
    width: 13px;
    height: 13px;
  }
}
.lk-button-custom-arrow .arrow-icon {
  transition: transform 0.3s ease;
}

.lk-button-custom-arrow .arrow-icon.rotated {
  transform: rotate(180deg);
}
.leave {
  position: relative;
}
