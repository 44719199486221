.main-info-documents {
  width: min(800px, 100% - 40px);
  margin: 5rem auto;
  padding: 20px;
  border-radius: 20px;
  background-color: var(--light-background);
  box-shadow:
    0 3px 6px rgba(0, 0, 0, 0.08),
    0 1px 3px rgba(0, 0, 0, 0.1);
  text-align: center;

  h2 {
    margin-block: 2rem;
    font-size: calc(0.95rem + 0.8vw);
    color: #333;
    font-weight: 500;
  }

  h2 + p {
    margin: 2rem auto;
    text-transform: uppercase;
    font-size: min(0.5rem + 1vw, 16px);
    font-weight: 600;
    color: #444;
    line-height: 1.65;
  }

  button {
    width: fit-content;
    display: block;
    padding: 12px 20px;
    margin: 1rem auto;
    border: none;
    border-radius: 8px;
    text-decoration: none;
    background-color: var(--primary-blue-color);
    color: #fff; // Можете изменить на предпочтительный цвет текста кнопок
    transition: background-color 150ms ease;

    &:hover {
      background-color: var(--secondary-blue-color);
    }
  }
}

@media (max-width: 415px) {
  .main-info-documents {
    width: calc(100% - 20px);
    padding: 10px;
  }
}
