.step-header__main-header,
.step-header__step-navigation {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 15px;
  color: var(--main-color);
}

.step-header__main-header {
  .language-header {
    box-shadow:
      0 3px 5px rgba(0, 0, 0, 0.05),
      0 1px 3px rgba(0, 0, 0, 0.08);
  }
}

.step-header__step-navigation {
  text-align: center;
  padding-top: 25px;
  margin: 0 auto;
  padding-top: 5rem;
  padding-bottom: 10px;

  .step-header__title {
    font-size: calc(1rem + 1vmin);
    margin-bottom: 3rem;
  }

  .step-header__steps {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 3rem;

    .step {
      display: flex;
      justify-content: center;
      align-items: center;

      .step__column {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 15px;

        .step__circle {
          position: relative;
          z-index: 1;
          width: 45px;
          height: 45px;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          border: 3px solid #aeaeae;
          color: #aeaeae;
          background-color: hsla(0, 0%, 100%, 0.75);

          &:hover {
            background-color: #fff;
          }
        }
      }

      .step__column.active {
        .step__circle,
        label {
          color: var(--pink-color);
        }

        .step__circle {
          border-color: var(--pink-color);
        }
      }

      .step__line {
        width: 180px;
        height: 2px;
        background: #aeaeae;
        transform: scale(1.3) translateY(-13px);
      }
    }
  }
}

@media (max-width: 480px) {
  .step-header__step-navigation {
    width: 100%;

    .step-header__steps {
      .step {
        .step__line {
          width: 40px;
          transform: scale(1.4) translateY(-13px);
          transform: scaleX(2.4) translateY(-15px);
        }
      }
    }
  }
}

.step-header__steps .step__line.active {
  background: #53c05e;
}
.step-header__steps .step__line.wait {
  background-color: #ffd27b;
}

@media (max-width: 768px) {
  .step-header__steps .step__circle {
    width: 30px;
    height: 30px;
  }
}
.step-header__steps .step__circle.active {
  border-color: #53c05e;
}
.step-header__steps .step__circle.wait {
  border-color: #ffd27b;
}
.step-header__steps .step__circle.wait path {
  fill: #ffd27b;
} /*# sourceMappingURL=registration-models-two.css.map */

.IN_PROGRESS {
  cursor: pointer;
  svg {
    color: #aeaeae;
  }
}
.PENDING {
  cursor: pointer;
  p {
    color: #ffd27b !important;
    border: 3px solid #ffd27b !important;
  }
  .step__line {
    background: #ffd27b !important;
  }
  svg {
    color: #ffd27b !important;
  }
}
.COMPLETED {
  cursor: pointer;
  p {
    color: #53c05e !important;
    border: 3px solid #53c05e !important;
  }
  .step__line {
    background: #53c05e !important;
  }
  svg {
    color: #53c05e !important;
  }
  .step__circle path {
    fill: #53c05e;
  }
  color: #53c05e !important;
  .step__circle {
    border: 3px solid #53c05e !important;
  }
}
