.usersStaff {
  .users-container {
    padding: 20px;
    font-family: sans-serif;
  }

  .filters {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
    flex-wrap: wrap;
  }

  .users-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
  }

  .users-table th,
  .users-table td {
    text-align: left;
    padding: 8px;
    border-bottom: 1px solid #ccc;
  }

  .pagination-container {
    display: flex;
    gap: 20px;
    align-items: center;
  }

  .pagination-buttons {
    display: flex;
    gap: 5px;
  }

  .pagination-button {
    padding: 5px 10px;
    border: 1px solid #ccc;
    background: #fff;
    cursor: pointer;
  }

  .pagination-button:hover {
    background: #eee;
  }

  .pagination-button.active {
    background: #eee;
    font-weight: bold;
  }

  .total-elements {
    font-weight: normal;
  }
}
