.visualizer-container {
  display: flex;
  align-items: center;
  .progress-bar {
    width: 100%;
    height: 10px;
    background-color: #eee;
    margin-top: 20px;

    .progress-bar-inner {
      height: 100%;
      // background-color: #00ffcc;
      background-color: 1px solid rgba(126, 224, 206, 1);
    }
  }
  canvas {
    height: 20px;
    width: 100%;
  }
  input[type='file'] {
    width: 90%;
    padding: 10px;
    border: none;
    background-color: #555;
    color: #fff;
    cursor: pointer;
    transition: background-color 0.3s;
    margin-bottom: 20px;
    outline: none;
  }

  input[type='file']:hover {
    background-color: #777;
  }
  .controls {
    display: flex;
    align-items: center;
    button {
      background: none;
      border: none;
      padding: 0;
      margin: 0;
      outline: none;
      font: inherit;
      color: inherit;
      text-align: inherit;
      cursor: pointer;
      appearance: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      -ms-appearance: none;
      // svg path {
      //   fill: black !important;
      // }
    }
  }
}
