.language-header_dark {
  background-color: black !important;
}

.language-header,
.language-header_dark {
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  gap: 12px;
  width: 177px;
  height: 37px;
  padding: 9px 16px;
  border-radius: 20px;
  box-shadow:
    0 3px 5px rgba(0, 0, 0, 0.05),
    0 1px 3px rgba(0, 0, 0, 0.08);
  background-color: #fff;
  cursor: pointer;

  .flag svg {
    width: 19px;
    height: 19px;
  }

  label {
    cursor: pointer;
    text-transform: capitalize;
    font-family: var(--font-family);
    font-size: 15px;
    font-weight: 400;
    line-height: 18.6px;
    white-space: nowrap; /* Запрещает перенос текста */
    overflow: hidden; /* Обрезает текст, если он превышает размер блока */
    text-overflow: ellipsis; /* Добавляет многоточие в конце обрезанного текста */
    width: 80px;
  }

  .dropdown-arrow svg {
    width: 17px;
    height: 7px;
    color: #000;
  }
}

@keyframes dropdownAnimation {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.dropdown-custom-open {
  animation: dropdownAnimation 0.35s ease-out;
}

@keyframes closeDropdownAnimation {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-20px);
  }
}
.dropdown-custom-close {
  animation: closeDropdownAnimation 0.35s ease-out;
}

@keyframes rotateUp {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-180deg);
  }
}

@keyframes rotateDown {
  from {
    transform: rotate(-180deg);
  }
  to {
    transform: rotate(0deg);
  }
}

.dropdown-arrow {
  transition: transform 0.3s ease;
  &.rotate-up {
    animation: rotateUp 0.3s forwards;
  }
  &.rotate-down {
    animation: rotateDown 0.3s forwards;
  }
}

@media (max-width: 380px) {
  .language-header,
  .language-header_dark {
    gap: 0.75rem;
    width: 5.75rem;

    label {
      display: none;
    }
  }
}
