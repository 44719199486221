.filter-girls {
  .filter-girls__row {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
    margin: 23px 12px 63px 12px;

    .online-filter,
    .language-filter,
    .search-name {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 24px;
      height: 37px;
      padding: 11px 22px;
      border-radius: 100px;
      font-family: var(--font-family);
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 19px;
      box-shadow:
        0 3px 5px rgba(0, 0, 0, 0.05),
        0 1px 3px rgba(0, 0, 0, 0.08);
      background-color: #fff;
      cursor: pointer;
    }

    .online-filter,
    .language-filter {
      color: var(--main-color);

      svg {
        width: 17px;
        height: 7px;
        color: rgba(0, 0, 0, 1);
      }
    }

    .search-name {
      color: hsl(248, 17%, 23%);

      div {
        padding-block: 10px 5px;

        svg {
          width: 17px;
          height: 17px;
          color: rgba(48, 26, 13, 1);
        }
      }
    }

    .language-filter-tooltip {
      .filter-tooltip {
        width: 620px;
        margin: auto;
        display: grid;
        justify-content: center;
        grid-template-columns: repeat(auto-fit, 150px);
      }
    }
  }

  @keyframes rotate-chevron-down {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(180deg);
    }
  }

  @keyframes rotate-chevron-up {
    0% {
      transform: rotate(180deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }

  .rotate-down {
    display: flex;
  }

  .rotate-up {
    display: flex;
  }

  .rotate-down svg {
    transform-origin: center center;
    animation: rotate-chevron-down 0.3s forwards;
  }

  .rotate-up svg {
    transform-origin: center center;
    animation: rotate-chevron-up 0.3s forwards;
  }
}

@media (max-width: 768px) {
  .filter-girls__row {
    flex-wrap: wrap;
    gap: 12px;
  }
}

@media (max-width: 650px) {
  .filter-girls {
    .filter-girls__row {
      .language-filter-tooltip {
        .filter-tooltip {
          width: 100vw;
        }
      }
    }
  }
}

@media (max-width: 400px) {
  .filter-girls__row {
    flex-direction: column;
    margin: 12px;

    & > div {
      width: 100%;

      .online-filter,
      .language-filter,
      .search-name {
        width: 100%;
      }
    }
  }
}
