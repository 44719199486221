.friends__sidebar {
  width: 64px;
  padding-block: 42px;
  border-right: 1px solid hsl(0, 0%, 91%);
  background-color: #fff;

  .sidebar__icons {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;

    .sidebar__icon {
      width: 64px;
      height: 3rem;
      display: grid;
      place-items: center;
      cursor: pointer;

      &.active {
        .primary-icon {
          path {
            fill: hsl(218, 100%, 73%);
            fill-opacity: 1;
          }
        }

        .friends-online {
          .online-icon {
            svg {
              path {
                stroke: hsl(218, 100%, 73%);
              }
            }
          }
        }

        .favorites {
          .favorites-icon {
            svg {
              path {
                fill: hsl(218, 100%, 73%);
                fill-opacity: 1;
              }
            }
          }
        }

        .friend-requests {
          .plus-icon {
            color: hsl(218, 100%, 73%);
          }
        }

        .blocked {
          .blocked-icon {
            svg {
              path {
                stroke: hsl(218, 100%, 73%);
              }
            }
          }
        }
      }

      .friends-online {
        position: relative;

        .online-icon {
          position: absolute;
          left: -7px;
          top: 1px;
          width: 12px;
          height: 12px;

          svg {
            width: 100%;
            height: 100%;

            path {
              stroke: hsla(234, 75%, 71%, 0.7);
            }
          }
        }
      }

      .favorites {
        position: relative;

        .favorites-icon {
          position: absolute;
          left: -7px;
          top: 2px;
          width: 14px;
          height: 14px;

          svg {
            width: 100%;
            height: 100%;
          }
        }
      }

      .friend-requests {
        position: relative;

        .plus-icon {
          position: absolute;
          left: -6px;
          top: -2px;
          color: hsl(234, 75%, 71%);
          opacity: 0.7;
          font-size: 20px;
          font-weight: 600;
        }

        .friend-requests-count {
          position: absolute;
          right: -6px;
          bottom: -4px;
          display: grid;
          place-items: center;
          width: 1rem;
          height: 1rem;
          padding: 1px;
          border-radius: 50%;
          font-weight: 400;
          font-size: 10px;
          color: #fff;
          background-color: var(--pink-color);
        }
      }

      .blocked {
        position: relative;

        .blocked-icon {
          position: absolute;
          left: -7px;
          top: 1px;
          width: 12px;
          height: 12px;

          svg {
            width: 100%;
            height: 100%;

            path {
              stroke: hsla(234, 75%, 71%, 0.7);
            }
          }
        }
      }
    }
  }
}

@media (max-width: 420px) {
  .friends__sidebar {
    width: 48px;
    padding-block: 8px;

    .sidebar__icon {
      width: 48px;
    }
  }
}
