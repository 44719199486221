.people__block {
  min-height: 100vh;
  max-width: calc(100vw - 184px);
  margin: 0 auto 5rem;

  .people__box {
    width: 100%;
    display: grid;
    justify-content: center;
    grid-template-columns: repeat(auto-fit, 303px);
    gap: 1rem;
  }

  .client__block {
    height: 294px;
    width: 303px;
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 9px;
    border-radius: 20px;
    font-family: var(--font-family);
    text-align: center;
    background-color: var(--light-background);
    box-shadow:
      0 3px 5px rgba(0, 0, 0, 0.08),
      0 1px 2px rgba(0, 0, 0, 0.1);

    .client__photo {
      position: relative;
      width: 87px;
      height: 87px;
      border-radius: 50%;

      svg {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    h2 {
      width: 100%;
      font-size: 20px;
      font-weight: 400;
      line-height: 24.8px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    p {
      font-size: 15px;
      font-weight: 400;
      line-height: 18.6px;
      color: var(--pink-color);
    }

    .client__buttons {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 10px;
      justify-content: center;
      align-items: center;
      text-align: center;

      button {
        width: 100%;
        height: 43px;
        padding: 11px 22px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 40px;
        background-color: var(--primary-blue-color);
        font-family: var(--font-family);
        font-size: 15px;
        font-weight: 400;
        line-height: 18.6px;

        &:hover {
          background-color: var(--secondary-blue-color);
        }

        svg {
          width: 20px;
          height: auto;
        }
      }
    }
  }
}

@media (max-width: 1068px) {
  .people__block {
    max-width: calc(100vw - 1rem * 2);
  }
}

@media (max-width: 360px) {
  .people__block {
    max-width: calc(100vw - 0.5rem * 2);

    .people__box {
      gap: 0.5rem;
    }
  }
}
