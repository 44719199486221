.pop-up__container {
  .circle {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, -45%);
    height: 75%;
    border-radius: 50%;
    aspect-ratio: 1/1;
  }

  .red {
    filter: blur(25px);
    background: #ff93d4;
  }
  .blue {
    filter: blur(10px);
    background: #adc9f7;
  }

  box-shadow: rgba(255, 1, 153, 0.46) 0 0 50px 15px;
  .buttonInfo {
    width: 23px;
  }

  &-info {
    display: flex;
    gap: 13px;
    align-items: center;
    width: 100%;
    justify-content: flex-end;
    .close {
      background: rgba(255, 255, 255, 0);
      padding: 0;
      margin: 0;
    }
  }

  &-text__info {
    max-width: 80%;
    font-size: 14px;
    font-weight: 400;
    margin-top: 13px;
  }

  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 256px;
  background: white;
  padding: 7px 14px 30px;
  header {
    line-height: 20px;
    font-size: 20px;
    font-weight: 300;
    text-align: center;
  }
  &__box {
    position: relative;
    margin-top: 19px;
    padding-top: 51px;
    color: white;
    &-price {
      position: relative;
      z-index: 4;
      border-radius: 15px;
      background: var(--primary-blue-color);
      font-size: 12px;
      font-weight: 300;
      padding: 11px 23px 11px 23px;
    }
  }

  &-btn {
    margin-top: 27px;
    display: flex;
    width: 100%;
    justify-content: space-between;

    .btn-action {
      font-size: 15px;
      font-weight: 400;
      width: 109px;
      padding: 12px 0;
      border-radius: 40px;
    }

    .btn__no {
      border: 1px solid var(--primary-blue-color);
      background: white;
      color: var(--primary-blue-color);
    }
    .btn__yes {
      background: var(--primary-blue-color);
      color: #fff;
    }
  }
}
