.step2-information {
  .step2-information-block_title {
    h2 {
      margin-block: 2rem;
      font-size: calc(0.75rem + 1.5vmin);
      color: #333;
      font-weight: 500;
    }
  }
}
