.withdrawal {
  min-height: 100vh;

  h2 {
    margin-block: 2rem;
    font-size: calc(0.95rem + 0.8vw);
    color: #333;
    font-weight: 500;
    text-align: center;
  }
}
