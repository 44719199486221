.dashboard {
  display: flex;
  min-width: 270px;
  height: 100vh;
  padding-bottom: 50px;
  width: 270px;
  transition: 0.5s;
  background: #fff;
  box-shadow: 0 12px 30px rgba(80, 143, 244, 0.1);
  &-block {
    width: 100%;

    ul {
      width: 100%;

      li {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        a {
          width: 100%;
          font-size: 18px;
          font-weight: 600;
          color: #2e4765;
          padding: 15px;
          background: #fff;
          transition: 0.3s;
          background: 0 0;

          &:hover {
            background: #27a93c;
          }
        }
      }
    }
  }
}
