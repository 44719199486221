.tooltip {
  background: #ffffff;
  border-radius: 8px; // скругленные углы
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); // тень
  width: 500px;
  padding: 20px; // внутренний отступ

  &-block {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around; // равномерное распределение элементов

    &_box {
      margin: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;

      button {
        margin-top: 10px;
        padding: 8px 16px;
        border-radius: 4px;
        border: none;
        background-color: var(--primary-blue-color);
        color: white;
        cursor: pointer;
        transition: background-color 0.3s;

        &:hover {
          background-color: var(--secondary-blue-color);
        }
      }

      svg {
        width: 120px !important;
        height: 120px !important;
        margin-bottom: 10px; // добавлен отступ снизу для svg
      }
    }
  }
}

.tippy-box {
  max-width: 100% !important;
}

// Для экранов с шириной менее 800px
@media (max-width: 800px) {
  .tooltip {
    width: 300px;
    padding: 10px; // внутренний отступ

    &-block {
      &_box {
        margin: 10px;

        button {
        }

        svg {
          width: 90px !important;
          height: 90px !important;
          margin-bottom: 10px; // добавлен отступ снизу для svg
        }
      }
    }
  }
}

// Для экранов с шириной менее 600px
@media (max-width: 600px) {
}
