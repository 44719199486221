.referal {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  .item {
    margin-right: 270px;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    max-width: 600px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    font-family: Arial, sans-serif;
  }

  .item h1 {
    margin-right: 270px;
    font-size: 24px;
    margin-bottom: 20px;
    color: #333;
  }

  .item p {
    word-wrap: break-word;
    font-size: 16px;
    margin: 10px 0;
    color: #555;
  }

  .item p strong {
    color: #333;
  }

  .item input[type='text'],
  .item textarea {
    width: 100%;
    padding: 10px;
    margin-top: 5px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    box-sizing: border-box;
  }

  .item textarea {
    height: 100px;
    resize: vertical;
  }

  .item button {
    padding: 10px 20px;
    margin: 5px;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
  }

  .item button:focus {
    outline: none;
  }

  .item button:hover {
    opacity: 0.9;
  }

  .save-btn {
    background-color: #4caf50;
    color: white;
  }

  .cancel-btn {
    background-color: #ff9800;
    color: white;
  }

  .edit-btn {
    background-color: #2196f3;
    color: white;
  }

  .delete-btn {
    background-color: #f44336;
    color: white;
  }

  h1 {
    padding: 50px;
    margin-right: 270px;
    color: #333;
    font-size: 2.5em;
    text-align: center;
  }

  .referal-filter,
  .referral-create {
    margin-right: 270px;
    width: 600px;
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
  }

  .referal-filter h1,
  .referral-create h2 {
    color: #555;
    margin-bottom: 15px;
  }

  input[type='text'],
  input[type='number'],
  textarea {
    width: calc(100% - 22px);
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1em;
  }

  textarea {
    height: 100px;
  }

  button {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1em;
    transition: background-color 0.3s ease;
  }

  button:hover {
    background-color: #0056b3;
  }

  .referal-filter input[type='text'],
  .referal-filter input[type='number'] {
    display: block;
    margin: 0 auto;
    width: 80%;
    margin-bottom: 10px;
  }
}
