.TooltipGift {
  width: 100%;
  flex: 1;

  .circle {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, -45%);
    height: 65%;
    border-radius: 50%;
    aspect-ratio: 1/1;
  }

  .red {
    filter: blur(30px);
    background: #ff93d4;
  }
  .blue {
    filter: blur(20px);
    background: #adc9f7;
  }

  .RenderGiftIconAndName {
    max-width: 100%;
    max-height: 100%;
  }
  .tooltips-gift {
    width: 100%;
    height: 100%;
    background: white;
    border-radius: 20px;

    &_block {
      padding: 20px 0 0;
      min-height: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: 1fr 1fr;
      flex-wrap: wrap;
      justify-content: space-around; // равномерное распределение элементов по горизонтали
      align-items: center; // центрирование элементов по вертикали

      &__box {
        height: 100%;
        position: relative;
        cursor: pointer;
        padding-top: 40px;
        text-align: center;
        margin: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
      }

      button {
        position: relative;
        z-index: 3;
        background-color: var(--primary-blue-hover);
        color: #ffffff;
        font-size: 13px;
        font-weight: 300;
        line-height: 15px;
        margin-bottom: 10px;
        padding: 6px 12px;
        border: none;
        outline: none;
        border-radius: 15px;
        cursor: pointer;
        transition: background-color 150ms ease;
      }

      button:hover {
        background-color: var(--intense-blue-color);
      }

      svg {
        max-height: 30px;
      }
    }
  }
}

@media (max-width: 600px) {
  .TooltipGift {
    .tooltips-gift {
      &_block {
        grid-template-rows: 1fr 1fr 1fr;
        grid-template-columns: 1fr 1fr;
      }
    }
  }
}
