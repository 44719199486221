.filter-people__row {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  margin: 23px 12px 63px 12px;

  .online-filter,
  .friends-filter {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 24px;
    height: 37px;
    padding: 11px 22px;
    border-radius: 100px;
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 19px;
    color: var(--main-color);
    background-color: #fff;
    box-shadow:
      0 3px 5px rgba(0, 0, 0, 0.05),
      0 1px 3px rgba(0, 0, 0, 0.08);
    cursor: pointer;

    svg {
      width: 17px;
      height: 7px;
      color: rgba(0, 0, 0, 1);
    }
  }
}

@keyframes rotate-chevron-down {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(180deg);
  }
}

@keyframes rotate-chevron-up {
  0% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.rotate-down {
  display: flex;
}

.rotate-up {
  display: flex;
}

.rotate-down svg {
  transform-origin: center center;
  animation: rotate-chevron-down 0.3s forwards;
}

.rotate-up svg {
  transform-origin: center center;
  animation: rotate-chevron-up 0.3s forwards;
}

@media (max-width: 360px) {
  .filter-people__row {
    flex-direction: column;
    gap: 0.5rem;
    margin-block: 1.5rem;

    .online-filter,
    .friends-filter {
      width: calc(100vw - 1rem);
      width: 303px;
    }
  }
}
