.RateVideoCall {
  .LogicStars {
    background-image: url('https://www.toptal.com/designers/subtlepatterns/patterns/concrete-texture.png');
    display: flex;
    flex-wrap: wrap;
    height: 100vh;
    align-items: center;
    justify-content: center;
    padding: 0 20px;

    .rating {
      display: flex;
      width: 100%;
      justify-content: center;
      overflow: hidden;
      flex-direction: row-reverse;
      height: 150px;
      position: relative;
    }

    /* Remove label-specific styles and use divs instead */
    .rating-item {
      cursor: pointer;
      width: 40px;
      height: 40px;
      margin-top: auto;
      background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='126.729' height='126.73'%3e%3cpath fill='%23e3e3e3' d='M121.215 44.212l-34.899-3.3c-2.2-.2-4.101-1.6-5-3.7l-12.5-30.3c-2-5-9.101-5-11.101 0l-12.4 30.3c-.8 2.1-2.8 3.5-5 3.7l-34.9 3.3c-5.2.5-7.3 7-3.4 10.5l26.3 23.1c1.7 1.5 2.4 3.7 1.9 5.9l-7.9 32.399c-1.2 5.101 4.3 9.3 8.9 6.601l29.1-17.101c1.9-1.1 4.2-1.1 6.1 0l29.101 17.101c4.6 2.699 10.1-1.4 8.899-6.601l-7.8-32.399c-.5-2.2.2-4.4 1.9-5.9l26.3-23.1c3.8-3.5 1.6-10-3.6-10.5z'/%3e%3c/svg%3e");
      background-repeat: no-repeat;
      background-position: center;
      background-size: 76%;
      transition: 0.3s;
      opacity: 0.5; /* Default opacity */
    }

    .rating-item.selected {
      background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='126.729' height='126.73'%3e%3cpath fill='%23fcd93a' d='M121.215 44.212l-34.899-3.3c-2.2-.2-4.101-1.6-5-3.7l-12.5-30.3c-2-5-9.101-5-11.101 0l-12.4 30.3c-.8 2.1-2.8 3.5-5 3.7l-34.9 3.3c-5.2.5-7.3 7-3.4 10.5l26.3 23.1c1.7 1.5 2.4 3.7 1.9 5.9l-7.9 32.399c-1.2 5.101 4.3 9.3 8.9 6.601l29.1-17.101c1.9-1.1 4.2-1.1 6.1 0l29.101 17.101c4.6 2.699 10.1-1.4 8.899-6.601l-7.8-32.399c-.5-2.2.2-4.4 1.9-5.9l26.3-23.1c3.8-3.5 1.6-10-3.6-10.5z'/%3e%3c/svg%3e");
      opacity: 1; /* Fully visible when selected */
    }

    .rating-item:hover,
    .rating-item:hover ~ .rating-item {
      background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='126.729' height='126.73'%3e%3cpath fill='%23d8b11e' d='M121.215 44.212l-34.899-3.3c-2.2-.2-4.101-1.6-5-3.7l-12.5-30.3c-2-5-9.101-5-11.101 0l-12.4 30.3c-.8 2.1-2.8 3.5-5 3.7l-34.9 3.3c-5.2.5-7.3 7-3.4 10.5l26.3 23.1c1.7 1.5 2.4 3.7 1.9 5.9l-7.9 32.399c-1.2 5.101 4.3 9.3 8.9 6.601l29.1-17.101c1.9-1.1 4.2-1.1 6.1 0l29.101 17.101c4.6 2.699 10.1-1.4 8.899-6.601l-7.8-32.399c-.5-2.2.2-4.4 1.9-5.9l26.3-23.1c3.8-3.5 1.6-10-3.6-10.5z'/%3e%3c/svg%3e");
      opacity: 1;
    }

    .emoji-wrapper {
      width: 100%;
      text-align: center;
      height: 100px;
      overflow: hidden;
      position: absolute;
      top: 0;
      left: 0;
    }

    .emoji-wrapper:before,
    .emoji-wrapper:after {
      content: '';
      height: 15px;
      width: 100%;
      position: absolute;
      left: 0;
      z-index: 1;
    }

    .emoji-wrapper:before {
      top: 0;
      background: linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 35%, rgba(255, 255, 255, 0) 100%);
    }

    .emoji-wrapper:after {
      bottom: 0;
      background: linear-gradient(to top, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 35%, rgba(255, 255, 255, 0) 100%);
    }

    .emoji {
      display: flex;
      flex-direction: column;
      align-items: center;
      transition: 0.3s;
    }

    .emoji > div {
      font-size: 70px;
      margin: 15px 0;
      width: 70px;
      height: 70px;
      flex-shrink: 0;
    }

    /* Adjust the transform based on the rating state */
    .emoji {
      transform: translateY(0);
      transition: transform 0.3s;
    }

    /* No need for #rating-X:checked styles */

    .feedback {
      min-width: 360px;
      max-width: 360px;
      background-color: #fff;
      width: 100%;
      padding: 30px;
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      align-items: center;
      box-shadow: 0 4px 30px rgba(0, 0, 0, 0.05);
    }
  }
  textarea {
    width: 100%;
    height: 120px;
    padding: 12px 20px;
    box-sizing: border-box;
    border: 2px solid #ccc;
    border-radius: 4px;
    background-color: #f8f8f8;
    font-size: 16px;
    resize: none;
  }
  .actions {
    margin-top: 10px;
    button {
      margin: 5px;
    }
  }
}
