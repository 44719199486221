@media (min-width: 768px) {
  .videoChat-text {
    min-height: 250px;
    max-height: calc(100vh - 69px);
    height: initial !important;
  }

  .message-user__chat {
    min-height: 250px;
    max-height: calc(100vh - 69px) !important;
  }
}

.videoChat-text {
  position: absolute;
  left: 0;
  width: min(100%, 500px);
  bottom: 69px;
  height: 250px;

  background: linear-gradient(
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.15) calc(5% + 10px),
    rgba(0, 0, 0, 0.32) calc(10% + 20px),
    rgba(0, 0, 0, 0.5) calc(50% + 30px),
    rgba(0, 0, 0, 0.7) 100%
  );

  -webkit-mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) calc(10% + 20px), rgb(0, 0, 0) calc(20% + 40px));

  mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) calc(10% + 20px), rgb(0, 0, 0) calc(20% + 40px));

  .message-user__content,
  .message-user__chat-container,
  .message-user__chat {
    height: 100% !important;
  }

  .message-user__chat {
    padding-bottom: 84px !important;
    gap: 0px !important;

    .info-message {
      display: none !important;
    }

    .me,
    .you {
      margin-top: 1rem;
    }

    .me {
      .chat__item-content {
        background: linear-gradient(
          270.16deg,
          hsla(273, 100%, 70%, 0.7) 0.13%,
          hsla(218, 90%, 65%, 0.7) 47.04%,
          hsla(218, 100%, 60%, 0.7) 99.93%
        ) !important;
      }
    }

    .chat__item-content {
      gap: 0.3rem !important;
      width: 85% !important;
      padding: 0.5rem 0.75rem !important;
      background-color: #0000003b !important;
      color: hsl(0deg 0% 100% / 90%) !important;

      > .text-message_translate {
        padding-top: 0.3rem !important;
        margin-bottom: 0rem;
        gap: 2px !important;
      }
    }
  }

  .chat-form__container {
    position: absolute;
    bottom: 0;
    left: 0;
    margin-bottom: 0.5rem;

    .chat-form {
      position: static !important;
    }

    form .chat-form__inner {
      left: 0;
      bottom: 0;
      height: 50px !important;
      width: 300px;
      padding-right: 12px !important;
      background-color: #00000075 !important;
      border-radius: 2rem !important;
      height: 50px;

      input {
        color: white;
      }
    }

    svg > rect {
      visibility: hidden;
    }
  }
}

// Common Styles (Dark and Light)
.lk-room-container {
  .lk-grid-layout {
    padding: 0px;
    border-radius: 0;
  }

  // FIX: border radius in Language selector
  .lk-button.lk-chat-toggle {
    border-radius: 0.5rem;
  }

  // FIX: center dropdown arrow in language selector
  .language-header_dark .dropdown-arrow {
    align-self: center;
  }

  // PROPOSAL: drop-down menu of mic and cam pasted..
  .lk-button-group-custom-menu > button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .lk-button-group-custom > .lk-button {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;

    &::before {
      content: '';
      position: absolute;
      top: 15%;
      bottom: 15%;
      right: 0;
      width: 1px;
      background-color: dimgrey;
    }
  }

  .gift {
    .lk-device-menu-custom,
    .actions-buttons {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
    }
  }

  // Add class in LiveKitCustom/Component/Button/Gift/Gift.tsx
  .lk-gift-button {
    padding: 10px 15px;

    .messages-dialog_block__left___menu {
      display: flex;
    }
  }

  // Add class in LiveKitCustom/Component/CustomButton.tsx
  .lk-chat-button {
    padding: 13px 19px;

    svg {
      transform: scale(1.2) translateY(2px);
    }
  }
}

.dark {
  .chat-form__container form .chat-form__inner {
    background-color: #4f4f4f75 !important;
  }

  .you .chat__item-content {
    background-color: #6868683b !important;
  }

  .lk-control-bar {
    background: #111;

    .gift {
      background-color: #333333;

      .tippy-arrow::before {
        color: #333333;
      }
    }

    // FIX: dropdown arrow dont see in black in language selector
    .dropdown-arrow path {
      fill: white;
    }
  }
}

.light {
  $primary-color: #545579;
  $bg-color: #f0f1ff;
  $bg-white: #fbfbfe;

  .videoChat-text {
    background: linear-gradient(
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.15) calc(5% + 10px),
      rgba(255, 255, 255, 0.32) calc(10% + 20px),
      rgba(255, 255, 255, 0.5) calc(50% + 30px),
      rgba(255, 255, 255, 0.7) 100%
    );
    -webkit-mask-image: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.7) calc(10% + 20px),
      rgb(255, 255, 255) calc(20% + 40px)
    );
    mask-image: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.7) calc(10% + 20px), rgb(255, 255, 255) calc(20% + 40px));
  }

  .chat-form__container form .chat-form__inner {
    background-color: $bg-color !important;

    input {
      color: black;
    }

    g path {
      fill: $primary-color;
    }
  }

  .you .chat__item-content {
    background-color: #ffffffbd !important;
    color: $primary-color !important;
  }

  .lk-focus-layout-wrapper {
    .lk-grid-layout {
      background: #c6c6c6;

      > .lk-participant-tile:first-of-type {
        box-shadow:
          0 3px 5px rgba(0, 0, 0, 0.05),
          0 1px 3px rgba(0, 0, 0, 0.08);
      }
    }
    .lk-participant-placeholder {
      background-color: #c6c6c6;
    }
  }

  .lk-control-bar {
    background-color: $bg-white;

    .lk-button-group-custom-menu .tippy-box {
      background-color: $bg-color;

      .tippy-arrow::before {
        color: $bg-color;
      }

      [data-lk-active='true'] > .lk-button {
        background-color: #6871f0;
        color: white;
      }

      button {
        color: $primary-color;
        background-color: $bg-white;
        transition: background-color 150ms ease;

        [data-lk-active='true'] {
          background-color: red;
        }

        &:hover {
          background-color: #d7d7ff;
        }
      }
    }

    .gift {
      background-color: $bg-color;

      button {
        color: $primary-color;
        background-color: $bg-white;
        transition: background-color 150ms ease;

        &:hover {
          background-color: #d7d7ff;
        }
      }

      .tippy-arrow::before {
        color: $bg-color;
      }
    }

    .lk-button-group-custom > button,
    .lk-button-group-menu > button,
    .lk-button-custom-arrow,
    .actions-buttons > button,
    .timeStartStop > button,
    & > .coin > button,
    & > button {
      background-color: $bg-color;
      color: $primary-color;
      transition: background-color 150ms ease;

      &:hover {
        background-color: #d7d7ff;
        transition: background-color 150ms ease;
      }
    }

    .lk-button-group-custom > .lk-button::before {
      background-color: #8ca2d1;
    }

    .messages-dialog_block__left___menu path,
    .arrow-icon {
      fill: $primary-color;
    }

    .language-header_dark {
      background-color: white !important;
    }

    .lk-button-custom-arrow,
    .language-header_dark label {
      color: $primary-color;
    }

    .lk-disconnect-button {
      background-color: $bg-color;
    }

    .lk-disconnect-button:not(:disabled):hover {
      background: #fa3343;
      color: white;
    }
  }
}

.chat-form__container .chat-form form .chat-form__inner {
  background-color: #00000075 !important;
}
