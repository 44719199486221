.firework {
  position: absolute;
  transform-origin: center center;
}

.explosion {
  position: absolute;
  left: -2px;
  bottom: 0;
  width: 4px;
  height: 80px;
  transform-origin: 50% 100%;
  overflow: hidden;

  &:nth-child(1) {
    transform: rotate(0deg) translateY(-15px);
  }
  &:nth-child(2) {
    transform: rotate(30deg) translateY(-15px);
  }
  &:nth-child(3) {
    transform: rotate(60deg) translateY(-15px);
  }
  &:nth-child(4) {
    transform: rotate(90deg) translateY(-15px);
  }
  &:nth-child(5) {
    transform: rotate(120deg) translateY(-15px);
  }
  &:nth-child(6) {
    transform: rotate(150deg) translateY(-15px);
  }
  &:nth-child(7) {
    transform: rotate(180deg) translateY(-15px);
  }
  &:nth-child(8) {
    transform: rotate(210deg) translateY(-15px);
  }
  &:nth-child(9) {
    transform: rotate(240deg) translateY(-15px);
  }
  &:nth-child(10) {
    transform: rotate(270deg) translateY(-15px);
  }
  &:nth-child(11) {
    transform: rotate(300deg) translateY(-15px);
  }
  &:nth-child(12) {
    transform: rotate(330deg) translateY(-15px);
  }
}

.explosion-inner {
  position: absolute;
  left: 0;
  right: 0;
  top: 100%;
  height: 40px;
  animation: explosion 2s ease-in-out infinite;
}

@keyframes explosion {
  0% {
    top: 100%;
  }
  33%,
  100% {
    top: -50%;
  }
}

.firework {
  /* Изначальная анимация */
  animation: spin 2s infinite linear;

  /* Когда срабатывает класс fade-out, уводим в прозрачность */
  &.fade-out {
    animation: none;
    transition: opacity 1s ease-out;
    opacity: 0;
  }
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
