.staff-view {
  /* styles.css */

  .filters {
    background-color: #4caf50;
    color: white;
    padding: 10px 20px;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  h2 {
    margin: 0;
  }

  .staff-item {
    background-color: white;
    margin: 20px auto;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 600px;
  }

  .staff-item h3 {
    margin-top: 0;
    color: #333;
  }

  .staff-item p {
    margin: 5px 0;
    color: #666;
  }

  .staff-item p span {
    font-weight: bold;
    color: #333;
  }

  @media (max-width: 600px) {
    .staff-item {
      padding: 15px;
      margin: 10px;
    }
  }
}
