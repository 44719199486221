.girl {
  min-height: 100vh;
}

.girl-profile {
  min-height: 100vh;

  & * {
    font-family: var(--font-family);
    font-weight: 400;
  }

  .container {
    padding: 0;
  }

  .box {
    gap: 4px;
    display: flex;
    background: white;
    padding: 10px;
    flex-direction: column;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;

    & > * {
      width: 100%;
    }

    .btn-relationship {
      height: 50px;
      padding: 12px 24px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: 40px;
      -webkit-border-radius: 40px;
      -moz-border-radius: 40px;
      -ms-border-radius: 40px;
      -o-border-radius: 40px;
      font-size: 15px;
      color: white;
      text-align: left;

      @media (max-width: 420px) {
        padding-inline: 20px;
      }

      .btn-icon {
        width: 28px;
        height: auto;

        svg {
          width: 25px;
          height: 25px;
        }
      }
    }

    .btn-favorites {
      color: white;
      background: hsl(324, 100%, 75%);
      transition: all 150ms ease;

      &:hover {
        background-color: hsla(324, 100%, 50%, 0.9);

        svg {
          path {
            fill: hsl(324, 100%, 75%);
          }
        }
      }
    }
  }

  .favoriteNoLike {
    width: 100%;
    justify-content: center;
    display: flex;
    flex: 1;
    gap: 21px;
    align-items: center;
    cursor: pointer;
  }
  .gift {
    border-radius: 10px;
    background-color: #a4c2d0;
  }

  .account__inner {
    margin-bottom: 3rem;
    display: flex;
    gap: 10px;
  }

  @media (max-width: 1024px) {
    .account__inner {
      flex-direction: column;
      align-items: center;
    }
  }

  .account__inner-img {
    height: 433px;
    border-radius: 20px;
    width: 476px;
    flex-shrink: 0;
  }

  @media (max-width: 768px) {
    .account__inner-img {
      width: 200px;
      height: 100%;
    }
  }

  .account__content .box {
    display: flex;
    justify-content: space-between;
  }

  @media (max-width: 768px) {
    .account__content .box {
      flex-direction: column;
    }
  }

  .account__content .box__buttons {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  @media (max-width: 768px) {
    .account__content .box__buttons {
      flex-direction: row;
      margin-bottom: 20px;
    }
  }

  .account__content .box__buttons button {
    border: none;
    width: 160px;
    padding: 11px 20px;
    border-radius: 40px;
    background: var(--primary-blue-color);
    text-align: center;
    color: #fff;
  }

  .account__content .box-list h6 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
    font-size: 20px;
  }

  .account__content .box__left {
    display: flex;
    flex-wrap: wrap;
    -moz-column-gap: 53px;
    column-gap: 53px;
  }

  .account__content .box-right {
    padding: 33px 44px;
    text-align: center;
    border-radius: 20px;
    background: #bcd6e2;
    filter: drop-shadow(0px 1px 10px #1d69ff);
  }

  @media (max-width: 768px) {
    .account__content .box-right {
      padding: 10px;
      font-size: 10px;
    }
  }

  .account-nav__list {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 57px;
  }

  @media (max-width: 768px) {
    .account-nav__list {
      display: inline-flex;
      gap: 20px;
      float: left;
      padding-left: 20px;
      padding-top: 13px;
    }
  }

  .account-nav__link.active {
    color: var(--red-color);
  }

  .account__logo {
    display: block;
    width: 78px;
    max-height: 68px;
  }

  @media (max-width: 768px) {
    .account__logo {
      margin: 0 auto;
    }
  }

  .account__logo img {
    -o-object-fit: cover;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  .account__title {
    font-size: 24px;
    margin-bottom: 42px;
  }

  @media (max-width: 768px) {
    .account__title {
      margin-bottom: 40px;
    }
  }

  .account__main {
    display: flex;
    justify-content: center;
  }

  .account__nav {
    width: 100px;
    display: flex;
    gap: 5px;
    align-items: center;
    margin-bottom: 18px;
    cursor: pointer;
  }

  .account__item + .account__item {
    margin-top: 50px;
  }

  .account__item-top {
    margin-bottom: 50px;
    font-size: 20px;
    display: flex;
    gap: 10px;
  }

  .account__item-box {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 109px;
  }

  @media (max-width: 1024px) {
    .account__item-box {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  @media (max-width: 768px) {
    .account__item-box {
      grid-template-columns: repeat(2, 1fr);
      gap: 23px;
    }
  }

  .account__item-box .item {
    padding: 13px;
    text-align: center;
    border-radius: 20px;
    background: #adc9f7;
    filter: drop-shadow(0px 0px 8px #1d69ff);
    font-size: 20px;
  }

  .account__item-box .item .text {
    font-weight: 300;
    color: var(--main-color);
  }

  .account__item-box .item .red {
    color: var(--pink-color);
  }

  .container {
    width: calc(100% - 20px);
    max-width: 1450px;
    margin: 0 auto;
  }

  .account__inner {
    width: 100%;
  }

  .account__inner {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .account__nav {
    width: 100%;
    display: flex;
    justify-content: start;
  }

  .account-slider__bottom .slick-track {
    display: flex !important;
    justify-content: space-between !important;
    width: 100% !important;
  }

  .account-slider__bottom .account-slider__img {
    width: 63px !important;
    height: 63px;
  }

  .slick-list {
    padding: 0px 0px !important;
    width: 100% !important;
  }

  .account-slider__img img {
    border-radius: 12px;
  }

  /*# sourceMappingURL=account.css.map */
  .slick-slider {
    position: relative;

    display: block;
    box-sizing: border-box;

    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
  }

  .slick-list {
    position: relative;
    display: block;
    overflow: hidden;
    margin: 0;
    padding: 0;
  }

  .slick-list:focus {
    outline: none;
  }

  .slick-list.dragging {
    cursor: pointer;
    cursor: hand;
  }

  .slick-slider .slick-track,
  .slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  .slick-track {
    position: relative;
    top: 0;
    left: 0;

    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .slick-track:before,
  .slick-track:after {
    display: table;

    content: '';
  }

  .slick-track:after {
    clear: both;
  }

  .slick-loading .slick-track {
    visibility: hidden;
  }

  .slick-slide {
    display: none;
    float: left;

    height: 100%;
    min-height: 1px;
  }

  [dir='rtl'] .slick-slide {
    float: right;
  }

  .slick-slide img {
    display: block;
  }

  .slick-slide.slick-loading img {
    display: none;
  }

  .slick-slide.dragging img {
    pointer-events: none;
  }

  .slick-initialized .slick-slide {
    display: block;
  }

  .slick-loading .slick-slide {
    visibility: hidden;
  }

  .slick-vertical .slick-slide {
    display: block;

    height: auto;

    border: 1px solid transparent;
  }

  .slick-arrow.slick-hidden {
    display: none;
  }

  .audioMp3 {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    border-radius: 12px;
    background-color: transparent;
    box-shadow: 0 0 0 rgba(0, 0, 0, 0);
    width: 100%;
    margin: 0px auto;

    svg {
      width: 40px;
      height: 40px;
      margin-right: 9px;
    }
  }

  .flex {
    display: flex;
    gap: 8px;
  }

  .flex-column {
    display: flex;
    gap: 8px;
    flex-direction: column;
    .right {
      background: #ffebf7;
      display: flex;
      align-items: center;
      padding: 30px 11px;
      border-radius: 20px;
      -webkit-border-radius: 20px;
      -moz-border-radius: 20px;
      -ms-border-radius: 20px;
      -o-border-radius: 20px;
      &-box {
        max-width: 1000px;
        margin: 0 auto;
        color: #00010e;

        p {
          font-family: 'Rubik', sans-serif;
          font-size: 14px;
          padding: 0;
          margin: 0;
        }
      }
    }

    &-audio {
      display: flex;
      justify-content: start;
      width: 205px;
    }

    &-action {
      display: flex;

      &_svg {
        position: relative;
        margin-right: 20px;

        &__line {
          position: relative;
          width: 1px;
          height: 2px;
        }

        svg {
          width: 40px;
          height: 40px;
        }
      }
    }
  }

  .right-price {
    .right-priceBox {
      width: 100%;
    }
    &__wrapper {
      display: flex;
      justify-content: start;
    }

    &-btn {
      width: 100%;
      display: flex;
      justify-content: start;
      gap: 78px;

      button {
        width: 185px;
        height: 43px;
        display: flex;
        align-items: center;
        color: var(--primary-blue-color);
        background: white;
        border: var(--primary-blue-color) 1px solid;
        padding: 3px;
        border-radius: 96px;
        -webkit-border-radius: 96px;
        -moz-border-radius: 96px;
        -ms-border-radius: 96px;
        -o-border-radius: 96px;

        &:hover {
          border-color: var(--intense-blue-color);
          color: var(--intense-blue-color);

          .icon {
            background-color: var(--intense-blue-color);
          }
        }
      }

      .icon {
        transform: rotate(0deg);
        transition: transform 0.3s ease;
      }
      .icon.hide {
        transform: rotate(180deg);
        transition: transform 0.3s ease;
      }
    }

    .right-price__info {
      overflow: hidden;
      transition: max-height 0.5s ease;
      max-height: 100px;

      span {
        font-size: 16px;
      }
    }

    &__info.hidden {
      max-height: 110px;
      transition: max-height 1s cubic-bezier(0.23, 1.12, 1, 0.93);
    }

    &__info.visible {
      max-height: 700px;
      transition: 1s linear;
    }

    background: white;

    margin-top: 8px;
    border-radius: 20px;
    // width: 61%;
    display: flex;
    flex-direction: column;
    gap: 33px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
  }

  .right-price-btn .text {
    flex: 1;
  }

  .right-price-btn .icon {
    width: 37px;
    aspect-ratio: 1/1;
    background: var(--primary-blue-color);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
  }

  .box-list {
    display: flex;
    flex-direction: column;
    background: white;
    // width: 419px;
    min-width: 55%;
    border-radius: 20px; /* скругление углов */
    /* отступ внутри блока */

    .block {
      display: flex; /* Это включает Flexbox для контейнера */
      align-items: center; /* Это выравнивает элементы по центру по вертикали */
      justify-content: start; /* Это распределяет пространство между элементами горизонтально */

      /* Заголовок */
      h6 {
        margin-bottom: 10px;
        font-size: 24px;
        font-weight: 500;
        font-family: 'Unbounded', sans-serif;
        color: #333; /* цвет текста */
      }
    }

    .box-list__audio {
      width: 162px;

      .audioMp3 {
        background-color: transparent;
        box-shadow: 0 0 0 rgba(0, 0, 0, 0);
        margin: 0 0 8px 0;
        padding: 0;

        .visualizer-container {
          width: 100%;
          height: 29px;
          gap: 8px;

          .controls {
            width: 29px;
            height: 29px;
            border-radius: 50%;
            background-color: var(--primary-blue-hover);

            button {
              width: 29px;
              height: 29px;
              display: flex;
              align-items: center;
              justify-content: center;

              svg {
                width: 13px;
                margin: 0;
                height: 13px;
                border: none;
              }
            }
          }

          canvas {
            height: 19px;
          }
        }
      }
    }

    /* Список */
    ul {
      list-style-type: none; /* убираем маркеры списка */
      padding: 0; /* убираем отступы */
      display: flex;
      gap: 8px;
      flex-direction: column;
    }

    /* Элемент списка */
    li {
      display: flex; /* выстраиваем див и текст в ряд */
      align-items: center; /* выравниваем элементы по центру по вертикали */
      height: 30px;
      gap: 8px;
    }

    /* Жирный текст */
    strong {
      color: #000000; /* темно-серый цвет */
      white-space: nowrap;
    }
  }
}

.box-list__box {
  width: 100%;
  display: flex;
  align-items: center;
  font-family: 'Unbounded', sans-serif;
  p {
    font-size: 15px;
    color: var(--pink-color);
    font-weight: 400;
  }
  strong {
    font-weight: 400;
    font-size: 15px !important;
  }
}
.box-list__languages {
  display: flex;
  justify-content: center;
  align-items: center;
  .buttonInfo {
    margin-left: 5px;
  }
}
.right-price {
  min-height: 117px;
  justify-content: center;
  align-items: center;
  p {
    font-weight: 400;
  }
}
.right-box_mob {
  display: none;
  background: #bcd7e2;
  border-radius: 7px;
  max-width: 1000px;
  margin: 0 auto;
  box-shadow: 0 0 7px 5px #94b4ef;
  font-weight: 600;
  padding: 20px;
  color: #00010e;
  margin-bottom: 50px;
}

@media (min-width: 1320px) {
  .account__content {
    width: 62%;

    .flex {
      flex-direction: row-reverse;
    }
  }
}

@media screen and (max-width: 1320px) {
  .account__content {
    width: 50%;

    .flex {
      flex-direction: column;
    }
  }
}

@media screen and (max-width: 1024px) {
  .account__content {
    width: 100%;
  }
}

@media screen and (max-width: 600px) {
  .girl-profile .right-price-btn {
    gap: 20px;
    justify-content: space-between;
  }
}

@media (min-width: 500px) {
  .box-list {
    padding: 30px;
  }

  .box-list__box {
    justify-content: space-between;
  }
}

@media (max-width: 500px) {
  .container {
    max-width: 100%;

    .flex {
      flex-direction: column;
    }

    .box-list {
      padding: 20px 10px;
    }

    .box-list__box {
      justify-content: start;
      gap: 3px;
    }

    .right-box_mob {
      display: flex;
    }

    .box-list__audio {
      width: 100% !important;
    }
  }
}

@media (min-width: 500px) {
  .right-price {
    padding: 30px;
  }
}

@media (max-width: 500px) {
  .box-list {
    p {
      font-size: 14px;
    }
  }

  .right-price {
    padding: 20px 10px;
    p {
      font-size: 12px;
    }
  }
}
