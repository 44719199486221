.webcam {
  display: flex;
  justify-content: center;
  width: 100%;

  &-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-height: 700px;
    gap: 20px;
    padding: 40px 20px;
    border-radius: 8px;

    video {
      width: 100%;
      height: 100%;
    }

    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 5px solid hsla(339, 100%, 50%, 0.75); // Красная рамка
      border-radius: 8px;
      box-sizing: border-box;
      pointer-events: none; // Чтобы не блокировать взаимодействие с видео

      &::before,
      &::after {
        content: 'Подсказка сверху'; // Подсказка сверху
        position: absolute;
        top: 10px;
        left: 50%;
        transform: translateX(-50%);
        background-color: hsla(339, 100%, 50%, 0.75); // Полупрозрачный красный фон
        color: white;
        padding: 2px 8px;
        font-size: 12px;
        pointer-events: none;
      }

      &::after {
        content: 'Подсказка снизу'; // Подсказка снизу
        top: auto;
        bottom: 10px;
      }
    }

    .display {
      display: block;
      border: 2px solid #ccc;
    }

    .none {
      display: none;
      border: none;
    }

    video {
      border-radius: 10px;
      // box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    }

    button {
      padding: 12px 20px;
      border: none;
      border-radius: 8px;
      font-size: 14px;
      color: #fff;
      background-color: hsla(218, 100%, 60%, 0.75);
      transition: background-color 150ms ease;
      cursor: pointer;
    }

    button:hover {
      background-color: hsla(218, 100%, 60%, 0.95);
    }

    a {
      margin-top: 20px;
      color: hsl(0, 0%, 28%);
      font-size: 15px;
      text-decoration: underline;
      text-underline-offset: 3px;
      transition: all 150ms ease;

      &:hover {
        color: var(--pink-color);
        text-underline-offset: 5px;
      }
    }
  }
}
