.AdminCreatePrivateChat {
  background: #f9f9f9;
  border-radius: 8px;
  padding: 20px;
  max-width: 400px;
  margin: 0 auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  h2 {
    font-size: 24px;
    margin-bottom: 20px;
    color: #333;
    text-align: center;
  }

  input {
    display: block;
    width: calc(100% - 20px);
    margin: 10px auto;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;

    &:focus {
      border-color: #007bff;
      box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
    }
  }

  button {
    display: inline-block;
    width: calc(50% - 10px);
    margin: 10px 5px;
    padding: 10px;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition:
      background 0.3s,
      color 0.3s;

    &:first-of-type {
      background: #007bff;
      color: #fff;

      &:hover {
        background: #0056b3;
      }
    }

    &:last-of-type {
      background: #ccc;
      color: #333;

      &:hover {
        background: #999;
      }
    }
  }
}
