.Toastify__toast-container--top-right {
  top: 21em;
  right: 15em;
}

.Toastify {
  .Toastify__toast-container {
    .Toastify__toast {
      border-radius: 20px;
      padding: 10px 15px 12px 15px;
      margin: 5px;

      .Toastify__toast-body {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 6px;
        padding-bottom: 12px;

        & > div:last-child {
          max-width: 15ch;
          font-size: 15px;
          font-weight: 400;
          line-height: 18.6px;
          text-align: center;
          text-wrap: balance;
        }

        .Toastify__toast-icon {
          height: 25px;
          width: 25px;
          margin-inline-end: 0;
        }
      }

      .Toastify__close-button {
        position: absolute;
        top: 10px;
        right: 15px;

        svg {
          fill: #8a8484;
          width: 12px;
          height: 19px;
        }
      }

      .Toastify__progress-bar {
        bottom: 12px;
        left: 15px;
        height: 7px;
        width: calc(100% - 30px);
        border-radius: 20px;
      }
    }
  }
}
