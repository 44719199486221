.footer {
  width: 100%;
  max-width: 1550px;
  margin-inline: auto;
  height: 100%;
  background: var(--dark-blue-color);
  font-family: var(--font-family);
  font-size: 13px;
  font-weight: 300;
  color: hsla(0, 0%, 100%, 0.75);

  &-block {
    display: flex;
    justify-content: center;
    max-width: 1200px; /* Ограничение ширины контейнера */
    margin: 0 auto; /* Центрирование контейнера */
    padding: 2rem 1rem; /* Внутренние отступы */
    box-sizing: border-box; /* Учет паддингов в ширину элемента */

    &_box {
      width: 300px;
      border-right: 1px solid hsla(0, 0%, 100%, 0.33);
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 1rem;

      h5 {
        font-size: 16px;
        text-transform: uppercase;
      }

      p {
        padding-bottom: 0.5rem;
        line-height: 1.55;
        transition: color 150ms ease;
        cursor: pointer;

        &:hover {
          color: #fff;
        }
      }

      &__title {
        color: var(--pink-color);
        font-weight: 600;
        font-size: 20px;
        display: flex;
        margin-block: 1rem;
      }

      &:last-of-type {
        border: none;
      }
    }
  }

  .footer-last {
    border-top: 1px solid hsla(0, 0%, 100%, 0.33);
    font-size: 16px;
    font-weight: 300;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    padding: 2rem;
  }
}

@media (max-width: 580px) {
  .footer {
    .footer-block {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 1rem 0;
    }

    .footer-block_box {
      width: 100%;
      border-right: none;
      border-bottom: 1px solid hsla(0, 0%, 100%, 0.33);

      &:last-of-type {
        border: none;
      }
    }

    .footer-last {
      padding: 1rem 0.5rem;
      justify-content: flex-start;
      font-size: 14px;

      p span {
        display: block;
        margin-top: 6px;
      }
    }
  }
}
