.complaint {
  margin-left: 30px;
  td:last-child {
    word-wrap: break-word; /* или overflow-wrap: break-word; */
    word-break: break-all;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .complaint-data {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 1200px;
    margin: 2em auto;
    padding: 2em;
    background-color: #f5f5f5;
    border-radius: 10px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    transition: box-shadow 0.3s ease-in-out;
  }

  .complaint-data table {
    width: 100%;
    border-collapse: collapse;
  }

  .complaint-data th,
  .complaint-data td {
    padding: 1em;
    text-align: left;
    border-bottom: 2px solid #ddd;
    transition: background-color 0.3s ease;
  }

  .complaint-data th {
    background: #ff6b6b;
    color: white;
  }

  tr:first-child {
    cursor: default;
  }

  tr {
    cursor: pointer;
  }

  .complaint-data tr:nth-child(even) {
    background-color: #f2f2f2;
  }

  .complaint-data tr:hover {
    background-color: #ddd;
  }

  @media (max-width: 600px) {
    .complaint-data {
      padding: 1em;
    }

    .complaint-data th,
    .complaint-data td {
      padding: 0.5em;
    }
  }
}
