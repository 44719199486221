.sidebar-left {
  width: 64px;
  padding-block: 42px;
  border-right: 1px solid hsl(0, 0%, 91%);

  &__icons {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;

    .sidebar-left__icon {
      width: 64px;
      height: 3rem;
      display: grid;
      place-items: center;
      cursor: pointer;

      &.active {
        .primary-icon {
          path {
            fill: hsl(218, 100%, 73%);
            fill-opacity: 1;
          }
        }

        .friends-online {
          .online-icon {
            svg {
              path {
                stroke: hsl(218, 100%, 73%);
              }
            }
          }
        }

        .favorites {
          .favorites-icon {
            svg {
              path {
                fill: hsl(218, 100%, 73%);
                fill-opacity: 1;
              }
            }
          }
        }
      }

      .friends-online {
        position: relative;

        .online-icon {
          position: absolute;
          left: -7px;
          top: 1px;
          width: 12px;
          height: 12px;

          svg {
            width: 100%;
            height: 100%;

            path {
              stroke: hsla(234, 75%, 71%, 0.7);
            }
          }
        }
      }

      .favorites {
        position: relative;

        .favorites-icon {
          position: absolute;
          left: -7px;
          top: 2px;
          width: 14px;
          height: 14px;

          svg {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}

.sidebar__content {
  width: 345px;

  &_top {
    height: 95px;
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid var(--black-10, hsla(0, 0%, 11%, 0.1));

    @media (max-width: 420px) {
      height: auto;
      padding: 12px;
    }

    .sidebar_flex {
      display: flex;
      align-items: center;
      gap: 8px;

      .sidebar__title {
        font-family: var(--font-family);
        font-size: 20px;
        font-weight: 600;
        line-height: 30px;
        text-align: left;
      }

      .chats-count {
        width: 28px;
        height: 28px;
        padding: 2px 8px;
        border-radius: 50%;
        display: grid;
        place-items: center;
        color: var(--main-color);
        background-color: hsl(248, 100%, 98%);

        span {
          font-family: Inter;
          font-size: 13px;
          font-weight: 600;
          line-height: 18px;
          color: hsl(237, 13%, 28%);
        }
      }
    }
  }

  .sidebar__search {
    position: relative;
    height: 48px;
    margin: 1rem;
    padding: 14px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 12px;
    background: hsl(248, 100%, 98%);

    @media (max-width: 420px) {
      margin-inline: 12px;
    }

    input {
      position: relative;
      width: 235px;
      height: 21px;
      border: none !important;
      outline: none !important;
      appearance: none !important;
      -webkit-appearance: none !important;
      -moz-appearance: none !important;
      position: relative !important;
      font-family: Inter;
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
      text-align: left;
      color: hsl(248, 17%, 23%);
      background: transparent;
    }

    .sidebar__close-btn {
      background-color: transparent;
      padding-inline: 0;
      display: grid;
      place-content: center;
    }

    .error {
      position: absolute;
      left: 0;
      bottom: -13px;
      font-family: var(--font-family);
      font-size: 13px;
      color: var(--red-color);
    }
  }

  .messages-view {
    height: 100%;
    padding-inline: 10px;

    .messages-items {
      div {
        &::-webkit-scrollbar {
          width: 10px;
          background-color: hsla(218, 100%, 75%, 0.2);
        }

        &::-webkit-scrollbar-thumb {
          border: 1px solid transparent;
          border-radius: 10px;
          background-clip: padding-box;
          background-color: var(--primary-blue-hover);
        }

        &::-webkit-scrollbar-thumb:hover {
          background-color: hsl(218, 100%, 77%);
        }
      }
    }

    @media (max-width: 420px) {
      padding-inline: 6px;
    }
  }
}

@media (max-width: 768px) {
  .sidebar__content {
    width: calc(100vw - 48px);
  }
}

@media (max-width: 420px) {
  .sidebar-left {
    width: 48px;
    padding-block: 8px;

    .sidebar-left__icon {
      width: 48px;
    }
  }
}
