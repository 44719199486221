.call-modal {
  width: min(537px, 100vw - 1rem);
  margin: 0.5rem;
  padding: 1rem;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;

  .caller-info {
    padding-block: 1rem;
    text-align: center;
    font-family: var(--font-family);
    font-size: 20px;
    font-weight: 600;
    line-height: 25px;
    color: hsl(235, 13%, 28%);
    width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    span {
      font-size: 20px;
      font-weight: 400;
    }
  }

  .caller-picture {
    position: relative;
    height: 100%;
    width: 100%;
    margin: auto;
    animation: grow-and-shrink 5000ms ease infinite;

    .photo-background {
      height: 100%;
      width: 100%;
      display: grid;
      place-content: center;

      svg {
        height: 100%;
        width: 100%;
      }
    }

    .caller-photo {
      position: absolute;
      inset: 0;
      width: 100%;
      height: 100%;
      display: grid;
      place-content: center;

      img {
        width: 245px;
        height: 245px;
        border-radius: 100px;
        object-fit: cover;

        @media (max-width: 500px) {
          width: 205px;
          height: 205px;
        }

        @media (max-width: 420px) {
          width: 175px;
          height: 175px;
        }

        @media (max-width: 350px) {
          width: 155px;
          height: 155px;
        }
      }
    }
  }

  .call-buttons {
    width: 100%;
    padding-block: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;

    @media (max-width: 420px) {
      flex-direction: column-reverse;
      gap: 0.5rem;
    }

    button {
      height: 43px;
      width: 45%;
      padding: 11px 22px;
      border-radius: 40px;
      font-family: var(--font-family);
      font-size: 15px;
      font-weight: 400;
      line-height: 18.6px;
      transition: background-color 150ms ease;

      @media (max-width: 420px) {
        width: 80%;
      }

      &.decline-call {
        background-color: #fff;
        border: 1px solid var(--primary-blue-color);
        color: var(--primary-blue-color);

        &:hover {
          border-color: var(--secondary-blue-color);
          color: var(--secondary-blue-color);
        }
      }

      &.accept-call {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 1rem;
        color: #fff;
        background-color: var(--primary-blue-color);

        &:hover {
          background-color: var(--secondary-blue-color);
        }
      }
    }
  }
}

@keyframes grow-and-shrink {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.075);
  }

  100% {
    transform: scale(1);
  }
}
