// Colors
$bg-color: #e8bc8d;
$gift-box-color: #34495e;
.giftAnimations {
  .container__content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  /* Gift container styles */
  .giftAnimationsC-container {
    display: flex;
    justify-content: center;
  }

  /* Gift */
  .giftAnimationsC {
    position: absolute;
    z-index: 2;

    &__cover {
      display: flex;
      justify-content: center;
      background-color: $gift-box-color;
      height: 4rem;
      width: 19rem;
      &__wrap {
        position: absolute;
        height: 4rem;
        width: 4rem;
        background-color: var(--pink-color);
        // Ribbon
        &::before {
          content: '';
          display: block;
          position: absolute;
          top: -4rem;
          box-shadow: inset 0 0 0 1rem var(--pink-color);
          border-radius: 2rem;
          height: 4rem;
          width: 4rem;
          transform: translateX(-45%) skewY(40deg);
        }
        &::after {
          content: '';
          display: block;
          position: absolute;
          top: -4rem;
          box-shadow: inset 0 0 0 1rem var(--pink-color);
          border-radius: 2rem;
          height: 4rem;
          width: 4rem;
          transform: translateX(45%) skewY(-40deg);
        }
      }

      /* Cover Animation Classes */
      &.animate__wobble {
        animation: wobble 0.5s linear infinite 0.1s forwards;
      }
      &.animate__open {
        animation: flyUp 0.4s ease-in forwards;
      }
    }

    &__container {
      display: flex;
      justify-content: center;
      position: relative;
      margin: 0 1rem;
      z-index: -1;
      top: -0.75rem;
      height: 10rem;
      width: 17rem;
      background-color: $gift-box-color;

      &__label {
        display: block;
        position: absolute;
        top: 4rem;
        font-size: 1.5rem;
        transform: rotate(-20deg);
        color: white;
      }

      &__wrap {
        position: absolute;
        height: 10rem;
        width: 4rem;
        background-color: var(--pink-color);
      }

      /* Shadow under the gift box */
      &::after {
        content: '';
        display: block;
        width: 100%;
        height: 2rem;
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 1;
      }

      /* Container Animation Classes */
      &.animate__open {
        animation: flyDown 0.2s ease-in forwards;
      }
    }

    /* Wobble for the gift itself */
    &.animate__wobble {
      animation: wobble 0.5s linear infinite forwards;
    }
  }

  /* Card */
  .card {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 1.25rem;
    padding: 10px;
    margin: 3px;
    background-color: white;
    border: solid 2px $gift-box-color;
    border-radius: 1.25rem;
    transform: scale(10%);
    z-index: 1; // behind the box cover but above container
    h2 {
      font-size: 28px;
      margin-bottom: 30px;
    }
    img {
      border-radius: 1.25rem;
      height: 10rem;
      width: 10rem;
    }

    &__text {
      color: $gift-box-color;
      margin-left: 1rem;

      &__greeting {
        font-size: 1.5rem;
        &__hbd {
          -webkit-text-stroke-width: 0.1px;
          -webkit-text-stroke-color: black;
        }
      }

      &__sign {
        font-size: 1rem;
        text-align: right;
      }
    }

    /* Zoom-in card animation */
    &.animate {
      animation: zoomIn 0.8s ease-in forwards;
    }
  }
}
/* Keyframes */
@keyframes wobble {
  25% {
    transform: rotate(4deg);
  }
  75% {
    transform: rotate(-2deg);
  }
}

@keyframes flyUp {
  75% {
    opacity: 1;
  }
  100% {
    transform: translateY(-1000px) rotate(20deg);
    opacity: 0;
  }
}

@keyframes flyDown {
  75% {
    opacity: 1;
  }
  100% {
    transform: translateY(150%);
    opacity: 0;
  }
}

@keyframes zoomIn {
  100% {
    transform: scale(100%) rotateZ(360deg) rotateX(360deg);
  }
}

.giftAnimations {
  .modal {
    z-index: 99999;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
  }

  .popup {
    /* Попап сам по себе должен остаться кликабельным */
    pointer-events: auto;
    padding: 20px;
    border-radius: 10px;
    width: 100%;
  }
}
// Простейшая fade-анимация (0.5s)
.fade-in {
  animation: fadeIn 0.5s forwards; // forwards - сохраняем конечное состояние
}

.fade-out {
  animation: fadeOut 0.5s forwards;
}

// Ключевые кадры
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
