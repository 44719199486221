.error-input {
  height: 2px;
  margin-block: 1px;
  font-size: 13px;
}

.input-error {
  border: 1px solid var(--red-color);
  outline: none;
  box-shadow: 0 0 0 0.2px rgba(220, 53, 69, 0.25);
}

.error-input.visible {
  color: var(--red-color);
}

.wrapper-input {
  position: relative;
  background: white;
  display: flex;
  align-items: center;
  flex-direction: row;
  flex: 1;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;

  .eye {
    position: absolute;
    top: 50%;
    right: 25px;
    transform: translate(0, -50%);
    -webkit-transform: translate(0, -50%);
    -moz-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    -o-transform: translate(0, -50%);

    svg {
      width: 18px;
      height: auto;
    }
  }
}

input[type='password']::-webkit-contacts-auto-fill-button,
input[type='password']::-webkit-credentials-auto-fill-button {
  visibility: hidden;
  display: none !important;
  pointer-events: none;
  height: 0;
  width: 0;
  margin: 0;
}
