.header {
  height: 120px;
  &-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    &_left {
      width: 340px;
      display: flex;
      &__text {
        display: flex;
        align-items: center;
      }
    }
    &_right {
      width: 340px;
    }
  }
}
.logo {
  width: 80px;
  height: auto;
}

.headers {
  li {
    cursor: pointer;
  }
}
