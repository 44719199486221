.messages__main {
  height: calc(100vh - 60px);
  width: 100%;
  display: flex;

  .sidebar {
    display: flex;
    background-color: #fff;
    border-right: 1px solid hsl(0, 0%, 91%);
  }

  .right-block {
    overflow: hidden;
    width: 100%;
  }
}

.messages__button {
  height: 43px;
  padding: 10px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  border-radius: 20px;
  background: linear-gradient(270.16deg, hsla(218, 100%, 60%, 0.9) 0.13%, hsla(218, 90%, 65%, 0.9) 47.04%, hsla(273, 100%, 70%, 0.9) 99.93%);
  transition: all 150ms ease;

  span {
    font-family: 'Urbanist';
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
    line-height: 19.2px;
  }

  &:hover {
    background: linear-gradient(270.16deg, hsla(273, 100%, 70%, 0.9) 0.13%, hsla(218, 90%, 65%, 0.9) 47.04%, hsla(218, 100%, 60%, 0.9) 99.93%);
  }
}

@media screen and (max-width: 768px) {
  .messages__main {
    height: 100vh; /* Без вычитания 120px */

    .sidebar {
      width: 100%;
    }
  }
}
