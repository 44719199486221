:root {
  --screen-width: 100%;
  --main-header-height: 120px;
  --header-bg-color: #673ab7;
  --splash-bg-color: #368887;
}

.MainHeader {
  position: relative;
  z-index: 1000;
}

.viewport {
  width: var(--screen-width);
  min-height: var(--main-header-height);
  margin: 0 auto;
  position: relative;
}

.header {
  display: flex;
  justify-content: space-between;
  height: var(--main-header-height);
  padding-inline: 30px;
}

.header-menu_left {
  display: flex;
  justify-content: center;
  align-items: center;
}

#nav__desc {
  display: flex;
  height: 100%;
  justify-content: center;

  ul {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1.25rem;

    li {
      list-style: none;

      a {
        font-family: var(--font-family);
        font-size: 17px;
        font-weight: 400;
        line-height: 18.6px;
        text-decoration: none;
        color: var(--main-color);
      }

      &.active {
        a {
          color: var(--pink-color);
        }
      }
    }
  }

  @media (max-width: 1120px) {
    display: none;
  }
}

.header-menu_right {
  font-family: var(--font-family);

  ul {
    height: var(--main-header-height);
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;

    .box {
      display: flex;
      justify-content: center;
      align-items: center;

      .box-content_box {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 1rem;
        font-size: 12px;
        font-weight: 400;
        line-height: 14.88px;

        .img-box {
          height: 37px;
          width: 37px;

          svg {
            height: 100%;
            width: 100%;
          }

          img {
            border-radius: 50%;
            height: 100%;
            width: 100%;
          }
        }

        .box-content_right {
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          gap: 3px;

          .name {
            max-width: 100px;
            font-size: 15px;
            font-weight: 400;
            line-height: 18.6px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .header-menu-right-text {
            color: #030303;

            span {
              color: var(--pink-color);
            }
          }
        }
      }
    }

    @media (max-width: 1120px) {
      margin-right: 20px;
    }
  }
}

// ---------------------------
// Main Navigation Menu
// ---------------------------
.header-menu {
  .user {
    .header-menu_right {
      margin-right: 10px;
    }
    .buttons {
      display: flex;
    }
    @media (max-width: 1120px) {
      button {
        display: none;
      }
    }
  }

  #nav {
    display: none;

    @media (max-width: 1120px) {
      position: absolute;
      display: block;
      z-index: 20;
    }
  }

  .nav {
    width: 100%;

    // Toggle Button
    &__toggle {
      display: inline-block;
      position: absolute;
      z-index: 10;
      padding: 0;
      border: 0;
      background: transparent;
      outline: 0;
      right: 20px;
      top: 20px;
      cursor: pointer;
      border-radius: 50%;
      transition: background-color 0.15s linear;
      margin-top: 15px;

      &:hover,
      &:focus {
        background-color: rgba(0, 0, 0, 0.5);
      }
    }

    &__menu {
      display: flex;
      flex-direction: column;
      min-height: var(--main-header-height);
      position: relative;
      padding-top: 8rem;
      z-index: 5;
      visibility: hidden;

      .active {
        a {
          color: var(--pink-color);
        }
      }
    }

    &__item {
      opacity: 0;
      transition: all 0.3s cubic-bezier(0, 0.995, 0.99, 1) 0.3s;
    }

    @for $i from 1 through 5 {
      &__item:nth-child(#{$i}) {
        transform: translateY(-40px * $i);
      }
    }

    &__link {
      color: white;
      display: block;
      text-align: center;
      text-transform: uppercase;
      letter-spacing: 5px;
      font-size: 18px;
      text-decoration: none;
      padding: 15px;

      &:hover,
      &:focus {
        outline: 0;
        background-color: rgba(0, 0, 0, 0.05);
      }
    }
  }

  // calculate a circle's circumference based on radius
  @function circumference($r) {
    $pi: 3.141592653;
    @return 2 * $pi * $r;
  }

  // ---------------------------
  // SVG Menu Icon
  // ---------------------------
  .menuicon {
    display: block;
    cursor: pointer;
    color: #000000;

    transform: rotate(0deg);
    transition: 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);

    &__bar,
    &__circle {
      fill: none;
      stroke: currentColor;
      stroke-width: 3;
      stroke-linecap: round;
    }

    &__bar {
      transform: rotate(0deg);
      transform-origin: 50% 50%;
      transition: transform 0.25s ease-in-out;
    }

    &__circle {
      transition: stroke-dashoffset 0.3s linear 0.1s;
      stroke-dashoffset: circumference(23); // 23 is the <circle>'s radius
      stroke-dasharray: circumference(23);
    }
  }

  // ---------------------------
  // Circular Splash Background
  // ---------------------------

  .splash {
    position: fixed;
    top: 0;
    right: 0;
    width: 1px;
    height: 1px;
    opacity: 0; /* make transparent by default */
    pointer-events: none; /* no mouse events when not visible */
    transition: opacity 1s ease-in-out;

    &::after {
      content: '';
      display: block;
      position: absolute;
      border-radius: 50%;
      background-color: var(--splash-bg-color);
      width: 284vmax;
      height: 284vmax;
      top: -142vmax;
      left: -142vmax;
      transform: scale(0);
      transform-origin: 50% 50%;
      transition: transform 0.5s cubic-bezier(0.755, 0.05, 0.855, 0.06);
      will-change: transform;
    }
  }

  // ---------------------------
  // Active State
  // ---------------------------

  .nav:target,
  .nav--open {
    //scale the background circle to full size
    > .splash::after {
      transform: scale(1);
    }

    //animate the menu icon
    .menuicon {
      color: white;
      transform: rotate(180deg);

      &__circle {
        stroke-dashoffset: 0;
      }

      &__bar:nth-child(1),
      &__bar:nth-child(4) {
        opacity: 0;
      }

      &__bar:nth-child(2) {
        transform: rotate(45deg);
      }

      &__bar:nth-child(3) {
        transform: rotate(-45deg);
      }
    }

    //show the nav items
    .nav {
      &__menu {
        visibility: visible;
      }

      &__item {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }

  // ---------------------------
  // Demo Stuff, Ignore
  // ---------------------------

  .main {
    padding: 20px;
  }

  .gallery {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));
    grid-auto-rows: 130px;
    grid-gap: 20px;

    &__item {
      height: 100%;
      background-color: #d8d8d8;

          &:hover,
      &:focus {
        background-color: #a4a4a4;
      }
    }
  }

  .languageSelect {
    margin-left: 10px;
  }

  .boxMenuMobile {
    text-align: center;
    border-bottom: 1px solid black;
    img {
      width: 153px;
      height: 153px;
      border-radius: 50%;
    }
    .name {
      font-size: 20px;
      font-weight: 700;
    }

    p {
      font-size: 18px;
      margin-bottom: 3px;
    }

    .box-content_box {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

@media (max-width: 1350px) {
  .header {
    padding-inline: 1rem;

    #nav__desc ul {
      justify-content: flex-start;
      gap: 1rem;

      li a {
        font-size: 1rem;
      }
    }
  }
}

@media (max-width: 1200px) {
  #nav {
    height: 1px;
    ul {
      height: 100vh;
    }
  }
}

@media (max-width: 1120px) {
  .header {
    width: calc(100vw - 1rem);
    padding-inline-end: 3rem;
  }

  @media (max-width: 740px) {
    .header-menu_right {
      min-width: auto !important;
      display: flex;

      li:last-child {
        display: none;
      }
    }
  }
}
