.GirlPopUp {
  &-close {
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    color: black;
    position: absolute;
    top: 20px;
    left: 40px;
    cursor: pointer;
  }
}
