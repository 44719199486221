.friends__tabs {
  display: flex;
  align-items: center;
  gap: 1.5rem;

  &-item {
    color: hsla(237, 27%, 67%);
    cursor: pointer;

    p {
      font-family: var(--font-family);
      font-size: 14px;
      font-weight: 500;
      line-height: 21px;
    }

    &.active {
      color: var(--pink-color);
    }
  }
}
