.age-block {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 737px;
  min-height: 624px;
  border-radius: 20px;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  font-family: var(--font-family);

  .age-block__block {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 30px;
    padding: 42px 51px;

    .age-block__header {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 12px;

      .age-block__age {
        font-style: normal;
        font-weight: 400;
        font-size: 48px;
        line-height: 60px;
        color: var(--pink-color);
      }

      .age-block__language {
        align-self: flex-start;
        border: 1px solid var(--primary-blue-color);
        border-radius: 20px;
      }
    }

    .age-block__box {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 30px;
      font-size: 16px;

      p {
        color: #333;
        font-weight: 400;
        line-height: 24px;
        text-align: left;
        text-wrap: balance;
      }

      span {
        display: block;
        color: var(--pink-color);
        font-weight: 600;
        line-height: 22.36px;
      }
    }

    .age-block__buttons {
      width: 100%;
      display: flex;
      justify-content: space-between;

      button {
        min-height: 50px;
        border-radius: 40px;
        padding-block: 11px;
        line-height: 18.6px;
        font-size: 15px;
        font-weight: 600;
        font-family: var(--font-family);
        text-decoration: none;
        cursor: pointer;
        transition: transform 150ms ease;
      }

      button[type='button'] {
        width: 235px;
        border: 1px solid var(--secondary-blue-color);
        color: var(--primary-blue-color);
        text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
        -webkit-text-fill-color: var(--secondary-blue-color);
        background-color: #fff;
        transition: transform 150ms ease;

        &:hover {
          transform: translateY(-2px);
        }
      }

      button[type='submit'] {
        width: 370px;
        border: none;
        background: linear-gradient(145deg, var(--primary-blue-hover), var(--secondary-blue-color), var(--primary-blue-hover));
        color: #fff;
        box-shadow:
          0 3px 6px rgba(0, 0, 0, 0.15),
          0 1px 3px rgba(0, 0, 0, 0.1);
        transition:
          transform 150ms ease,
          box-shadow 150ms ease;

        &:hover {
          transform: translateY(-2px);
          box-shadow:
            0 5px 10px rgba(0, 0, 0, 0.2),
            0 2px 5px rgba(0, 0, 0, 0.15);
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .age-block {
    width: calc(100vw - 30px);
    margin: auto;
    height: auto;

    .age-block__block {
      width: 100%;
      gap: 15px;
      padding: 30px 15px;

      .age-block__header {
        .age-block__age {
          font-size: 42px;
        }
      }

      .age-block__box {
        font-size: 15px;
      }

      .age-block__buttons {
        flex-direction: column;
        gap: 10px;

        button[type='button'],
        button[type='submit'] {
          width: 100%;
          font-size: 14px;
          text-wrap: balance;
        }
      }
    }
  }
}
