.step2-information {
  min-height: 100vh;
  max-width: 100%;
  margin-inline: auto;
  padding: 20px;
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 20px;
  display: flex;
  flex-direction: column;
  text-align: center;
  color: var(--main-color);

  * {
    overflow-wrap: break-word;
  }

  span {
    display: block;
    text-align: left;
    font-size: min(14px, 0.5rem + 1vmax);
  }

  form {
    width: min(650px, 100%);
    margin: 0 auto 100px;
    padding: 40px 20px;
    border-radius: 10px;
    border: 1px solid #fff;
    background-color: hsla(0, 0%, 100%, 0.75);
    box-shadow:
      0 3px 6px rgba(0, 0, 0, 0.08),
      0 1px 3px rgba(0, 0, 0, 0.1);

    .name {
      span {
        color: #555;
      }

      .custom-input {
        margin-block: 3px 20px;

        .wrapper-input {
          font-size: min(16px, 0.6rem + 1vmax);

          input {
            width: 100%;
            padding: 20px 10px;
            outline: none !important;
            border: none;
            border-radius: 8px;
            box-shadow:
              0 3px 5px rgba(0, 0, 0, 0.08),
              0 1px 3px rgba(0, 0, 0, 0.1);
            font-family: var(--font-family);
            font-size: 15px;
            color: var(--pink-color);
            background-color: #fff;

            &:hover {
              box-shadow:
                0 5px 7px rgba(0, 0, 0, 0.1),
                0 3px 5px 1px rgba(0, 0, 0, 0.08);
            }

            &:focus {
              box-shadow: none;
              border: 2px solid var(--secondary-blue-color);
            }
          }
        }

        .error-input.visible p {
          text-align: center;
        }
      }
    }

    .step2-information-block {
      display: flex;
      flex-wrap: wrap;
      gap: 20px; /* This is to give some space between the boxes */

      .step2-information-block__item {
        width: 100%;

        label {
          display: block;
          text-align: left;
          font-size: min(14px, 0.5rem + 1vmax);
          color: #555;
        }

        .step2-information-block__item_box,
        .step2-photo {
          background-color: #fff;
          width: 100%;
          margin-top: 3px;
          box-sizing: border-box;
          display: flex;
          border-radius: 8px;
          box-shadow:
            0 3px 5px rgba(0, 0, 0, 0.08),
            0 1px 3px rgba(0, 0, 0, 0.05);

          &:hover {
            box-shadow:
              0 5px 7px rgba(0, 0, 0, 0.1),
              0 3px 5px 1px rgba(0, 0, 0, 0.08);
          }
        }

        .step2-photo {
          flex-direction: column;
        }

        span {
          color: #555;
        }

        .info-circle,
        .TextTooltip span {
          color: #fff;
        }
      }

      .step2-information-block__button {
        width: 100%;

        button {
          width: 75%;
          min-height: 50px;
          margin: 20px auto;
          padding: 0.6rem 1.2rem;
          border-radius: 40px;
          border: none;
          background: linear-gradient(145deg, var(--primary-blue-hover), hsla(218, 100%, 60%, 1), var(--primary-blue-hover));
          color: #fff;
          font-size: 1rem;
          font-family: var(--font-family);
          line-height: 18.6px;
          cursor: pointer;
          box-shadow:
            0 3px 6px rgba(0, 0, 0, 0.15),
            0 1px 3px rgba(0, 0, 0, 0.1);
          transition:
            transform 150ms ease-in-out,
            box-shadow 150ms ease-in-out;

          &:hover {
            transform: translateY(-2px);
            box-shadow:
              0 5px 10px rgba(0, 0, 0, 0.2),
              0 2px 5px rgba(0, 0, 0, 0.15);
          }

          @media (max-width: 580px) {
            width: 100%;
          }
        }
      }

      .step2-photo:has(.ErrorInput) {
        border: 1px solid var(--red-color);
      }

      .ErrorInput,
      .error {
        position: relative;
        top: 15px;
        width: 100%;
        padding: 0;
        display: flex;
        justify-content: center;
        color: var(--red-color);
        font-family: var(--font-family);
        font-size: 13px;
      }

      .profile-photo .errorPhoto {
        top: 17px;
      }

      .photo {
        box-shadow: none;
        font-family: var(--font-family);
        font-size: 1rem;
        line-height: 18.6px;

        h1 {
          font-size: calc(0.8rem + 1vmin);
          margin-block: 0.5rem 2rem;
          color: #333;
        }

        p {
          width: 100%;
        }
      }
    }

    .photos:has(.errorPhoto) .step2-photo {
      border: 1px solid var(--red-color);
    }

    .webcam-container {
      h2 {
        font-size: calc(0.8rem + 1vmin);
      }
    }
  }
}

@media (max-width: 415px) {
  .step2-information {
    padding: 20px 10px;

    form {
      padding: 20px 10px;

      .step2-information-block {
        .photo,
        .videoModel,
        .webcam-container {
          padding: 20px 10px;
          font-size: 14px;

          & > div {
            padding-inline: 10px;
          }
        }

        .webcam-container {
          .overlay {
            &::before {
              top: -30px;
            }

            &::after {
              bottom: -30px;
            }
          }
        }
      }
    }
  }
}
