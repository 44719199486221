.policies {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  width: 100%;
  background: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  padding-bottom: 15px;
  p {
    margin-left: 20px;
    margin-right: 20px;
    padding: 10px;
    cursor: pointer;
    font-size: 22px;
    margin-top: 20px;
    display: inline;
    background: white;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    transition: 1s;
    &:hover {
      background: var(--pink-color);
      color: white;
    }
  }
}

.info {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
  width: 100%;
  border-radius: 5px;
  padding-bottom: 15px;
  &-block {
    max-width: 1000px;
    display: flex;
    flex-direction: column;
    margin-top: 50px;
    margin-bottom: 50px;
    padding: 50px 50px;
    width: 100%;
    background: white;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    padding-bottom: 15px;
  }
  h2 {
    font-size: 24px;
    margin: 15px;
    text-align: center;
  }
  h3 {
    margin-left: 20px;
  }
  p {
    margin: 10px 10px 10px 20px;
    font-size: 20px;
  }
  ul {
    margin-left: 40px;
    list-style: circle;
  }
  li {
  }
}
.Services {
  button {
    border-radius: 0 !important;
  }
}
.Services {
  justify-content: center;
  font-weight: 700;
  position: relative;
  display: flex;
  margin: 5rem 0;
  align-items: center;
  text-align: center;
  background: 0 0;
  background-attachment: fixed;
  background-size: cover;
  background-position: 50% 50%;

  @media (max-width: 415px) {
    margin-block: 3rem;
  }
}

.Services-block {
  border-radius: 20px;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
  // @import url('https://fonts.googleapis.com/css?family=Hind:300,400&display=swap');
  background-color: var(--light-background);
  box-shadow:
    0 3px 6px rgba(0, 0, 0, 0.08),
    0 1px 3px rgba(0, 0, 0, 0.1);
}
.Services-block h2 {
  margin: 30px 0 30px 0;
  font-size: 28px;
}
.Services-block .accordion {
  border-radius: 100% 100% 0%;
}
.Services-block .accordion .accordion-item {
  border-bottom: 1px solid #e5e5e5;
}
.Services-block .accordion .accordion-item button[aria-expanded='true'] {
  border-bottom: 1px solid var(--pink-color);
}
.Services-block .accordion button {
  position: relative;
  display: block;
  text-align: left;
  width: 100%;
  padding: 1em 0;
  color: black;
  font-size: 20px;
  font-weight: 400;
  border: none;
  background: none;
  outline: none;
}
.Services-block .accordion button:hover,
.Services-block .accordion button:focus {
  cursor: pointer;
  color: var(--pink-color);
}
.Services-block .accordion button:hover::after,
.Services-block .accordion button:focus::after {
  cursor: pointer;
  color: var(--pink-color);
  border: 1px solid var(--pink-color);
}
.Services-block .accordion button .accordion-title {
  padding: 1em 1.5em 1em 0;
}
.Services-block .accordion button .icon {
  display: inline-block;
  position: absolute;
  top: 18px;
  right: 0;
  width: 22px;
  height: 22px;
  border: 1px solid;
  border-radius: 22px;
}
.Services-block .accordion button .icon::before {
  display: block;
  position: absolute;
  content: '';
  top: 9px;
  left: 5px;
  width: 10px;
  height: 2px;
  background: currentColor;
}
.Services-block .accordion button .icon::after {
  display: block;
  position: absolute;
  content: '';
  top: 5px;
  left: 9px;
  width: 2px;
  height: 10px;
  background: currentColor;
}
.Services-block .accordion button[aria-expanded='true'] {
  color: var(--pink-color);
}
.Services-block .accordion button[aria-expanded='true'] .icon::after {
  width: 0;
}
.Services-block .accordion button[aria-expanded='true'] + .accordion-content {
  margin: 10px 0;
  opacity: 1;
  max-height: 11em;
  transition: all 200ms linear;
  will-change: opacity, max-height;
}
.Services-block .accordion .accordion-content {
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  border: 1px solid #ddd;
  border-radius: 5px;
  transition: all 200ms linear;
  will-change: opacity, max-height;
  -webkit-transition: all 200ms linear;
  -moz-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  -o-transition: all 200ms linear;
}

.Services-block .accordion .accordion-content p {
  font-size: 18px;
  font-weight: 300;
  color: #333;
  margin: 30px;
}

.contact {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  .contact-block {
    // Стили для блоков контактов
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    button {
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &_left,
    &_right {
      min-width: 200px;
      display: flex;
      flex-direction: column;
      margin: 30px;
      // Стили для левой и правой части
      flex: 1; // Разрешает элементам занимать одинаковое пространство
      justify-content: center; // Центрирует содержимое по горизонтали
      align-items: center; // Центрирует содержимое по вертикали
      background: white;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
      border-radius: 5px;
      h2 {
        font-size: 22px;
      }
      p {
        font-size: 18px;
      }
    }

    &_right {
      // Возможно, вы захотите добавить уникальные стили для правой части
    }
  }

  .contact-block_center {
    // Стили для центральной части
    margin-top: 20px; // Отступ сверху
    display: flex;
    flex-direction: column;
    p {
      // Стили для текста внутри центральной части
    }
    button {
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    svg {
      margin-left: 10px;
    }
  }
}
