.login {
  font-family: var(--font-family);
  min-height: 100vh;

  a {
    color: hsl(0, 0%, 28%);
    font-size: 14px;
    text-decoration: underline;
    text-underline-offset: 3px;
    transition: all 150ms ease;

    &:hover {
      color: var(--pink-color);
      text-underline-offset: 5px;
    }
  }

  .login__header {
    height: 90px;
    width: 50%;
    display: flex;
    justify-content: space-around;
    align-items: center;

    .login__logo {
      display: block;
      width: 78px;
      max-height: 68px;

      img {
        -o-object-fit: cover;
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }
  }

  .login__main {
    min-height: 100vh;
    position: relative;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-content: center;
    align-items: center;

    .login__main-left {
      align-self: self-start;
      width: min(500px, 100vw - 2rem);
      margin: 6.7rem auto;
      padding: 1rem;
      border-radius: 8px;
      background-color: hsla(218, 100%, 60%, 0.65);
      box-shadow:
        0 4px 10px rgba(0, 0, 0, 0.15),
        0 2px 5px rgba(0, 0, 0, 0.1);
      text-align: center;
      word-break: break-word;

      .login__main-left_box {
        width: 100%;
        padding: 2rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1rem;
        border-radius: 8px;
        border: 1px solid #fff;
        background-color: hsla(0, 0%, 100%, 0.9);
        box-shadow:
          0 4px 10px rgba(0, 0, 0, 0.15),
          0 2px 5px rgba(0, 0, 0, 0.1);

        .login__title {
          font-size: 24px;
          margin-bottom: 42px;

          &::first-letter {
            color: #dd1982;
            color: var(--pink-color);
          }
        }

        .login__form {
          width: 100%;
          text-align: left;

          label > span,
          .user-age > span {
            color: #555;
            font-size: min(14px, 0.5rem + 1vmax);
          }

          .custom-input {
            .wrapper-input {
              position: relative;
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              justify-content: center;
              gap: 0.125rem;
              border: none;
              margin-block: 3px 1rem;
              font-size: min(16px, 0.6rem + 1vmax);
              background-color: #fff !important;

              input {
                position: relative !important;
                display: block;
                width: 100%;
                min-height: 50px;
                outline: none !important;
                border: 2px solid hsla(218, 100%, 60%, 0.5);
                border-radius: 8px;
                appearance: none !important;
                -webkit-appearance: none !important;
                -moz-appearance: none !important;
                padding: 20px 10px;
                font-family: var(--font-family);
                font-size: 14px !important;
                cursor: text !important;
                transition:
                  background-color 0.3s,
                  border-color 0.3s,
                  color 0.3s;
                background-color: #fff !important;

                &:focus {
                  border: 2px solid var(--secondary-blue-color);
                }
              }

              input:-webkit-autofill,
              input:-webkit-autofill:hover,
              input:-webkit-autofill:focus,
              input:-webkit-autofill:active {
                -webkit-box-shadow: 0 0 0px 1000px #fff inset !important;
              }
            }

            .success,
            .error {
              position: absolute;
              bottom: -22px;
              color: var(--pink-color);
              font-size: 12px;
              font-weight: 400;
            }

            .error-input.visible {
              position: relative;
              top: -13px;
              text-align: left;

              p {
                word-wrap: break-word;
              }
            }
          }

          .user-age {
            width: 100%;
            margin-bottom: 1rem;
            text-align: left;
          }

          .rules {
            &-block {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              gap: 1rem;
              margin-bottom: 8px;

              &_text {
                line-height: 1.2;
                text-align: left;
              }
            }
          }

          .login__form-button {
            width: 100%;
            min-height: 50px;
            margin: 1rem auto;
            padding: 0.6rem 1.2rem;
            border-radius: 40px;
            border: none;
            background: linear-gradient(145deg, var(--primary-blue-hover), var(--secondary-blue-color), var(--primary-blue-hover));
            color: #fff;
            font-size: 1rem;
            font-family: var(--font-family);
            line-height: 18.6px;
            cursor: pointer;
            box-shadow:
              0 3px 6px rgba(0, 0, 0, 0.15),
              0 1px 3px rgba(0, 0, 0, 0.1);
            transition:
              transform 150ms ease-in-out,
              box-shadow 150ms ease-in-out;

            &:hover {
              transform: translateY(-2px);
              box-shadow:
                0 5px 10px rgba(0, 0, 0, 0.2),
                0 2px 5px rgba(0, 0, 0, 0.15);
            }
          }

          .login__footer {
            margin-top: 1rem;
            display: flex;
            justify-content: space-between;
          }
        }
      }
    }

    .login__main-img {
      position: absolute;
      right: 0;
      top: -90px;
      bottom: 0;
      width: 50%;
      height: calc(100% + 90px);
    }

    .login__decor {
      position: fixed;
      left: 0;
      bottom: 0;
      width: 100%;
      z-index: -1;
      height: 500px;

      svg {
        width: 100%;
        height: 500px;
      }
    }
  }
}

@media (max-width: 1025px) {
  .login {
    .login__header {
      width: 100%;
    }

    .login__main {
      grid-template-columns: 1fr;

      .login__main-left {
        align-self: center;

        .login__decor {
          display: none;
        }
      }

      .login__main-img {
        display: none;
      }
    }
  }

  @media (max-width: 500px) {
    .login {
      .login__main {
        .login__main-left {
          .login__main-left_box {
            padding-inline: 1rem;
            .login__form {
              .age {
                width: 100%;
              }

              .rules {
                &-block {
                  flex-direction: column;
                }
              }
            }
          }
        }
      }
    }
  }
}
