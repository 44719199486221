//$box-color: rgb(118,167,96);
//$cover-color: rgb(100,158,197);
//$ribbon-color: rgb(239,203,71);

$box-color: hsl(218, 100%, 94%);
$cover-color: hsl(218, 100%, 92%);
$ribbon-color: hsl(218, 100%, 94%);
$border-radius: 3px;

@mixin transform($scale, $rotate, $translateX, $translateY, $skew) {
  transform: scale($scale) rotate($rotate) translate($translateX, $translateY) skew($skew);
}

@mixin border-radius($radius) {
  border-radius: $radius;
}

.gift-box {
  position: relative;

  .giftSvg {
    z-index: 9;
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
    &-block {
    }

    .RenderGiftIconAndName {
      position: relative;
    }
  }
  .gift--body {
    position: relative;

    .gift--bottom {
      width: 245px;
      height: 161px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: $box-color;
      margin: 0 auto;
      position: relative;
      @include border-radius(0 0 $border-radius $border-radius);
      .time {
        position: absolute;
        bottom: 0;
        right: 0;
        font-size: 14px;
        margin-bottom: 15px;
        margin-right: 10px;
      }
    }

    .gift--cover {
      width: 278px;
      height: 50px;
      background: $cover-color;
      margin: 0 auto;
      position: relative;
      z-index: 9;
      p {
        text-align: center;
        word-break: normal;
      }
      @include border-radius($border-radius);
    }

    .gift--strip {
      $strip-width: 40px;
      width: $strip-width;
      height: 190px;
      background: $ribbon-color;
      position: absolute;
      top: 0;
      left: 50%;
      margin-left: $strip-width / 2 * -1;
    }
  }

  .gift--ribbon-wrapper {
    width: 140px;
    height: 50px;
    margin: 0 auto;
    position: relative;

    .gift--ribbon {
      &.gift--ribbon__left {
        float: left;
      }

      &.gift--ribbon__right {
        float: right;
        transform: rotateY(180deg);
      }

      .gift--ribbon-part {
        border-radius: $border-radius;
        $ribbon-part-size: 50px;

        &.part__big {
          width: $ribbon-part-size * 1.3;
          height: $ribbon-part-size;
          background: $ribbon-color;
          @include transform(1, 45deg, 18px, 23px, 8deg);
        }

        &.part__small {
          margin: -30px 8px 0;
          width: $ribbon-part-size * 0.9;
          height: $ribbon-part-size * 0.9;
          background: darken($ribbon-color, 15%);
          @include transform(1, 29deg, 1px, 16px, -25deg);
        }
      }
    }

    .gift--ribbon-node__wrapper {
      $ribbon-node-width: 50px;
      width: $ribbon-node-width;
      height: 20px;
      position: absolute;
      bottom: 0;
      left: 50%;
      margin-left: $ribbon-node-width / 2 * -1;
      z-index: 2;
      overflow: hidden;

      > div {
        border-radius: 50%;
        margin-left: auto;
        margin-right: auto;
        position: absolute;
        width: 100%;
      }

      .gift--ribbon-node__big {
        height: 50px;
        top: 3px;
        background: $ribbon-color;
      }

      .gift--ribbon-node__small {
        width: $ribbon-node-width * 0.8;
        height: 30px;
        top: 10px;
        left: 50%;
        margin-left: $ribbon-node-width * 0.8 / 2 * -1;
        background: darken($ribbon-color, 15%);
      }
    }
  }
}
