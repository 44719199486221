.PopupLanguage {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 100px;
  padding-bottom: 100px;
}

.languagePopUp {
  width: 80%;
  background-color: #f5f5f5;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.languagePopUp h3 {
  font-size: 24px;
  color: #333;
  text-align: left;
}

.languagePopUp-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.languagePopUp-block {
  flex: 1 1 calc(50% - 20px); /* Две колонки */
  margin-bottom: 20px;
  padding: 15px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-sizing: border-box; /* Добавлено */
  svg {
    border: 0.2px solid black;
    position: relative;
  }
}

.languagePopUp-block__box {
  width: 100%;
}

.languagePopUp-block__box___title {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #666;
}

.languagePopUp-block__content-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.languagePopUp-block__box___content {
  flex: 1 1 calc(50% - 10px); /* Две колонки */
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.languagePopUp-block__box___content svg {
  margin-right: 10px;
}

.languagePopUp-block__box___content span {
  margin-left: 10px;
  font-size: 16px;
  color: #333;
  text-transform: capitalize; /* Первая буква заглавная */
}

.languagePopUp-block__box___content___icon {
  width: 30px;
  svg {
    width: 30px;
  }
}

.languagePopUp-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  font-size: 20px;
}

.languagePopUp-header .languagePopUp-block__box___content {
  margin-bottom: 0;
}

@media (min-width: 768px) {
  .languagePopUp {
    padding: 20px;
  }

  .languagePopUp-block {
    padding: 20px;
  }

  .languagePopUp-block__box___title {
    font-size: 22px;
  }

  .languagePopUp-block__box___content span {
    font-size: 18px;
  }
}

@media (max-width: 767px) {
  .languagePopUp-header_close {
    position: absolute;
    right: 20px;
    top: 20px;
  }
  .languagePopUp {
    position: relative;
    padding: 10px;
  }

  .languagePopUp h3 {
    padding: 4px;
  }

  .languagePopUp-container {
    flex-direction: column;
  }

  .languagePopUp-block {
    flex: 1 1 100%;
    padding: 10px;
  }

  .languagePopUp-block__box___title {
    font-size: 18px;
  }

  .languagePopUp-block__box___content span {
    font-size: 17px;
  }

  .languagePopUp-header {
    flex-direction: column;
    align-items: flex-start;
    font-size: 18px;
  }

  .languagePopUp-header .languagePopUp-block__box___content {
    margin-bottom: 10px;
    align-items: flex-start;
  }
  .languagePopUp-header-selected {
    .languagePopUp-block__box___content {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.languagePopUp-block__box {
  cursor: pointer;
  .active {
    color: #dd1982;
    font-weight: 800;
  }
}
.languagePopUp-header-selected {
  padding: 10px;
  span {
    color: #dd1982;
    font-weight: 800;
  }
  svg {
    border: 0.2px solid black;
  }
}
@media (max-width: 400px) {
  .languagePopUp {
    width: 100%;
  }
  .languagePopUp-block__content-container {
    flex-direction: column;
  }
}
