.model-profile {
  width: min(800px, 100% - 40px);
  margin: 5rem auto;
  padding: 20px;
  border-radius: 20px;
  background-color: var(--light-background);
  box-shadow:
    0 3px 6px rgba(0, 0, 0, 0.08),
    0 1px 3px rgba(0, 0, 0, 0.1);

  h2 {
    margin-top: 2rem;
    font-size: calc(0.95rem + 0.8vw);
    color: #333;
    font-weight: 500;
    text-align: center;
  }

  .profile {
    .profile__main {
      padding-block: 3rem;

      .profile__main-inner {
        margin-inline: auto;

        .content__box {
          padding: 10px;
          gap: 10px;
          border: none;
          background-color: hsla(218, 100%, 60%, 0.65);
          box-shadow:
            0 4px 10px rgba(0, 0, 0, 0.15),
            0 2px 5px rgba(0, 0, 0, 0.1);
        }
      }
    }
  }
}

@media (max-width: 700px) {
  .model-profile {
    width: calc(100% - 20px);
    margin-block: 0;
    padding-inline: 0;
    box-shadow: none;
    background-color: transparent;

    .profile__main {
      .profile__main-inner {
        width: 100%;
        margin-inline: auto;
      }
    }
  }
}
