.top-up {
  position: relative;
  width: min(288px * 4 + 1rem * 3 + 80px, 100vw - 2rem);
  margin: 0.5rem auto;
  padding: 40px;
  border-radius: 20px;
  background-color: #fff;

  h1 {
    margin-bottom: 3rem;
    color: var(--main-color);
    font-family: Inter;
    font-size: 32px;
    font-weight: 400;
    line-height: 36px;
    text-align: center;
    text-wrap: pretty;

    @media (max-width: 400px) {
      font-size: 28px;
      margin-bottom: 2rem;
    }
  }

  &__cards {
    margin-inline: auto;
    display: grid;
    justify-content: center;
    grid-template-columns: repeat(auto-fit, 288px);
    gap: 1rem;

    @media (max-width: 720px) {
      gap: 0.5rem;
    }

    .top-up__card {
      width: 288px;
      height: auto;
      padding: 2rem;
      border-radius: 24px;
      border-bottom: 1px solid hsla(0, 0%, 0%, 0.08);
      background: hsla(245, 100%, 98%, 1);
      background-image: linear-gradient(
        180.16deg,
        hsla(273, 83%, 73%, 0.05) 0.13%,
        hsla(273, 83%, 73%, 0.3) 0.33%,
        hsla(273, 87%, 63%, 0.2) 57.04%,
        hsla(273, 93%, 73%, 0.05) 99.93%
      );

      @media (max-width: 335px) {
        width: 272px;
        justify-self: center;
      }

      .top-up__card-image {
        border-radius: 100px;
        background: radial-gradient(circle, hsla(293, 100%, 73%, 0.25) 60%, hsla(273, 100%, 73%, 0.5) 100%);
      }

      .top-up__card-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1rem;

        .top-up__coins {
          display: flex;
          align-items: center;
          gap: 0.5rem;

          span {
            font-family: var(--font-family);
            font-size: 40px;
            font-weight: 600;
            line-height: 60px;
            color: var(--main-color);
          }
        }

        .top-up__bonus {
          font-family: Inter;
          font-size: 16px;
          font-weight: 600;
          line-height: 19.36px;
          color: var(--pink-color);
        }

        .top-up__price {
          width: 117px;
          height: 37px;
          padding: 8px 24px;
          border-radius: 1rem;
          font-family: var(--font-family);
          font-size: 16px;
          font-weight: 400;
          line-height: 19.84px;
          color: #fff;
          background: hsl(248, 87%, 63%);
        }
      }
    }
  }

  &-close {
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
    cursor: pointer;

    &:hover {
      path {
        fill: var(--main-color);
      }
    }
  }
}
