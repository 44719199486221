.header {
  .button-block_line {
    display: inline-block; /* Чтобы можно было использовать в текстовом блоке */
    width: 2px; /* Толщина линии */
    height: 100%; /* Высота линии */
    background-color: black; /* Цвет линии */
    margin: 0 10px; /* Отступы слева и справа */
  }
  //box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  // border-bottom: 1px solid #000;
  height: 120px;
  &-block {
    display: flex;
    justify-content: space-between;
    height: 100%;
    &_logo {
      width: 270px;
      font-size: 33px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      p {
        color: var(--red-color);
      }
    }
    &_box {
      width: 300px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 22px;
      height: 100%;
    }
  }
  .button-block {
    display: flex;
    justify-content: center;
    align-items: center;
    button {
      padding: 10px;
      border: none;
      background-color: lightgrey;
      cursor: pointer;
      margin-right: 5px;
      border-radius: 4px;
    }

    button.active {
      background-color: blue;
      color: white;
    }
  }
}
