.message-user__chat-container {
  display: grid;
  place-items: center;

  .message-user__chat {
    width: 100%;
    height: auto;
    padding: 24px;
    padding-bottom: 84px;
    overflow-y: scroll;
    overscroll-behavior: none;
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-start;
    gap: 1rem;

    &::-webkit-scrollbar {
      width: 10px;
      background-color: hsla(218, 100%, 75%, 0.2);
    }

    &::-webkit-scrollbar-thumb {
      border: 1px solid transparent;
      border-radius: 10px;
      background-clip: padding-box;
      background-color: var(--primary-blue-hover);
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: hsl(218, 100%, 77%);
    }

    @media (max-width: 768px) {
      padding-bottom: 24px;
    }

    @media (max-width: 420px) {
      padding: 24px 12px;
    }

    .chat__item {
      display: flex;
      gap: 20px;

      @media (max-width: 420px) {
        gap: 10px;
      }

      .item__profile-photo {
        align-self: flex-end;
        width: 48px;
        height: 48px;
        display: grid;
        place-items: center;

        @media (max-width: 380px) {
          display: none;
        }

        .img-box {
          width: 48px;
          height: 48px;
          display: grid;
          place-items: center;

          img {
            border-radius: 50%;
          }

          svg {
            width: 100%;
            height: 100%;
          }
        }
      }

      .chat__item-content {
        position: relative;
        width: 100%;
        padding: 1.5rem 1rem;
        display: flex;
        gap: 12px;
        font-family: Inter;

        &:has(.gift-box) {
          justify-content: center;
          align-items: center;
        }

        .text-message {
          width: 100%;
          font-size: 15px;
          font-weight: 500;
          line-height: 19.5px;
        }

        .text-message_translate {
          padding-top: 12px;
          border-top: 1px solid;
          display: flex;
          gap: 4px;
          font-size: 13px;

          & > div {
            width: 20px;
            height: auto;
          }
        }

        .info-message {
          position: absolute;
          right: 12px;
          bottom: 4px;
          display: flex;
          align-items: center;

          .time {
            font-family: Inter;
            font-size: 12px;
            font-weight: 400;
            line-height: 11.75px;
          }

          .read-status {
            margin-left: 10px;
          }
        }
      }
    }

    .chat__item.me,
    .chat__item.you {
      .chat__item-content {
        width: min(547px, 100%);
        flex-direction: column;
        justify-content: center;
      }
    }

    .chat__item.me {
      justify-content: end;

      .chat__item-content {
        border-radius: 1rem 1rem 0px 1rem;
        background: linear-gradient(270.16deg, hsla(273, 100%, 70%, 0.9) 0.13%, hsla(218, 90%, 65%, 0.9) 47.04%, hsla(218, 100%, 60%, 0.9) 99.93%);
        color: #fff;

        .text-message_translate {
          border-color: hsla(0, 0%, 100%, 0.75);
        }

        .time {
          color: #fff;
        }
      }
    }

    .chat__item.you {
      .chat__item-content {
        box-shadow:
          0 3px 6px rgba(0, 0, 0, 0.08),
          0 1px 3px rgba(0, 0, 0, 0.1);
        border-radius: 1rem 1rem 1rem 0px;
        background-color: #fff;
        color: hsl(237, 13%, 28%);

        .text-message_translate {
          border-color: hsla(237, 100%, 92%, 0.75);
        }

        .time {
          color: hsl(218, 15%, 52%);
        }
      }
    }

    .chat__item.approved {
      min-height: 65px;
      border-top: 2px solid hsla(218, 100%, 65%, 0.5);
      border-right: 2px solid hsla(218, 100%, 65%, 0.5);
      border-bottom: 2px solid hsla(273, 100%, 65%, 0.5);
      border-left: 2px solid hsla(273, 100%, 65%, 0.5);
      border-radius: 8px;

      .chat__item-content {
        flex-direction: row;
        align-items: center;
        position: relative;
        height: 100%;
        width: 100%;
        border-radius: 6px;
        background: hsl(248, 100%, 98%);

        .text-message {
          width: 100%;
          font-family: Inter;
          font-size: 16px;
          font-weight: 400;
          line-height: 20.4px;
          letter-spacing: -0.4px;
          text-align: left;
          color: hsl(237, 13%, 28%);
        }

        .time {
          color: hsl(216, 15%, 52%);
        }
      }
    }

    .chat-form__container {
      width: 100%;
      display: flex;
      justify-content: center;

      .chat-form {
        position: absolute;
        z-index: 10;
        width: min(760px, 100% - 2rem);
        height: 60px;
        border-radius: 1rem;
        display: flex;
        justify-content: center;
        align-items: center;
        bottom: 80px;

        @media (max-width: 768px) {
          bottom: 20px;
        }

        .chat-form__inner {
          position: relative;
          width: min(760px, 100%);
          padding: 12px 24px;
          border-radius: 1rem;
          display: flex;
          justify-content: space-between;
          align-items: center;
          background-color: #fff;
          box-shadow:
            0 3px 5px rgba(0, 0, 0, 0.05),
            0 1px 3px rgba(0, 0, 0, 0.08);

          input {
            width: 90%;
            padding: 12px 0;
            border: none;
            background-color: transparent;
            font-family: Inter;
            font-size: 14px;
            font-weight: 400;
            line-height: 16.8px;
          }

          button[type='submit'] {
            padding: 0;
            background: transparent;
            display: grid;
            place-items: center;
          }

          .chat-form__clear-scroll {
            position: absolute;
            top: -3rem;
            left: calc(50% - 21px);
            width: 42px;
            height: 42px;

            button[type='button'] {
              width: 100%;
              height: 100%;
              padding: 0;
              border-radius: 50%;
              display: flex;
              justify-content: center;
              align-items: center;
              background: linear-gradient(270.16deg, hsl(218, 100%, 60%) 0.13%, hsl(218, 90%, 65%) 47.04%, hsl(273, 100%, 70%) 99.93%);
            }
          }
        }
      }
    }
  }
}
