.messages__item {
  width: 100%;
  position: relative;
  padding: 12px;
  border-radius: 1rem;
  display: flex;
  gap: 1rem;
  cursor: pointer;
  overflow: hidden;

  @media (max-width: 420px) {
    padding-inline: 6px;
  }

  &.messages__admin-item {
    margin-bottom: 1px;
  }

  &:hover {
    background-color: hsl(218, 100%, 97%);
  }

  &.active {
    background-color: hsl(248, 100%, 97%);
  }

  &.needs-to-add-friend {
    background-color: #ffb0df;
  }

  .item__img {
    width: 48px;
    height: 48px;
    display: grid;
    place-items: center;

    .item__profile-photo {
      width: 48px;
      height: 48px;
      display: grid;
      place-items: center;

      img {
        border-radius: 50%;
      }
    }

    .status-indicator {
      left: 48px;

      @media (max-width: 420px) {
        left: 42px;
        bottom: 14px;
      }
    }
  }

  .item__content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .item__content-block {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .item__content-name {
        font-family: var(--font-family);
        font-size: 14px;
        font-weight: 600;
        line-height: 21px;
        text-align: left;
        word-wrap: break-word;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: hsl(237, 13%, 28%);

        overflow: hidden;
        max-width: 140px;
      }

      .item__content-creation-time {
        font-family: Inter;
        font-size: 13px;
        font-weight: 500;
        line-height: 21px;
        color: hsla(218, 15%, 52%, 1);

        @media (max-width: 420px) {
          font-size: 12px;
        }
      }

      .typing {
        width: 100%;
        max-width: 200px;
        display: flex;
        align-items: center;
        gap: 4px;

        @media (max-width: 768px) {
          max-width: calc(200px + 20vw);
        }

        @media (max-width: 500px) {
          max-width: calc(180px + 10vw);
        }

        @media (max-width: 420px) {
          max-width: calc(150px + 5vw);
        }

        @media (max-width: 380px) {
          max-width: calc(120px + 8vw);
        }

        svg {
          margin-left: -4px;
        }

        .item__content-text {
          width: 100%;
          font-family: Inter;
          font-size: 12px;
          font-weight: 400;
          line-height: 18px;
          text-align: left;
          color: hsl(218, 15%, 50%);
          word-wrap: break-word;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
      }

      .item__content-read-status {
        width: 24px;
        height: 24px;
        display: grid;
        place-items: center;

        .read-status {
          margin: 0;
        }
      }

      .unread-count {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 24px;
        padding: 2px 8px;
        border-radius: 24px;
        font-family: Inter;
        font-size: 12px;
        font-weight: 600;
        line-height: 18px;
        text-align: left;
        color: white;
        background: linear-gradient(276.23deg, hsl(218, 87%, 58%) 4.92%, hsl(218, 77%, 61%) 57.86%, hsl(273, 83%, 73%) 117.55%);
      }
    }
  }
}
