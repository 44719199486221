.photo {
  width: 100%;
  max-width: 700px;
  margin: 0 auto;
  padding: 20px;
  border-radius: 8px;

  & > div {
    margin-bottom: 5px;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px dashed #cccccc;
    border-radius: 8px;
    cursor: pointer;
    transition: border-color 0.2s;

    &:hover {
      border-color: #999999;
    }

    p {
      margin: 0;
      color: #555555;
    }
  }

  aside {
    margin-top: 20px;

    h4 {
      margin-bottom: 10px;
      font-weight: bold;
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      display: flex;
      flex-wrap: wrap;

      div {
        margin: 5px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 150px;

        div {
          width: 150px;
          height: 150px;
          margin-bottom: 5px;

          img {
            width: 100%;
            height: 100%;
            border-radius: 5px;
            object-fit: cover;
          }
        }

        button {
          background-color: var(--red-color);
          color: #ffffff;
          border: none;
          border-radius: 5px;
          padding: 5px 10px;
          cursor: pointer;
          font-size: 12px;
          transition: background-color 0.2s;

          &:hover {
            background-color: darkred;
          }
        }
      }
    }
  }
}

.photo-block {
  background: white;
  border: 2px solid #cccccc !important;
  margin-bottom: 5px;
  padding-bottom: 50px;
  display: flex;
  flex-direction: column;
  text-align: left;

  .arrow {
    display: inline-block;
    position: relative;
  }

  .arrow-head {
    position: relative;
    bottom: -25px;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 20px solid black; /* Измените цвет на нужный */
  }

  .arrow-stick {
    position: absolute;
    bottom: -20px;
    left: 8px;
    width: 4px; /* Ширина палочки */
    height: 40px; /* Высота палочки */
    background-color: black; /* Цвет палочки */
  }

  .info-circle {
    margin-left: 5px;
    display: inline-block;
    width: 30px; /* Размер круга */
    height: 30px; /* Размер круга */
    line-height: 30px; /* Центрирование текста по вертикали */
    border-radius: 50%; /* Делает круг круглым */
    background-color: hsla(218, 100%, 60%, 0.75);
    color: white; /* Цвет текста */
    text-align: center; /* Центрирование текста по горизонтали */
    font-weight: bold; /* Жирность текста */
    cursor: pointer; /* Изменяет курсор на указатель */
    user-select: none; /* Предотвращает выделение текста */
  }

  .info-circle:hover {
    background-color: var(--secondary-blue-color); /* Цвет фона при наведении */
  }

  h1 {
    font-size: 22px;
  }

  p {
    width: 100%;
  }

  button {
    margin: 20px;

    p {
      color: #fff;
    }
  }
}
