.legal {
  width: min(800px, 100% - 40px);
  margin: 5rem auto;
  padding: 20px;
  border-radius: 20px;
  background-color: var(--light-background);
  box-shadow:
    0 3px 6px rgba(0, 0, 0, 0.08),
    0 1px 3px rgba(0, 0, 0, 0.1);

  h2 {
    margin-block: 2rem;
    font-size: calc(0.95rem + 0.8vw);
    color: #333;
    font-weight: 500;
    text-align: center;
  }

  p {
    font-size: calc(0.7rem + 0.25vw);
    color: #444;
    line-height: 1.6;
    margin-bottom: 20px;
  }

  h2 + p {
    font-size: calc(0.75rem + 0.25vw);
    font-weight: 500;
    color: #555;
  }

  .legal-block {
    .legal-block__item {
      border: 1px solid #999;
      padding: 20px;
      border-radius: 8px;
      margin-bottom: 20px;
      background-color: hsla(0, 0%, 100%, 0.75);

      a {
        width: min(350px, 100%);
        max-width: 100%;
        display: block;
        padding: 12px 20px;
        margin: 1rem auto;
        border: none;
        border-radius: 8px;
        text-decoration: none;
        text-align: center;
        color: #fff; // Можете изменить на предпочтительный цвет текста кнопок
        background-color: var(--primary-blue-color);
        transition: background-color 150ms ease;

        &:hover {
          background-color: var(--secondary-blue-color);
        }
      }
    }
  }
}

@media (max-width: 415px) {
  .legal {
    width: calc(100% - 20px);
    margin-block: 3rem;
    padding: 10px;

    .legal-block {
      .legal-block__item {
        padding: 10px;
      }
    }
  }
}
