.voteAdmin {
  .vote-container {
    max-width: 1100px;
    margin: 0 auto;
    padding: 20px;

    h1 {
      margin-bottom: 20px;
    }

    .filters {
      display: flex;
      flex-wrap: wrap;
      gap: 15px;
      margin-bottom: 20px;

      label {
        display: flex;
        flex-direction: column;
        font-size: 14px;
        font-weight: 600;

        input,
        select {
          margin-top: 5px;
          padding: 6px 8px;
          font-size: 14px;
        }
      }

      button {
        align-self: flex-end;
        padding: 8px 16px;
        font-size: 14px;
        cursor: pointer;
        border: none;
        background-color: #005b96;
        color: #fff;
        border-radius: 4px;
        transition: background-color 0.2s ease-in-out;

        &:hover {
          background-color: #004570;
        }
      }
    }

    .error {
      color: red;
      margin-bottom: 10px;
    }

    .table-container {
      overflow-x: auto;
      margin-bottom: 20px;

      table {
        width: 100%;
        border-collapse: collapse;
        text-align: left;

        th,
        td {
          padding: 10px;
          border: 1px solid #ccc;
        }

        th {
          background-color: #f2f2f2;
        }
      }
    }

    .pagination {
      display: flex;
      align-items: center;
      gap: 10px;

      button {
        padding: 6px 12px;
        font-size: 14px;
        cursor: pointer;
        border: 1px solid #ccc;
        background-color: #fff;
        border-radius: 4px;

        &:disabled {
          opacity: 0.5;
          cursor: not-allowed;
        }

        &:not(:disabled):hover {
          background-color: #efefef;
        }
      }
    }
  }
}
