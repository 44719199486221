.step1-information {
  min-height: 100vh;
  max-width: 100%;
  margin-inline: auto;
  padding: 20px;
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 20px;
  display: flex;
  flex-direction: column;
  text-align: center;
  color: var(--main-color);

  * {
    overflow-wrap: break-word;
  }

  form {
    width: min(650px, 100%);
    margin: 0 auto 100px;
    padding: 40px 20px;
    border-radius: 10px;
    border: 1px solid #fff;
    background-color: hsla(0, 0%, 100%, 0.75);
    box-shadow:
      0 3px 6px rgba(0, 0, 0, 0.08),
      0 1px 3px rgba(0, 0, 0, 0.1);

    .name {
      span {
        display: block;
        text-align: left;
        font-size: min(14px, 0.5rem + 1vmax);
        color: #555;
      }

      .custom-input {
        margin-block: 3px 20px;

        .wrapper-input {
          font-size: min(16px, 0.6rem + 1vmax);

          input {
            width: 100%;
            padding: 20px 10px;
            outline: none !important;
            border: none;
            border-radius: 8px;
            box-shadow:
              0 3px 5px rgba(0, 0, 0, 0.08),
              0 1px 3px rgba(0, 0, 0, 0.1);
            font-family: var(--font-family);
            font-size: 15px;
            color: var(--pink-color);
            background-color: #fff;

            &:hover {
              box-shadow:
                0 5px 7px rgba(0, 0, 0, 0.1),
                0 3px 5px 1px rgba(0, 0, 0, 0.08);
            }

            &:focus {
              box-shadow: none;
              border: 2px solid var(--secondary-blue-color);
            }
          }
        }

        .error-input.visible p {
          text-align: center;
        }
      }
    }

    .step1-information-block {
      display: flex;
      flex-wrap: wrap;
      gap: 20px; /* This is to give some space between the boxes */

      .step1-information-block__item {
        width: 100%;

        label {
          display: block;
          text-align: left;
          font-size: min(14px, 0.5rem + 1vmax);
          color: #555;
        }

        .step1-information-block__item_box,
        .step1-photo {
          background-color: #fff;
          width: 100%;
          margin: 3px auto;
          box-sizing: border-box;
          border-radius: 8px;
          box-shadow:
            0 3px 5px rgba(0, 0, 0, 0.08),
            0 1px 3px rgba(0, 0, 0, 0.05);

          &:hover {
            box-shadow:
              0 5px 7px rgba(0, 0, 0, 0.1),
              0 3px 5px 1px rgba(0, 0, 0, 0.08);
          }
        }

        span:not(.react-dropdown-select-option) {
          display: block;
          font-size: min(14px, 0.5rem + 1vmax);
          color: #555;
        }

        .select-item {
          position: relative;
          width: 100%;

          .react-dropdown-select,
          .react-dropdown-select-dropdown {
            width: 100%;
            border: none;
            border-radius: 8px;
            background-color: #fff;
          }

          .react-dropdown-select {
            margin-top: 0;
            padding: 10px 10px 10px 5px;

            span {
              font-size: 15px;
              background-color: #fff;
            }

            input {
              font-family: var(--font-family);
              font-size: min(14px, 0.5rem + 1vmax);
              color: #555;
            }

            .react-dropdown-select-content {
              span {
                color: var(--pink-color);
              }
            }
          }

          .react-dropdown-select:focus {
            box-shadow: none;
          }

          span.react-dropdown-select-item {
            text-align: left;
            font-size: min(15px, 0.5rem + 1vmax);
          }

          span.react-dropdown-select-item:hover {
            background-color: hsla(218, 100%, 60%, 0.25);
          }

          span.react-dropdown-select-item-selected,
          span.react-dropdown-select-item-selected:hover {
            color: #fff;
            background-color: hsla(218, 100%, 60%, 0.85);
          }

          .dropdown-select-button {
            padding-inline: 0;
            background-color: transparent;
          }

          .dropdown-select-icon-down {
            transform: rotate(0deg);
            transition: transform 300ms ease;
          }

          .dropdown-select-icon-up {
            transform: rotate(180deg);
            transition: transform 300ms ease;
          }
        }

        .select-item:has(.error-message) {
          .react-dropdown-select {
            border: 1px solid var(--red-color);
          }

          .error-message {
            position: absolute;
            width: 100%;
            display: flex;
            justify-content: center;
            top: calc(100% + 3px);
            color: var(--red-color);
            font-size: 13px;
          }
        }

        .step1-audio {
          .audio {
            width: 100%;
          }
        }
      }

      .step1-information-block__button {
        width: 100%;

        button {
          width: 75%;
          min-height: 50px;
          margin: 20px auto;
          padding: 0.6rem 1.2rem;
          border-radius: 40px;
          border: none;
          background: linear-gradient(145deg, var(--primary-blue-hover), var(--secondary-blue-color), var(--primary-blue-hover));
          color: #fff;
          font-size: 1rem;
          font-family: var(--font-family);
          line-height: 18.6px;
          cursor: pointer;
          box-shadow:
            0 3px 6px rgba(0, 0, 0, 0.15),
            0 1px 3px rgba(0, 0, 0, 0.1);
          transition:
            transform 150ms ease-in-out,
            box-shadow 150ms ease-in-out;

          &:hover {
            transform: translateY(-2px);
            box-shadow:
              0 5px 10px rgba(0, 0, 0, 0.2),
              0 2px 5px rgba(0, 0, 0, 0.15);
          }

          @media (max-width: 580px) {
            width: 100%;
          }
        }
      }

      .step1-information-block__item_box.step1-textarea {
        padding: 20px;
      }

      .step1-photo:has(.errorPhoto) {
        border: 1px solid var(--red-color);
      }

      .errorPhoto,
      .error {
        position: relative;
        top: 0;
        width: 100%;
        padding: 0;
        display: flex;
        justify-content: center;
        color: var(--red-color);
        font-family: var(--font-family);
        font-size: 13px;
      }

      .profile-photo .errorPhoto {
        top: 17px;
      }

      .photo,
      .videoModel,
      .step1-audio {
        box-shadow: none;
        font-family: var(--font-family);
        font-size: 1rem;
        line-height: 18.6px;
      }

      .step1-audio {
        padding: 20px;

        h4 {
          margin-top: 20px;
          font-weight: bold;
        }

        audio {
          margin-block: 10px;
        }
      }

      textarea {
        width: 100%;
        height: 200px;
        resize: none;
      }

      .custom-editor:has(.error) .custom-editor_block {
        border: 1px solid var(--red-color);
      }

      .checkSteps {
        display: flex;
        justify-content: center;
        padding-bottom: 15px;

        &-block {
          border: 2px solid var(--secondary-blue-color); /* Синий цвет границы */
          padding: 15px; /* Отступы вокруг текста */
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Тень вокруг контейнера */
          border-radius: 8px; /* Округлённые углы */
          background-color: #f0f8ff; /* Светло-голубой цвет фона */
          margin: 10px 20px; /* Отступы сверху и снизу для разделения контента */
          max-width: 600px; /* Максимальная ширина контейнера */
          word-wrap: break-word; /* Перенос слов, если текст не умещается */
        }
      }

      .checkSteps p {
        color: #333; /* Тёмно-серый цвет текста */
        font-size: 16px; /* Размер шрифта */
        line-height: 1.5; /* Расстояние между строками */
      }
    }

    .photos:has(.errorPhoto-block p) .step1-photo {
      border: 1px solid var(--red-color);
    }
  }
}

@media (max-width: 415px) {
  .step1-information {
    padding: 20px 10px;

    form {
      padding: 20px 10px;

      .step1-information-block {
        .photo,
        .videoModel,
        .step1-audio {
          padding: 20px 10px;
          font-size: 14px;

          p {
            width: 100%;
          }
        }

        .step1-information-block__item_box.step1-textarea {
          width: 100%;
          padding: 20px 10px;
        }
      }
    }
  }
}
