.profile {
  .profile__main {
    padding-block: 120px;

    .profile__main-inner {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: min(650px, 100vw - 20px);
      margin: 0 auto;

      .content__img {
        height: 115px;
        margin-bottom: 100px;
        position: relative;

        .content__img___background {
          width: 150px;
          height: 150px;

          svg {
            width: 150px;
            height: 150px;
            border-radius: 50%;
          }
        }

        .content__img__camera {
          cursor: pointer;
          display: inline;
          position: absolute;
          left: 115px;
          bottom: -25px;

          svg {
            height: 40px;
            width: 40px;
          }
        }
      }

      .content__box {
        width: 100%;
        margin: 0.5rem auto;
        padding: 1rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1rem;
        border-radius: 20px;
        border: 1px solid #fff;
        background-color: hsla(218, 100%, 75%, 0.33);

        .content__box-item {
          width: 100%;
          padding: 3rem;
          border-radius: 15px;
          border: 1px solid #fff;
          background-color: var(--light-background);
          font-family: var(--font-family);

          h6 {
            margin-bottom: 2rem;
            font-size: min(22px, 0.8rem + 1vmax);
            color: var(--secondary-blue-color);
            text-align: center;
          }

          span {
            color: #555;
            font-size: min(15px, 0.5rem + 1vmax);
          }

          .age {
            width: 190px;

            .react-dropdown-select,
            .react-dropdown-select-dropdown {
              border-radius: 8px;
              border: 2px solid hsla(218, 100%, 60%, 0.5);
              background-color: #fff;
            }

            .react-dropdown-select {
              margin-top: 3px;
              padding: 0 10px;
            }

            .react-dropdown-select:focus {
              box-shadow: none;
            }

            .react-dropdown-select:focus-within {
              border: 2px solid var(--secondary-blue-color);
              box-shadow: none;
            }

            span.react-dropdown-select-item:hover {
              background-color: hsla(218, 100%, 60%, 0.25);
            }

            span.react-dropdown-select-item-selected,
            span.react-dropdown-select-item-selected:hover {
              color: #fff;
              background-color: hsla(218, 100%, 60%, 0.85);
            }

            .dropdown-select-button {
              padding-inline: 0;
              background-color: transparent;
            }

            .dropdown-select-icon-down {
              transform: rotate(0deg);
              transition: transform 300ms ease;
            }

            .dropdown-select-icon-up {
              transform: rotate(180deg);
              transition: transform 300ms ease;
            }
          }

          hr {
            border-top: 1px solid var(--secondary-blue-color);
            border-bottom: 1px solid var(--secondary-blue-color);
          }

          label {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-block: 1rem;
            position: relative;

            p:not(.error-message) {
              font-family: var(--font-family);
              font-size: min(15px, 0.5rem + 1vmax);
            }

            span.error,
            span.success {
              font-size: 13px;
              color: var(--pink-color);
            }
          }

          form label {
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            gap: 0.125rem;
            border: none;
            margin-bottom: 1rem;
            font-size: min(16px, 0.6rem + 1vmax);

            .custom-input {
              width: 100%;

              input {
                position: relative !important;
                display: block;
                width: 100%;
                outline: none !important;
                border: 2px solid hsla(218, 100%, 60%, 0.5);
                border-radius: 8px;
                appearance: none !important;
                -webkit-appearance: none !important;
                -moz-appearance: none !important;
                margin: 0 !important;
                padding: 20px 10px;
                font-family: var(--font-family);
                font-size: 14px !important;
                cursor: text !important;
                transition:
                  background-color 0.3s,
                  border-color 0.3s,
                  color 0.3s;

                &:focus {
                  border: 2px solid var(--secondary-blue-color);
                }
              }
            }
          }
        }

        .item-buttons {
          margin: 2rem auto 0;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 0.5rem;

          button {
            width: 75%;
            min-height: 50px;
            padding: 11px 22px;
            border-radius: 40px;
            border: 1px solid var(--secondary-blue-color);
            background-color: #fff;
            color: var(--secondary-blue-color);
            font-size: 15px;
            font-weight: 400;
            font-family: var(--font-family);
            line-height: 18.6px;

            &:hover {
              color: #fff;
              background-color: hsla(218, 100%, 60%, 0.85);
            }
          }
        }
      }

      .profile__decor {
        position: fixed;
        left: 0;
        bottom: 0;
        width: 100%;
        z-index: -1;

        svg {
          width: 100%;
          height: 150%;
        }
      }
    }
  }
}

.success,
.error {
  position: absolute;
  bottom: -22px;
}

.custom-input {
  .error-input.visible {
    position: relative;
    top: 0;

    .error-message {
      font-size: 13px;
      word-wrap: break-word;
      text-align: left;
    }
  }
}

@media (max-width: 600px) {
  .profile {
    .profile__main {
      padding-top: 62px;
      .profile__main-inner {
        .content__box {
          padding: 10px;
          gap: 10px;

          .content__box-item {
            padding-inline: 10px;

            label span.error,
            label span.success {
              font-size: 11px;
            }

            .item-buttons {
              button {
                width: 100%;
                font-size: 14px;
              }
            }
          }
        }

        .profile__decor {
          height: 300px;
        }
      }
    }
  }
}
