.lk-username-container {
  input[name='username'] {
    display: none;
  }
}
[data-lk-source='screen_share'] {
  display: none;
}
.videoChat {
  height: 100vh;
}

[data-lk-local-participant='true'] {
  position: absolute !important;
  right: 0;
  top: 0;
  width: 250px;
  height: 150px;
  z-index: 90;
  .lk-participant-placeholder {
    z-index: 91;
  }
  .lk-participant-metadata {
    z-index: 91;
  }
}
@media (max-width: 650px) {
  [data-lk-local-participant='true'] {
    width: 175px;
    height: 90px;
  }
}

[data-lk-local-participant='false'] {
  position: relative !important;
  width: 100%;
  height: 100%;
}
.lk-grid-layout {
  --lk-col-count: 1 !important;
  --lk-row-count: 1 !important;
}

.lk-grid-layout {
  display: grid;
  background-image: url('../../../../images/img/backgraund/videoInitialRooms.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
