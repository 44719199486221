.search {
  &-block {
    display: flex;
    svg {
      margin-top: 2px;
      width: 14px;
      height: 14px;
      stroke: white;
    }
    svg circle {
      stroke: white;
    }
    svg {
      color: white;
    }
  }
  input {
    border: none !important;
    border-bottom: 2px solid rgba(224, 224, 224, 0.7) !important;
    outline: none !important;
    border-radius: 5px !important;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05) !important;
    appearance: none !important;
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
    padding: 10px 35px 10px 10px !important;
    font-size: 16px !important;
    margin: 0 !important;
    cursor: text !important;
    transition:
      background-color 0.3s,
      border-color 0.3s,
      color 0.3s,
      box-shadow 0.3s !important;
    position: relative !important;
    width: 250px !important;
  }

  input:hover,
  input:focus {
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.07);
  }

  input::placeholder {
    color: rgba(170, 170, 170, 0.7); /* Цвет подсказки также полупрозрачный */
  }

  input::after {
    content: '✎';
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 18px;
    opacity: 0.7;
    transition: opacity 0.3s;
  }

  input:hover::after,
  input:focus::after {
    opacity: 1;
  }
  .error {
    margin-left: 10px;
    color: #ff5959;
  }
}
