.buttonInfo {
  padding: 0;
  width: 25px;
  aspect-ratio: 1/1; // соотношение сторон кнопки
  border-radius: 100%; // делает кнопку круглой
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--pink-color); // цвет фона кнопки
  animation: question 1s infinite ease;
  cursor: pointer;
  border: none; // убираем стандартную обводку у кнопки
  &:hover {
    background-color: var(--pink-color); // цвет фона кнопки
  }
  @keyframes question {
    0% {
      box-shadow: rgba(255, 255, 255, 0) 0 0 0 0;
    }
    50% {
      box-shadow: #ff019969 0 0 9px 5px;
    }
    100% {
      box-shadow: rgba(255, 255, 255, 0) 0 0 0 0;
    }
  }
  svg {
    margin-top: 3px;
  }
}
