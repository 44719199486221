.status {
  svg {
    width: 11px !important;
    height: 11px !important;
  }

  .status-indicator {
    z-index: 4;
    position: absolute;
    bottom: -20px;
    left: 20px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-family: 'Roboto', Arial, sans-serif;
    font-size: 10px;
    transition: all 0.3s ease;
    box-shadow: 10px 2px 4px rgba(0, 0, 0, 0.1);
    animation: pulse 2s infinite;
  }

  /* Individual status colors */
  .online {
    background: linear-gradient(45deg, hsl(139, 85%, 70%), hsl(139, 85%, 50%));
  }

  .offline {
    background: linear-gradient(45deg, hsl(339, 100%, 70%), var(--red-color)); /* red gradient */
  }

  .away {
    background: linear-gradient(45deg, hsl(44, 100%, 70%), hsl(44, 100%, 50%)); /* orange gradient */
  }

  .on_call {
    background: linear-gradient(45deg, var(--primary-blue-color), var(--intense-blue-color)); /* blue gradient */
  }

  .waiting_in_room {
    background: linear-gradient(45deg, hsl(273, 100%, 70%), hsl(273, 100%, 50%)); /* purple gradient */
  }
}

.item__online-status {
  .status .status-indicator {
    bottom: 12px;
    left: 48px;
    width: 16px;
    height: 16px;
  }
}

.item__online-status-right {
  position: relative;
  .status .status-indicator {
    bottom: -10px;
    left: -25px;
    width: 15px;
    height: 15px;
  }
}
.MODEL {
  .status-indicator {
    bottom: -3px !important;
  }
}
.girl__box {
  .status-indicator {
    position: static;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    // background: rgba(129, 255, 19, 1);
  }
}
.people__block {
  .client__photo {
    .status .status-indicator {
      position: absolute;
      left: 62px;
      bottom: 10px;
      width: 18px;
      height: 18px;
      border-radius: 50%;
    }
  }
}

.message-user__content {
  .status-indicator {
    bottom: 0;
    left: 33px;
    width: 18px;
    height: 18px;
  }
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 8px var(--pulse-color);
  }
  50% {
    box-shadow:
      0 0 15px var(--pulse-color),
      0 0 20px var(--pulse-color);
  }
  100% {
    box-shadow: 0 0 8px var(--pulse-color);
  }
}

.status-indicator {
  --pulse-color: rgba(0, 0, 0, 0.5); // дефолт, если нет класса
  animation: pulse 1.5s infinite;
  // остальные стили...
}

.online {
  background: rgba(82, 179, 0, 0.91);
  --pulse-color: rgba(82, 179, 0, 0.5);
}

.offline {
  background: linear-gradient(45deg, #e74c3c, #c0392b);
  --pulse-color: rgba(231, 76, 60, 0.5);
}

.away {
  background: linear-gradient(45deg, #f39c12, #f1c40f);
  --pulse-color: rgba(243, 156, 18, 0.5);
}

.on_call {
  background: linear-gradient(45deg, #3498db, #2980b9);
  --pulse-color: rgba(52, 152, 219, 0.5);
}

.waiting_in_room {
  background: linear-gradient(45deg, #9b59b6, #8e44ad);
  --pulse-color: rgba(155, 89, 182, 0.5);
}
