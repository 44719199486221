.AccountController {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f4f4f9;
  padding: 20px;

  &-block {
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 30px;
    width: 100%;
    max-width: 400px;

    & > div {
      margin-bottom: 20px;

      p {
        margin: 0;
        font-weight: bold;
        color: #333;
      }

      input[type='number'],
      textarea {
        width: 100%;
        padding: 10px;
        margin-top: 5px;
        border: 1px solid #ddd;
        border-radius: 5px;
        font-size: 16px;
        background-color: #f9f9f9;
        transition: border-color 0.3s;

        &:focus {
          border-color: #007bff;
          outline: none;
        }
      }
    }

    &-coin,
    &-messages,
    &-details {
      display: flex;
      flex-direction: column;

      p {
        font-size: 18px;
      }

      textarea {
        resize: vertical;
        height: 100px;
      }
    }

    button {
      display: inline-block;
      padding: 10px 20px;
      background-color: #007bff;
      color: #fff;
      border: none;
      border-radius: 5px;
      font-size: 16px;
      cursor: pointer;
      transition: background-color 0.3s;
      &:hover {
        background-color: #0056b3;
      }
    }
  }
}
