.modelVote {
  .model-vote {
    background-color: #fff;
    padding: 1.5rem;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);

    &__title {
      margin: 0 0 1rem;
      font-size: 1.25rem;
      font-weight: 600;
      color: #333;
    }

    &__filters {
      display: flex;
      flex-wrap: wrap;
      gap: 1rem;
      margin-bottom: 1rem;
    }

    &__input {
      display: flex;
      flex-direction: column;

      label {
        margin-bottom: 0.25rem;
        font-size: 0.9rem;
        color: #666;
      }

      input {
        padding: 0.45rem 0.75rem;
        border: 1px solid #ccc;
        border-radius: 4px;
        font-size: 0.9rem;
      }

      &.model-vote__checkbox {
        flex-direction: row;
        align-items: center;

        label {
          margin-right: 0.5rem;
          margin-bottom: 0;
        }
      }
    }

    &__button {
      padding: 0.5rem 1rem;
      background-color: #3876f0;
      color: #fff;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      align-self: end;
      transition: background-color 0.2s ease;

      &:hover {
        background-color: #2858b8;
      }
    }

    &__results {
      margin-top: 1rem;
      font-size: 0.95rem;
      color: #333;
    }

    &__stat {
      display: flex;
      gap: 0.5rem;
      margin-bottom: 0.5rem;

      span {
        font-weight: 600;
        color: #444;
      }
    }
  }
}
