.girls__block {
  min-height: 100vh;
  max-width: calc(100vw - 5rem * 2);
  margin: 5rem auto;

  .girls__box {
    display: grid;
    justify-content: center;
    grid-template-columns: repeat(auto-fit, 263px);
    gap: 1rem;

    .no-data {
      margin-top: 50px;
      width: 200px;
      height: 200px;

      p {
        margin-top: 20px;
        text-align: center;
        margin-bottom: 15px;
        font-size: 22px;
        font-weight: 700;
      }

      p:first-letter {
        color: var(--pink-color);
      }
    }
  }
}

@media (max-width: 800px) {
  .girls__block {
    max-width: calc(100vw - 1rem * 2);
  }
}

@media (max-width: 620px) {
  .girls__block {
    max-width: calc(100vw - 0.5rem * 2);

    .girls__box {
      grid-template-columns: repeat(2, 45vw);
      gap: 0.5rem;
    }
  }
}
