.filter-tooltip {
  font-family: var(--font-family);
  padding: 0.5rem;

  .filter-tooltip__box {
    min-width: 150px;
    margin-top: 10px;

    .filter-tooltip__checkbox {
      display: none;
    }

    .filter-tooltip__label {
      position: relative;
      cursor: pointer;
      font-size: 14px;
      user-select: none;
    }

    .filter-tooltip__label:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 25px;
      height: 25px;
      border: 2px solid #aaa;
      border-radius: 3px;
      background-color: white;
    }

    .filter-tooltip__checkbox:checked + .filter-tooltip__label:after {
      content: '';
      position: absolute;
      left: 9px;
      top: 5px;
      width: 9px;
      height: 13px;
      border: solid #000;
      border-width: 0 3px 3px 0;
      transform: rotate(45deg);
    }

    label {
      p {
        top: 3px;
        right: -36px;
        position: relative;
      }

      p.flex {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 5px;

        svg {
          width: 30px;
          height: auto;
        }
      }
    }
  }
}

.filter-buttons {
  margin: 1rem 0.5rem;
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;

  button {
    font-family: var(--font-family);
    font-size: 15px;
    padding: 8px 16px; // увеличен внутренний отступ для кнопки
    border-radius: 4px; // скругленные углы для кнопки
    border: none; // удаление стандартных стилей границы
    background-color: var(--primary-blue-color); // добавлен цвет фона для кнопки
    color: white; // цвет текста кнопки
    cursor: pointer; // указатель руки при наведении
    transition: background-color 0.3s; // плавный переход цвета при наведении

    &:hover {
      background-color: var(--secondary-blue-color); // темнее оттенок при наведении
    }
  }
}
