.UnreadCountFriends {
  position: relative;
  .header_link {
    position: relative;
    span {
      position: absolute;
      font-size: 10px;
      top: -2px;
      right: -17px;
      color: var(--pink-color);
    }
  }
}
