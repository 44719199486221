.button-roulette {
  // Примерные локальные переменные (кастомизируйте под себя)
  $button-width: 335px;
  $button-height: 363px;
  $button-bg: #ffffff;
  $button-padding-top: 40px;
  $button-gap: 9px;
  $button-z-index: 9;

  // Цвет линий
  $wave-border-color: rgba(255, 255, 255, 0.2);

  @mixin center-flex {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  width: $button-width;
  height: $button-height;
  position: absolute;
  top: 100.5px;
  left: 552.5px;
  padding-top: $button-padding-top;
  gap: $button-gap;
  border-radius: 20px;
  z-index: $button-z-index;
  background-color: $button-bg;

  &__block {
    @include center-flex;
    flex-direction: column;
    gap: $button-gap;
  }

  &__text {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  &__title {
    font-family: 'Unbounded', sans-serif;
    font-size: 18px;
    font-weight: 700;
    line-height: 27px;
  }

  &__description {
    font-family: 'Inter', sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 14.52px;
    color: #929292;
    margin: 8px 40px 0 40px;
  }

  &__wave-button {
    // Стили для самой кнопки
    @include center-flex;
    position: relative; // нужно для псевдоэлемента
    width: 220px;
    height: 46px;
    margin-top: 25px;
    padding: 16px 32px;
    border-radius: 40px;
    background: linear-gradient(270.16deg, #3689f1 0.13%, #5191e8 47.04%, #bf80f3 99.93%);
    color: #fff;
    font-family: 'Urbanist', sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 19.2px;
    cursor: pointer;
    overflow: hidden;
    z-index: 1; // чтобы текст был выше псевдоэлемента

    p {
      margin-left: 5px;
    }

    &:hover {
      background-color: #45a049; // любой цвет при hover
    }

    // Псевдоэлемент, покрывающий всю кнопку круговыми линиями
    &::after {
      content: '';
      position: absolute;
      inset: 0; // top: 0; right: 0; bottom: 0; left: 0;
      z-index: 0; // линия будет под содержимым кнопки
      pointer-events: none;

      // Линии каждые 5px: "1px" цвет, "4px" пустота, повторяется
      // можно настроить толщину линии и промежуток
      background: repeating-radial-gradient(
        circle at center,
        transparent 0 4px,
        // первые 4px прозрачны
        $wave-border-color 4px 5px // 1px полупрозрачной линии
      );
    }
  }
}
