.ActionUserTooltips {
  .tooltips-block {
    &_box {
      ul {
        padding: 10px;
        li {
          cursor: pointer;
          padding: 10px;
          &:hover {
            background: white;
            color: black;
          }
        }
      }
    }
  }
}
