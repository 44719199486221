.NeedsToAddFriend-block button {
  min-width: 200px;
  transition: background-color 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.NeedsToAddFriend-block button:disabled {
  cursor: not-allowed;
}

.loading-text {
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading-dots {
  width: 1em; /* fixed width for dots */
  display: inline-block;
  text-align: left;
  overflow: hidden;
  white-space: nowrap;
  position: relative;
  margin-left: 3px;
}

.loading-dots::after {
  content: '';
  display: inline-block;
  animation: loadingDots 2s steps(4, end) infinite;
}

@keyframes loadingDots {
  0% {
    content: '';
  }
  25% {
    content: '.';
  }
  50% {
    content: '..';
  }
  75% {
    content: '...';
  }
  100% {
    content: '';
  }
}
