:root {
  --toastify-z-index: 9999999999 !important;

  --toastify-color-light: #fff;
  --toastify-color-dark: #000;

  --toastify-color-info: var(--primary-blue-color);
  --toastify-color-success: #5cb80b;
  --toastify-color-error: #ff0000;

  --toastify-icon-color-info: var(--toastify-color-info);
  --toastify-icon-color-success: var(--toastify-color-success);
  --toastify-icon-color-error: var(--toastify-color-error);

  --toastify-toast-width: 291px;
  --toastify-toast-min-height: 109px;
  --toastify-toast-background: #fff;
  --toastify-font-family: var(--font-family);

  --toastify-text-color-light: #000;
  --toastify-text-color-dark: #fff;

  --toastify-color-progress-info: var(--toastify-color-info);
  --toastify-color-progress-success: var(--toastify-color-success);
  --toastify-color-progress-error: var(--toastify-color-error);
}
