@import 'variables';

.admin {
  width: 100%;
  &-block {
    width: 100%;
    display: flex;
  }
}

input:focus {
  outline: none;
}
$block-color: #6b6b6b;

html {
  overflow-x: hidden;
}

body::-webkit-scrollbar {
  width: 10px;
}

body::-webkit-scrollbar-track {
  border-radius: 100vw;
  background-color: hsla(218, 100%, 75%, 0.2);
}

body::-webkit-scrollbar-thumb {
  border-radius: 10px;
  border: 1px solid transparent;
  background-clip: padding-box;
  background-color: var(--primary-blue-color);
}

body::-webkit-scrollbar-thumb:hover {
  background-color: var(--primary-blue-hover);
}

.displayColum {
  width: 100%;
  display: flex;
  flex-direction: column;
  .errorPhoto {
    padding: 50px 0 0 0;
  }
}
.back-arrow {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.no-scroll {
  overflow: hidden;
}
