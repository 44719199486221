.no-results {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  flex-direction: column;

  h2 {
    margin-top: 10px;
    color: var(--main-color);
    font-family: Inter;
    font-size: 28px;
    font-weight: 400;
    line-height: 48px;
    text-align: center;

    @media (max-width: 420px) {
      font-size: 24px;
    }
  }

  img {
    width: 150px;
    height: 150px;
    rotate: 270deg;
  }
}
