.staff {
  width: 100%;
  $primary-color: #3498db;
  $secondary-color: #2ecc71;
  $text-color: #333;
  $background-color: #f5f5f5;
  $nav-background: #fff;
  $nav-border: #ddd;
  font-family: 'Arial, sans-serif';
  color: $text-color;
  background-color: $background-color;
  padding: 20px;

  nav {
    background-color: $nav-background;
    border: 1px solid $nav-border;
    border-radius: 5px;
    margin-bottom: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      display: flex;
      justify-content: space-around;

      li {
        margin: 0;

        a {
          display: block;
          padding: 10px 20px;
          color: $text-color;
          text-decoration: none;
          border-radius: 5px;
          transition:
            background-color 0.3s ease,
            color 0.3s ease;

          &:hover {
            background-color: $primary-color;
            color: #fff;
          }

          &.active {
            background-color: $secondary-color;
            color: #fff;
          }
        }
      }
    }
  }

  .content {
    background-color: $nav-background;
    border: 1px solid $nav-border;
    border-radius: 5px;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
}
