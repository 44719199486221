.videoModel {
  width: 100%;
  max-width: 700px;
  margin: 0 auto;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  border-radius: 10px;
  font-family: Arial, sans-serif;

  > div {
    border: 2px dashed #cccccc;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    cursor: pointer;
    transition: border-color 0.2s;

    &:hover {
      border-color: #999999;
    }

    p {
      margin: 0;
      color: #555555;
    }
  }

  aside {
    margin-top: 20px;

    h4 {
      margin-bottom: 10px;
      font-weight: bold;
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      display: flex;
      flex-wrap: wrap;

      div {
        margin: 5px;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 150px;

        div {
          width: 150px;
          margin-bottom: 5px;

          img {
            width: 100%;
            height: 100%;
            border-radius: 5px;
            object-fit: cover;
          }
        }

        button {
          background-color: var(--red-color);
          color: #ffffff;
          border: none;
          border-radius: 5px;
          padding: 5px 10px;
          cursor: pointer;
          font-size: 12px;
          transition: background-color 0.2s;

          &:hover {
            background-color: darkred;
          }
        }
      }
    }
  }
}
