.popup-overlay {
  position: fixed;
  display: block;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  overflow: auto;
  z-index: 9999 !important;
  background: rgba(119, 126, 144, 0.3);
  backdrop-filter: blur(10px);
  transition: 1s;

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 100vw;
    background-color: hsla(218, 100%, 75%, 0.2);
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    border: 1px solid transparent;
    background-clip: padding-box;
    background-color: var(--primary-blue-color);
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: var(--primary-blue-hover);
  }
}

.popup-content {
  animation: 1s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards a;
}

.popup.popup--big {
  width: 480px;
}

.popup-header__title.popup-header__title--error {
  color: var(--ui-red-color);
}

.popup-img {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 24px 0;
}

.popup-button {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  gap: 24px;
}

.bankAccount.bankAccount--popup input {
  width: 100%;
}
.bankAccount.bankAccount--popup textarea {
  width: 100%;
}
.bankAccount.bankAccount--popup .select {
  width: 100%;
}

.flex-column {
  flex-direction: column;
}

.popup-text {
  word-break: break-word;
}
