$hue: 223;
$starHue: 38;

.StarRating {
  margin-top: 100px;
  width: 100%;
  display: flex;
  justify-content: center;
  --fg: #{hsl($hue, 10%, 10%)};
  --primary: #{hsl($hue, 90%, 55%)};
  --yellow: #{hsl($starHue, 90%, 55%)};
  --yellow-t: #{hsla($starHue, 90%, 55%, 0)};
  --bezier: cubic-bezier(0.42, 0, 0.58, 1);
  --trans-dur: 0.3s;
  font-size: calc(24px + (30 - 24) * (100vw - 320px) / (1280 - 320));

  background-color: var(--bg);
  color: var(--fg);
  font:
    1em/1.5 'DM Sans',
    sans-serif;
  display: flex;
  transition:
    background-color var(--trans-dur),
    color var(--trans-dur);
}

.rating {
  &__display {
    font-size: 1em;
    font-weight: 500;
    min-height: 1.25em;
    position: absolute;
    top: 100%;
    width: 100%;
    text-align: center;
  }

  &__stars {
    display: flex;
    position: relative;
  }

  &__star {
    display: block;
    overflow: visible;
    pointer-events: none;
    width: 2em;
    height: 2em;

    &-ring,
    &-fill,
    &-line,
    &-stroke {
      animation-duration: 1s;
      animation-timing-function: ease-in-out;
      animation-fill-mode: forwards;
    }

    &-ring,
    &-fill,
    &-line {
      stroke: var(--yellow);
    }

    &-fill {
      fill: var(--yellow);
      transform: scale(0);
      transition:
        fill var(--trans-dur) var(--bezier),
        transform var(--trans-dur) var(--bezier);
    }

    &-line {
      stroke-dasharray: 12 13;
      stroke-dashoffset: -13;
    }

    &-stroke {
      stroke: hsl($hue, 10%, 80%);
      transition: stroke var(--trans-dur);
    }
  }

  &__label {
    cursor: pointer;
    padding: 0.125em;
  }

  @for $s from 1 through 4 {
    &__label--delay#{$s} &__star-ring,
    &__label--delay#{$s} &__star-fill,
    &__label--delay#{$s} &__star-line,
    &__label--delay#{$s} &__star-stroke {
      animation-delay: 0.05s * $s;
    }
  }

  &__input {
    position: absolute;
    -webkit-appearance: none;
    appearance: none;
  }

  // display
  &__input:hover ~ [data-rating]:not([hidden]) {
    display: none;
  }

  &__input-1:hover ~ [data-rating='1'][hidden],
  &__input-2:hover ~ [data-rating='2'][hidden],
  &__input-3:hover ~ [data-rating='3'][hidden],
  &__input-4:hover ~ [data-rating='4'][hidden],
  &__input-5:hover ~ [data-rating='5'][hidden],
  &__input:checked:hover ~ [data-rating]:not([hidden]) {
    display: block;
  }

  // stars
  &__input-1:hover ~ &__label:first-of-type &__star-stroke,
  &__input-2:hover ~ &__label:nth-of-type(-n + 2) &__star-stroke,
  &__input-3:hover ~ &__label:nth-of-type(-n + 3) &__star-stroke,
  &__input-4:hover ~ &__label:nth-of-type(-n + 4) &__star-stroke,
  &__input-5:hover ~ &__label:nth-of-type(-n + 5) &__star-stroke {
    stroke: var(--yellow);
    transform: scale(1);
  }

  &__input-1:checked ~ &__label:first-of-type &__star-ring,
  &__input-2:checked ~ &__label:nth-of-type(-n + 2) &__star-ring,
  &__input-3:checked ~ &__label:nth-of-type(-n + 3) &__star-ring,
  &__input-4:checked ~ &__label:nth-of-type(-n + 4) &__star-ring,
  &__input-5:checked ~ &__label:nth-of-type(-n + 5) &__star-ring {
    animation-name: starRing;
  }

  &__input-1:checked ~ &__label:first-of-type &__star-stroke,
  &__input-2:checked ~ &__label:nth-of-type(-n + 2) &__star-stroke,
  &__input-3:checked ~ &__label:nth-of-type(-n + 3) &__star-stroke,
  &__input-4:checked ~ &__label:nth-of-type(-n + 4) &__star-stroke,
  &__input-5:checked ~ &__label:nth-of-type(-n + 5) &__star-stroke {
    animation-name: starStroke;
  }

  &__input-1:checked ~ &__label:first-of-type &__star-line,
  &__input-2:checked ~ &__label:nth-of-type(-n + 2) &__star-line,
  &__input-3:checked ~ &__label:nth-of-type(-n + 3) &__star-line,
  &__input-4:checked ~ &__label:nth-of-type(-n + 4) &__star-line,
  &__input-5:checked ~ &__label:nth-of-type(-n + 5) &__star-line {
    animation-name: starLine;
  }

  &__input-1:checked ~ &__label:first-of-type &__star-fill,
  &__input-2:checked ~ &__label:nth-of-type(-n + 2) &__star-fill,
  &__input-3:checked ~ &__label:nth-of-type(-n + 3) &__star-fill,
  &__input-4:checked ~ &__label:nth-of-type(-n + 4) &__star-fill,
  &__input-5:checked ~ &__label:nth-of-type(-n + 5) &__star-fill {
    animation-name: starFill;
  }

  &__input-1:not(:checked):hover ~ &__label:first-of-type &__star-fill,
  &__input-2:not(:checked):hover ~ &__label:nth-of-type(2) &__star-fill,
  &__input-3:not(:checked):hover ~ &__label:nth-of-type(3) &__star-fill,
  &__input-4:not(:checked):hover ~ &__label:nth-of-type(4) &__star-fill,
  &__input-5:not(:checked):hover ~ &__label:nth-of-type(5) &__star-fill {
    fill: var(--yellow-t);
  }

  // screen reader text
  &__sr {
    clip: rect(1px, 1px, 1px, 1px);
    overflow: hidden;
    position: absolute;
    width: 1px;
    height: 1px;
  }
}

// Dark theme
//@media (prefers-color-scheme: dark) {
//  body {
//    --bg: #{hsl($hue, 10%, 10%)};
//    --fg: #{hsl($hue, 10%, 90%)};
//  }
//
//  .rating {
//    &__star {
//      &-stroke {
//        stroke: hsl($hue, 10%, 30%);
//      }
//    }
//  }
//}

// Animations
@keyframes starRing {
  from,
  20% {
    animation-timing-function: ease-in;
    opacity: 1;
    r: 8px;
    stroke-width: 16px;
    transform: scale(0);
  }
  35% {
    animation-timing-function: ease-out;
    opacity: 0.5;
    r: 8px;
    stroke-width: 16px;
    transform: scale(1);
  }
  50%,
  to {
    opacity: 0;
    r: 16px;
    stroke-width: 0;
    transform: scale(1);
  }
}

@keyframes starFill {
  from,
  40% {
    animation-timing-function: ease-out;
    transform: scale(0);
  }
  60% {
    animation-timing-function: ease-in-out;
    transform: scale(1.2);
  }
  80% {
    transform: scale(0.9);
  }
  to {
    transform: scale(1);
  }
}

@keyframes starStroke {
  from {
    transform: scale(1);
  }
  20%,
  to {
    transform: scale(0);
  }
}

@keyframes starLine {
  from,
  40% {
    animation-timing-function: ease-out;
    stroke-dasharray: 1 23;
    stroke-dashoffset: 1;
  }
  60%,
  to {
    stroke-dasharray: 12 13;
    stroke-dashoffset: -13;
  }
}
