.edit {
  width: 100%;
  padding: 20px;
  border-radius: 20px;

  .edit__title {
    h2 {
      margin-block: 2rem;
      font-size: calc(0.75rem + 1.5vmin);
      color: #333;
      font-weight: 500;
    }
  }

  .checkSteps {
    margin-bottom: 2rem;

    p {
      font-size: 15px;
      font-weight: 500;
      color: #555;
      text-align: left;
      line-height: 1.6;
      text-wrap: balance;
    }
  }

  .notification-panel {
    width: 100%;
    margin-block: 15px;
    padding: 20px;
    border-bottom: 6px solid var(--red-color);
    border-radius: 8px;
    background-color: hsla(339, 100%, 50%, 0.075);
    color: var(--red-color);
    font-size: 15px;
    // box-shadow:
    //   0 -1px 3px rgba(0, 0, 0, 0.08),
    //   0 1px 3px rgba(0, 0, 0, 0.05);
  }
}
