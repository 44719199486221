.adminRooms {
  .data-rooms {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    padding: 20px;
    background-color: #f9f9f9;

    .data-room {
      background-color: #ffffff;
      border: 1px solid #e0e0e0;
      border-radius: 10px;
      padding: 20px;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
      width: 300px;

      h2 {
        font-size: 18px;
        color: #333;
        margin-bottom: 10px;
      }

      p {
        font-size: 14px;
        color: #555;
        margin: 5px 0;
      }
    }
  }
  .filters {
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    padding: 16px;
    margin-bottom: 20px;
    border-radius: 6px;

    h2 {
      margin-top: 0;
      margin-bottom: 16px;
      font-size: 1.25rem;
    }

    .filterRow {
      display: flex;
      align-items: center;
      margin-bottom: 12px;

      label {
        display: inline-block;
        width: 220px; // при необходимости скорректируйте ширину
        margin-right: 8px;
        font-weight: 500;
        color: #333;
      }

      input[type='text'],
      input[type='date'],
      input[type='number'] {
        flex: 1;
        padding: 6px 8px;
        border: 1px solid #ccc;
        border-radius: 4px;
        font-size: 0.95rem;

        &:focus {
          outline: none;
          border-color: #888;
        }
      }

      &.checkboxRow {
        label {
          width: auto;
          margin-right: 10px;
        }

        input[type='checkbox'] {
          width: 20px;
          height: 20px;
          cursor: pointer;
        }
      }
    }
  }
}
