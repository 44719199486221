.previewPhoto {
  flex-direction: column;
  width: 100%;
  padding: 0 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  p {
    margin-top: 15px !important;
    color: black !important;
    font-weight: 700 !important;
  }
  &-block {
    margin: 20px;
    display: flex;
    &_left {
      width: 90px;
      height: 90px;
    }
    &_right {
      margin-left: 15px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      .top-message__user-online {
        font-size: 18px;
        color: #529500;
      }
    }
  }
  span {
    margin-top: 10px;
    color: black;
  }
  img {
    border-radius: 50%;
  }
}
@media (max-width: 400px) {
  .cr-boundary {
    width: 200px !important;
    height: 200px !important;
  }
}
