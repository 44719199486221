.staff {
  h2 {
    text-align: center;
    color: #444;
    margin-bottom: 20px;
  }

  /* Контейнер формы */
  form {
    max-width: 400px;
    margin: 50px auto;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  }

  /* Стили для каждого поля формы */
  form div {
    margin-bottom: 15px;
  }

  label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    color: #555;
  }

  input[type='email'],
  input[type='password'],
  input[type='text'] {
    width: calc(100% - 22px);
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 16px;
  }

  input[type='checkbox'] {
    margin-right: 10px;
  }

  /* Стили для кнопки */
  button[type='submit'] {
    display: inline-block;
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  button[type='submit']:hover {
    background-color: #0056b3;
  }

  /* Стили для разрешений */
  .permissions {
    display: flex;
    flex-wrap: wrap;
  }

  .permissions label {
    margin-right: 10px;
    margin-bottom: 10px;
  }
}
