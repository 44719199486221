.no-id {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;

  &-block {
    margin-bottom: 10rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 3rem;

    p {
      color: var(--main-color);
      font-family: Inter;
      font-size: 32px;
      font-weight: 400;
      line-height: 48px;
      text-align: center;
    }
  }
}
