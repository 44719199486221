.logo-main {
  cursor: pointer;
  span {
    font-size: 26px;
    display: flex;
    font-weight: 900;
    color: black;
    .one {
      color: #dd1982;
    }
  }
}

@media (max-width: 425px) {
  .logo-main {
    margin-left: 10px;
    span {
      display: flex;
      flex-direction: column;
    }
  }
}
