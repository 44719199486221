.chat-form__container {
  width: 100%;
  display: flex;
  justify-content: center;

  .chat-form {
    position: absolute;
    z-index: 10;
    width: min(760px, 100% - 2rem);
    height: 60px;
    border-radius: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 80px;

    .chat-form__inner {
      position: relative;
      width: min(760px, 100%);
      padding: 12px 24px;
      border-radius: 1rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: #fff;
      box-shadow:
        0 3px 5px rgba(0, 0, 0, 0.05),
        0 1px 3px rgba(0, 0, 0, 0.08);

      input {
        width: 90%;
        padding: 12px 0;
        border: none;
        background-color: transparent;
        font-family: Inter;
        font-size: 14px;
        font-weight: 400;
        line-height: 16.8px;
      }

      button[type='submit'] {
        padding: 0;
        background: transparent;
        display: grid;
        place-items: center;
      }

      .chat-form__clear-scroll {
        position: absolute;
        top: -1rem;
        left: calc(50% - 21px);
        width: 42px;
        height: 42px;

        button[type='button'] {
          width: 100%;
          height: 100%;
          padding: 0;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          background: linear-gradient(270.16deg, hsl(218, 100%, 60%) 0.13%, hsl(218, 90%, 65%) 47.04%, hsl(273, 100%, 70%) 99.93%);
        }
      }
    }
  }
}
