.girl__box {
  justify-self: center;
  position: relative;
  z-index: 1;
  width: 263px;
  height: 374px;
  border-radius: 20px;
  cursor: pointer;
  overflow: hidden;

  img {
    border-radius: 20px;
  }

  h1 {
    position: absolute;
    z-index: 5;
    left: 16px;
    bottom: 40px;
    width: 162px;
    white-space: nowrap; /* Удерживает текст в одной строке */
    overflow: hidden; /* Прячет текст, выходящий за пределы контейнера */
    text-overflow: ellipsis;
    font-family: var(--font-family);
    font-size: 20px;
    font-weight: 400;
    line-height: 24.8px;
    color: #fff;
  }

  .status {
    position: absolute;
    z-index: 5;
    top: -26px;
    right: -21px;
    width: 95px;
    height: 95px;
    border: 1px solid #fff;
    border-radius: 50%;
    display: grid;
    place-content: center;

    .status__box {
      display: grid;
      place-content: center;
      width: 63px;
      height: 63px;
      border: 1px solid #fff;
      border-radius: 50%;
      background: linear-gradient(196.65deg, rgba(118, 115, 115, 0.2) -36.33%, #ffffff 106.11%);
    }
  }

  .girl__audio {
    position: absolute;
    z-index: 5;
    left: 16px;
    bottom: 8px;
    width: 162px;

    .audioMp3 {
      background-color: transparent;
      box-shadow: 0 0 0 rgba(0, 0, 0, 0);
      margin: 0;
      padding: 0;

      .visualizer-container {
        width: 100%;
        height: 29px;
        gap: 8px;

        .controls {
          width: 29px;
          height: 29px;
          border-radius: 50%;
          background-color: var(--primary-blue-hover);

          button {
            width: 29px;
            height: 29px;
            display: grid;
            place-content: center;

            svg {
              width: 13px;
              height: 13px;
              border: none;
            }
          }
        }

        canvas {
          height: 19px;
        }
      }
    }
  }

  /* Keyframes для микроанимации */
  @keyframes pulse {
    0% {
      box-shadow: 0 0 8px hsla(0, 50%, 100%, 0.5);
    }
    50% {
      box-shadow:
        0 0 15px hsla(0, 50%, 100%, 0.8),
        0 0 20px hsla(0, 50%, 100%, 0.9);
    }
    100% {
      box-shadow: 0 0 8px hsla(0, 50%, 100%, 0.5);
    }
  }

  .gradient_0,
  .gradient_1,
  .gradient_2,
  .gradient_3 {
    z-index: 2;
    position: absolute;
    left: 0;
    right: 0;
    // top: 33px;
    top: 0;
    // bottom: -33px;
    bottom: 0;
    border-radius: 20px;
  }

  .gradient_0 {
    background: radial-gradient(87.54% 87.67% at 50% 50%, rgba(72, 134, 255, 0) 0%, #4886ff 100%);
  }

  .gradient_1 {
    background: linear-gradient(0deg, var(--pink-color) 0%, rgba(255, 0, 153, 0) 100%);
  }

  .gradient_2 {
    background: linear-gradient(0deg, #597cc0 0%, rgba(0, 0, 0, 0) 100%);
  }

  .gradient_3 {
    background: radial-gradient(87.54% 87.67% at 50% 50%, rgba(255, 1, 153, 0) 0%, var(--pink-color) 100%);
  }
}

@media (max-width: 620px) {
  .girl__box {
    width: 45vw;
    height: 65vw;

    h1,
    .girl__audio {
      width: 125px;
    }

    h1 {
      font-size: 18px;
    }
    .gradient_0,
    .gradient_1,
    .gradient_2,
    .gradient_3 {
      //top: 27px;
      //bottom: -27px;
    }
  }
}
