/* src/components/MediaPermissionMonitorPopUp/scss/index.scss */

// Variables
$modal-background: rgba(0, 0, 0, 0.6);
$popup-background: #ffffff;
$header-background: #f5f5f5;
$primary-color: #007bff;
$danger-color: #dc3545;
$text-color: #333333;
$icon-size: 24px;
$border-radius: 8px;
$padding: 20px;
$transition-duration: 0.3s;

// Mixins
@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin button-style($bg-color, $text-color) {
  background-color: $bg-color;
  color: $text-color;
  border: none;
  padding: 10px 20px;
  border-radius: $border-radius;
  cursor: pointer;
  font-size: 16px;
  transition: background-color $transition-duration ease;

  &:hover {
    background-color: darken($bg-color, 10%);
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.5);
  }
}

.MediaPermissionMonitorPopUp {
  background-color: $popup-background;
  border-radius: $border-radius;
  width: 90%;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  padding: $padding;
  color: $text-color;

  &-header {
    background-color: $header-background;
    padding: 15px;
    border-bottom: 1px solid #e0e0e0;

    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    &_icons {
      display: flex;
      gap: 15px;
      margin-bottom: 10px;

      &-microphone,
      &-camera {
        width: $icon-size;
        height: $icon-size;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $danger-color;

        svg {
          width: 100%;
          height: 100%;
        }
      }
    }

    h1 {
      font-size: 18px;
      margin: 0;
      line-height: 1.4;
    }
  }

  &-content {
    padding: 20px 0;
    font-size: 16px;
    line-height: 1.6;

    ol {
      padding-left: 20px;

      li {
        margin-bottom: 10px;

        strong {
          color: $primary-color;
        }
      }
    }
  }

  &-footer {
    padding-top: 15px;
    border-top: 1px solid #e0e0e0;
    display: flex;
    justify-content: center;

    .try-again-button {
      @include button-style($primary-color, #ffffff);
    }
  }
}

// Responsive Design
@media (max-width: 600px) {
  .popup {
    width: 95%;
  }

  .MediaPermissionMonitorPopUp {
    &-header {
      h1 {
        font-size: 16px;
      }
    }

    &-content {
      font-size: 14px;

      ol {
        li {
          margin-bottom: 8px;
        }
      }
    }

    &-footer {
      .try-again-button {
        padding: 8px 16px;
        font-size: 14px;
      }
    }
  }
}
/* Контейнер с пальцем */
.MediaPermissionMonitorPopUp_help {
  position: fixed;
  top: -3px;
  left: 110px;
  svg {
    width: 50px;
    height: 50px;
  }
}

/* Анимация для SvgFinger */
.MediaPermissionMonitorPopUp_help svg {
  animation: point-up-down 2s infinite ease-in-out;
}

/* Анимация движения пальца вверх-вниз */
@keyframes point-up-down {
  0%,
  100% {
    transform: translateY(20px);
  }
  50% {
    transform: translateY(-10px); /* Движение вверх */
  }
}
