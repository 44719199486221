.Complaint {
  display: flex;
  justify-content: center;
  align-items: center;
  .Complaint-block {
    background-color: #fff;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 20px;
    width: 500px; // Измените ширину на ваше усмотрение
    position: relative;
    z-index: 10;

    &_box {
      margin-bottom: 20px;
      text-align: center;
      &_checked {
        display: flex;
      }
      h1 {
        font-size: 24px;
        color: #333;
        margin-bottom: 15px;
      }

      textarea {
        // Учтите, что стандартное значение для input type это "text", а не "textarea"
        width: 100%;
        height: 100px;
        padding: 10px;
        border: 1px solid #ccc;
        border-radius: 5px;
        font-size: 16px;
        transition: border-color 0.3s;

        &:focus {
          border-color: #007bff; // Выберите цвет, который соответствует вашему бренду или дизайну
          outline: none;
        }
      }
    }
    &_button {
      display: flex;
      justify-content: space-between;

      button {
        padding: 10px 20px;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        font-size: 16px;
        transition: background-color 0.3s;
      }
    }
  }
  .Complaint-block_box_checked {
    display: flex;
    align-items: center;
    padding: 10px;
    background-color: #f6f6f6;
    border-radius: 8px;
  }

  .Complaint-block_box_checked p {
    margin-right: 10px;
    font-size: 16px;
    color: #333;
    margin: 0;
  }
  .Complaint-block_box_checked {
    display: flex;
    align-items: center;
    padding: 10px;
    border: 1px solid #dcdcdc;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    background-color: #f7f7f7;
    transition: background-color 0.3s ease-in-out;
  }

  .Complaint-block_box_checked:hover {
    background-color: #ebebeb;
  }

  .Complaint-block_box_checked p {
    margin-right: 10px;
    font-weight: bold;
    font-size: 16px;
    color: #333;
  }

  .Complaint-block_box_checked input[type='checkbox'] {
    cursor: pointer;
  }
  .Complaint-block_box_checked input[type='checkbox']:checked {
    background-color: #4caf50; /* Цвет фона при активном чекбоксе */
  }
}
