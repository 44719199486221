.friends__container {
  width: 100%;
  margin-inline: auto;

  .friends__header {
    width: 100%;
    height: 95px;
    padding: 12px 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    border-bottom: 1px solid hsla(0, 0%, 0%, 0.08);
    background-color: #fff;

    @media (max-width: 768px) {
      height: auto;
      padding: 0.5rem;
      flex-wrap: wrap;
      gap: 0.5rem;
    }

    .friends__header_flex {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 8px;

      .friends__title {
        font-family: var(--font-family);
        font-size: 20px;
        font-weight: 600;
        line-height: 30px;
        text-align: left;
      }

      .friends-count {
        width: 28px;
        height: 28px;
        padding: 2px 8px;
        border-radius: 50%;
        display: grid;
        place-items: center;
        background-color: hsl(248, 100%, 98%);

        span {
          font-family: Inter;
          font-size: 13px;
          font-weight: 600;
          line-height: 18px;
          color: hsl(237, 13%, 28%);
          color: var(--main-color);
        }
      }

      .friends__tabs {
        flex-basis: 100%;
      }
    }

    .friends__search {
      position: relative;
      width: 335px;
      height: 48px;
      padding: 14px 16px;
      border-radius: 12px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: hsl(248, 100%, 98%);

      @media (max-width: 420px) {
        width: 100%;
      }

      input {
        position: relative;
        width: 225px;
        height: 21px;
        border: none;
        outline: none;
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        position: relative;
        font-family: Inter;
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        text-align: left;
        color: hsl(248, 17%, 23%);
        background: transparent;

        @media (max-width: 420px) {
          width: 70%;
        }
      }

      .sidebar__close-btn {
        background-color: transparent;
        padding-inline: 0;
        display: grid;
        place-content: center;
      }
    }
  }

  .friends__content {
    width: 100%;
    padding: 0.5rem;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(255px, auto));
    place-content: center;
    gap: 0.5rem;
  }

  .no-content {
    width: min(600px, 100%);
    margin-inline: auto;
    margin-top: 3rem;
    padding: 1rem;
    display: flex;
    justify-content: center;
    font-family: Inter;
    font-size: 28px;
    font-weight: 400;
    line-height: 36px;
    text-align: center;
    color: var(--main-color);

    @media (max-width: 420px) {
      font-size: 24px;
    }

    p {
      text-wrap: pretty;

      a {
        color: var(--pink-color);
        text-decoration: underline;
        text-underline-offset: 7px;
        transition: text-underline-offset 150ms ease;

        &:hover {
          text-underline-offset: 10px;
        }
      }
    }
  }
}
