/* styles.css */
.model-gifts {
  width: min(800px, 100% - 40px);
  margin: 5rem auto;
  padding: 20px;
  border-radius: 20px;
  background-color: var(--light-background);
  box-shadow:
    0 3px 6px rgba(0, 0, 0, 0.08),
    0 1px 3px rgba(0, 0, 0, 0.1);
}

.model-gifts h2 {
  margin-block: 2rem;
  font-family: var(--font-family);
  font-size: calc(0.95rem + 0.8vw);
  font-weight: 500;
  color: #333;
  text-align: center;
}

.model-gifts label {
  display: block;
  margin-bottom: 15px;
  color: #555;
}

.model-gifts select,
.model-gifts input[type='text'] {
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.model-gifts button {
  margin: 5px;
  padding: 12px 20px;
  border: none;
  border-radius: 8px;
  background-color: var(--primary-blue-color);
  color: #fff;
  cursor: pointer;
  transition: background-color 150ms ease;
}

.model-gifts button:hover {
  background-color: var(--secondary-blue-color);
}

.model-gifts button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.model-gifts ul {
  list-style-type: none;
  padding: 0;
}

.model-gifts li {
  background-color: #f9f9f9;
  margin-bottom: 10px;
  padding: 15px;
  border: 1px solid #eee;
  border-radius: 4px;
}

.model-gifts li:nth-child(odd) {
  background-color: #e9e9e9;
}

.model-gifts .pagination {
  text-align: center;
  margin-top: 20px;
}

.model-gifts .pagination button {
  margin: 0 5px;
}

.model-gifts .loading {
  text-align: center;
  font-size: 18px;
  color: #888;
}

@media (max-width: 415px) {
  .model-gifts {
    width: calc(100% - 20px);
    margin-block: 3rem;
    padding: 10px;
  }
}
