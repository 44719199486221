.SettingsVideo {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  h2 {
    margin: 20px;
    font-size: 22px;
  }
  main {
    max-width: 500px;
  }
}
