.language-selected {
  min-width: 450px;
  max-width: 800px;
  position: relative;
  padding: 20px 20px;
  border-radius: 40px;
  background: #fff;
  border: none;
  .e1gn6jc30 {
  }
  input {
    right: 0;
  }

  input::placeholder {
    color: #757575; /* Example gray color */
  }
  .e1l4eby50 {
    padding: 12px 5px;
    position: relative;
    background: transparent;
    color: black;
  }
  .react-dropdown-select-dropdown-handle {
    position: relative;
    top: 1px;

    svg {
      width: 30px;
      height: 30px;
    }
  }
  .react-dropdown-select {
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
  }
  .css-z2hyoe-ItemComponent.react-dropdown-select-item-selected {
  }
  .error-message {
    position: absolute;
    left: 50%;
    top: calc(100% + 5px); /* 5px - это ваш отступ от инпута */
    transform: translateX(-50%);
    color: var(--red-color);
    font-size: 12px;
  }
}
@media (max-width: 500px) {
  .language-selected {
    width: 200px;
  }
  .step1-information .language-selected .e1gn6jc30 {
    min-width: 200px !important;
  }
}
