:root {
  --desktop: 1440;
  --table: 768;
  --mobile: 390;
  --table-media: 1025px;
  --mobile-media: 480px;
  --font-family: 'Unbounded';
  --container: 1276rem;
  --padding-container: 30rem;
  --bg-color: hsl(248, 75%, 98%);
  --main-color: hsl(218, 10%, 20%);
  --red-color: hsl(339, 100%, 50%);
  --pink-color: hsl(324, 100%, 50%);
  --light-background: hsla(0, 0%, 100%, 0.95);
  --light-blue-color: hsl(218, 100%, 95%);
  --primary-blue-hover: hsl(218, 100%, 75%);
  --primary-blue-color: hsl(218, 100%, 70%);
  --secondary-blue-color: hsl(218, 100%, 60%);
  --intense-blue-color: hsl(218, 100%, 50%);
  --dark-blue-color: hsl(218, 100%, 10%);
}
