.notifications {
  font-family: var(--font-family);
  position: relative;

  &-count {
    position: absolute;
    width: 20px;
    height: 20px;
    padding-top: 1px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    font-size: 10px;
    color: white;
    background-color: var(--pink-color);
    top: -15px; /* Подстроить в зависимости от местоположения на вашем сайте */
    right: -13px; /* Подстроить в зависимости от местоположения на вашем сайте */
  }

  &-icon {
    width: 37px;
    height: 37px;
    display: grid;
    place-content: center;
    border-radius: 50%;
  }

  &-icon_pink {
    background-color: #fff;

    svg {
      fill: var(--pink-color);
    }
  }

  &-icon_black {
    background-color: transparent;

    svg {
      fill: var(--main-color);
    }
  }

  p {
    padding: 0;
    margin: 0;
  }

  &-block {
    // Общие стили для блока уведомлений
    position: absolute;
    z-index: 9999;
    right: 0;
    width: 623px;
    height: fit-content;
    max-height: 471px;
    padding: 0 12px 24px 12px;
    border-radius: 20px;
    background-color: #fff;
    box-shadow: 3px 4px 13.4px rgba(0, 0, 0, 0.16);
    overflow: hidden;

    .close-notifications__button {
      position: absolute;
      top: 17px;
      right: 23px;
      width: 12px;
      height: 19px;
      border: none;
      background-color: transparent;
      cursor: pointer;
    }

    &_top,
    &_bottom {
      display: flex;
      align-items: center;
      justify-content: space-between;
      &__noMassages {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        p {
          margin-bottom: 40px;
        }
      }
    }

    &_top {
      height: 93px;
      padding-top: 48px;
      padding-bottom: 20px;
      padding-left: 42px;

      &__text {
        p {
          font-style: normal;
          font-weight: 400;
          font-size: 20px;
          line-height: 25px;
          color: var(--main-color);
        }
      }
    }

    &_hidden-corners {
      border-radius: 20px;
      overflow: hidden;
    }

    &_bottom {
      width: 598px;
      height: fit-content;
      max-height: 361px;
      padding: 24px 12px;
      background: hsl(218, 100%, 97%);
      border-radius: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 8px;
      overflow-y: auto;
      overscroll-behavior: none;

      &::-webkit-scrollbar {
        width: 10px;
        border-radius: 10px;
        background-color: hsla(218, 82%, 82%, 0.25);
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: var(--primary-blue-hover);
      }

      &::-webkit-scrollbar-thumb:hover {
        background-color: hsla(218, 100%, 75%, 0.8);
      }

      &__box {
        display: flex;
        align-items: flex-start;
        width: 100%;
        gap: 30px;
        padding: 21px 34px 20px 15px;
        background-color: hsla(218, 82%, 82%, 0.33);
        border-bottom: hsl(218, 82%, 82%);
        border-radius: 10px;

        &___img {
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          width: 50px;
          height: 100%;

          span {
            top: -5px;
            right: -23px;
            position: absolute;
            font-size: 9px;
            color: var(--pink-color);
          }

          svg {
            width: 28px;
            height: 28px;
          }
        }

        &___container {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;
          gap: 5px;

          &____text {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            color: #000;

            p {
              margin: 0;
              padding: 0;
            }
          }

          &____data {
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 15px;
            color: #6d6d6d;
          }
        }
      }
    }
  }

  .short-length {
    width: 20px;
    padding: 2px 12px 1px;
  }

  .long-length {
    width: 35px;
    right: -21px;
  }
}

@keyframes openAnimationNotification {
  from {
    opacity: 0;
    transform: translateY(-70px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.notifications {
  .close {
    animation: openAnimationNotification 0.35s ease-out;
  }
}

@keyframes closeAnimationNotification {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-70px);
  }
}

.notifications {
  .open {
    animation: closeAnimationNotification 0.35s ease-out;
  }
}

@media (max-width: 1180px) {
  @keyframes openAnimationNotification {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  .notifications {
    .close {
      animation: openAnimationNotification 0.35s ease-out;
    }
  }

  @keyframes closeAnimationNotification {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }

  .notifications {
    .open {
      animation: closeAnimationNotification 0.35s ease-out;
    }
  }
}
.notifications-block {
  cursor: auto;
}
@media (max-width: 1180px) {
  .notifications-block {
    position: fixed;
    top: 350px;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

@media (max-width: 630px) {
  .notifications {
    &-block {
      width: calc(100vw - 1.5rem);

      &_bottom {
        width: 100%;
      }
    }
  }
}

@media (max-width: 420px) {
  .notifications-block {
    width: 100%;
  }
}
