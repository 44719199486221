.TransactionalController {
  /* TransactionalController.css */

  max-width: 1000px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

  h1 {
    text-align: center;
    color: #333;
    margin-bottom: 20px;
  }

  .filters {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  .filters label {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    color: #555;
  }

  .filters input,
  .filters select {
    padding: 8px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
  }

  .table-container {
    overflow-x: auto;
  }

  table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
  }

  th,
  td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }

  th {
    background-color: #f4f4f4;
    color: #333;
  }

  tr:nth-child(even) {
    background-color: #f9f9f9;
  }

  .pagination {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .pagination p {
    margin: 0 10px;
    font-size: 14px;
    color: #555;
  }

  .pagination button {
    padding: 8px 12px;
    border: none;
    background-color: #007bff;
    color: #fff;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
  }

  .pagination button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
}
