.client {
  .item {
    display: flex;
    flex-direction: column;
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 20px;
    margin: 10px;
    background-color: #fafafa;
    box-shadow:
      0 4px 8px 0 rgba(0, 0, 0, 0.2),
      0 6px 20px 0 rgba(0, 0, 0, 0.1);
  }

  h2 {
    color: #333;
    padding-bottom: 10px;
  }

  .item-info {
    margin-bottom: 10px;
    color: #555;
  }

  strong {
    color: #333;
  }

  .item-permissions {
    list-style-type: none;
    padding: 0;
    margin-top: 10px;
  }

  .item-permissions li {
    margin-bottom: 5px;
    padding: 10px;
    border-radius: 5px;
    background-color: #eaeaea;
    transition: background-color 0.3s ease;
  }

  .item-permissions li:hover {
    background-color: #d5d5d5;
  }
}
