.MessageReplenishment {
  font-family: 'Arial', sans-serif;
  max-width: 800px;
  margin: 20px auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  background-color: #f9f9f9;
}

.MessageReplenishment h1 {
  text-align: center;
  color: #333;
}

.MessageReplenishment div[style*="color: 'red'"] {
  color: var(--red-color);
  margin-bottom: 20px;
  text-align: center;
}

.MessageReplenishment table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.MessageReplenishment table th,
.MessageReplenishment table td {
  padding: 10px;
  text-align: center;
  border: 1px solid #ccc;
}

.MessageReplenishment table th {
  background-color: #f1f1f1;
  color: #333;
}

.MessageReplenishment table td {
  background-color: #fff;
}

.MessageReplenishment input[type='number'] {
  width: 80%;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.MessageReplenishment button {
  display: inline-block;
  margin: 10px 5px;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #007bff;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
}

.MessageReplenishment button:hover {
  background-color: #0056b3;
}

.MessageReplenishment button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.MessageReplenishment button + button {
  background-color: #28a745;
}

.MessageReplenishment button + button:hover {
  background-color: #218838;
}
