/* Основные стили для общего контейнера */
.profileModel {
  font-family: var(--font-family);
}

/* Стилизация навигационной панели */
.profileModel nav {
  // background-color: var(--primary-blue-color);
  background: linear-gradient(
    to right,
    var(--primary-blue-color),
    var(--secondary-blue-color),
    var(--secondary-blue-color),
    var(--primary-blue-color)
  );
  color: #fff; /* Цвет текста */
  padding: 20px 0; /* Вертикальный отступ */
  text-align: center; /* Выравнивание текста по центру */
}

/* Улучшение стиля списка */
.profileModel nav ul {
  list-style-type: none; /* Убираем маркеры списка */
  padding: 0;
  margin: 0;
  display: flex; /* Используем flexbox для выравнивания элементов */
  justify-content: center; /* Выравнивание элементов по центру */
  flex-wrap: wrap; /* Перенос элементов на новую строку при необходимости */
}

/* Стилизация элементов списка */
.profileModel nav ul li {
  display: inline-flex; /* Использование inline-flex для элементов списка */
  width: auto; /* Автоматическая ширина в соответствии с содержимым */
  margin: 3px; /* Горизонтальный отступ между элементами */
}

/* Стилизация ссылок */
.profileModel nav ul li a {
  color: #fff; /* Цвет текста */
  text-decoration: none; /* Убираем подчеркивание */
  font-size: calc(0.75rem + 0.25vw);
  padding: 12px 20px; /* Паддинг для улучшения внешнего вида и удобства нажатия */
  border-radius: 8px; /* Скругление углов */
  background-color: var(--primary-blue-color);
  transition: background-color 150ms ease; /* Плавное изменение фона при наведении */
}

/* Стили для активной ссылки */
.profileModel nav ul li a.active {
  color: var(--pink-color); /* Цвет активного элемента */
  background-color: hsla(0, 0%, 100%, 0.85);
}

/* Стили для ссылок при наведении */
.profileModel nav ul li a:not(.active):hover {
  background-color: var(--intense-blue-color);
}
