.main-info {
  &-block {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    &:last-of-type {
      margin-bottom: 5rem;
    }

    .delete {
      background: var(--red-color);
    }

    .rooms {
      margin-block: 2.5rem;
      background: #27a93c;
      opacity: 0.9;

      &:hover {
        opacity: 1;
      }
    }

    button {
      width: min(350px, 100%);
      padding: 15px 20px;
      border-radius: 40px;
      font-size: 15px;
    }
  }
}
