.loading {
  margin-top: 50px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  .load-wrapp {
    height: 100px;
    border-radius: 5px;
    text-align: center;
  }

  .load-wrapp p {
    margin: 0;
    padding: 10px 0;
    color: #777;
  }

  .letter-holder {
    display: flex;
    flex-direction: row;
    padding: 16px;
  }

  .letter {
    float: left;
    color: var(--main-color);
    font-family: var(--font-family);
  }

  .load-6 .letter {
    animation-name: loadingF;
    animation-duration: 1.6s;
    animation-iteration-count: infinite;
    animation-direction: linear;
  }

  .l-1 {
    animation-delay: 0.48s;
  }
  .l-2 {
    animation-delay: 0.6s;
  }
  .l-3 {
    animation-delay: 0.72s;
  }
  .l-4 {
    animation-delay: 0.84s;
  }
  .l-5 {
    animation-delay: 0.96s;
  }
  .l-6 {
    animation-delay: 1.08s;
  }
  .l-7 {
    animation-delay: 1.2s;
  }
  .l-8 {
    animation-delay: 1.32s;
  }
  .l-9 {
    animation-delay: 1.44s;
  }
  .l-10 {
    animation-delay: 1.56s;
  }

  @keyframes loadingF {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  ////////// two
  .ouro {
    height: 70px;
    width: 70px;
    position: relative;
    display: inline-block;
    border-radius: 50%;
    background: none repeat scroll 0 0 #dddddd;
    overflow: hidden;
    box-shadow:
      0 0 10px rgba(0, 0, 0, 0.1) inset,
      0 0 25px rgba(0, 0, 255, 0.075);
  }

  .ouro > span {
    position: absolute;
    height: 100%;
    width: 50%;
    overflow: hidden;
  }
  .left {
    left: 0;
  }
  .right {
    left: 50%;
  }

  .anim {
    position: absolute;
    left: 100%;
    top: 0;
    height: 100%;
    width: 100%;
    border-radius: 999px;

    background: none repeat scroll 0 0 var(--primary-blue-color);
    opacity: 0.8;
    -webkit-animation: ui-spinner-rotate-left 3s infinite;
    animation: ui-spinner-rotate-left 3s infinite;
    -webkit-transform-origin: 0 50% 0;
    transform-origin: 0 50% 0;
  }
  .left .anim {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
  }
  .right .anim {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    left: -100%;
    -webkit-transform-origin: 100% 50% 0;
    transform-origin: 100% 50% 0;
  }

  /* v3 */
  .ouro3 .anim {
    -webkit-animation-delay: 0s;
    -webkit-animation-duration: 3s;
    -webkit-animation-timing-function: linear;
    animation-delay: 0s;
    animation-duration: 3s;
    animation-timing-function: linear;
  }
  .ouro3 .right .anim {
    -webkit-animation-name: ui-spinner-rotate-right;
    -webkit-animation-delay: 0;
    -webkit-animation-delay: 1.5s;
    animation-name: ui-spinner-rotate-right;
    animation-delay: 0;
    animation-delay: 1.5s;
  }

  @keyframes ui-spinner-rotate-right {
    0% {
      transform: rotate(0deg);
    }
    25% {
      transform: rotate(180deg);
    }
    50% {
      transform: rotate(180deg);
    }
    75% {
      transform: rotate(360deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes ui-spinner-rotate-left {
    0% {
      transform: rotate(0deg);
    }
    25% {
      transform: rotate(0deg);
    }
    50% {
      transform: rotate(180deg);
    }
    75% {
      transform: rotate(180deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  @-webkit-keyframes ui-spinner-rotate-right {
    0% {
      -webkit-transform: rotate(0deg);
    }
    25% {
      -webkit-transform: rotate(180deg);
    }
    50% {
      -webkit-transform: rotate(180deg);
    }
    75% {
      -webkit-transform: rotate(360deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }
  @-webkit-keyframes ui-spinner-rotate-left {
    0% {
      -webkit-transform: rotate(0deg);
    }
    25% {
      -webkit-transform: rotate(0deg);
    }
    50% {
      -webkit-transform: rotate(180deg);
    }
    75% {
      -webkit-transform: rotate(180deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }
}
