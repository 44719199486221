.recommendations {
  width: min(800px, 100% - 40px);
  margin-inline: auto;

  .container {
    font-family: var(--font-family);
    padding: 20px;

    h2 {
      margin-block: 2rem;
      font-family: var(--font-family);
      font-size: calc(0.95rem + 0.8vw);
      font-weight: 500;
      color: #333;
    }

    .recommendations-text {
      font-size: calc(0.75rem + 0.25vw);
      font-weight: 500;
      color: #555;
      text-align: left;
      line-height: 1.6;
    }

    .accordion {
      margin-block: 30px;
      padding: 10px;
      border: 1px solid #ddd;
      border-radius: 8px;
      background-color: var(--light-background);
      box-shadow:
        0 3px 6px rgba(0, 0, 0, 0.08),
        0 1px 3px rgba(0, 0, 0, 0.1);

      .accordion-title {
        font-size: calc(0.9rem + 0.25vw);
      }

      .accordion-content {
        p {
          margin: 0;
          padding: 10px;
          color: #333;
          font-size: 16px; /* Пример изменения размера шрифта на пиксели */
          font-size: calc(0.75rem + 0.25vw);
          line-height: 24px; /* Пример изменения высоты строки на пиксели */
          text-align: left;
        }

        p > p {
          padding: 10px 0;
        }

        strong {
          color: var(--secondary-blue-color);
          font-weight: bold;
        }

        /* Стиль для ссылок, если они есть */
        a {
          color: var(--pink-color);
          text-decoration: none;
          font-size: 16px;
        }

        a:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

@media (max-width: 415px) {
  .recommendations {
    width: calc(100% - 20px);

    .container {
      padding: 10px;
    }
  }
}
